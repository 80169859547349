import React, { Dispatch, MutableRefObject, SetStateAction, memo, useCallback, useEffect, } from 'react';
import { ChatItem, Message, PrValidationStatus, PullRequest, Snippet, StatefulCodeSuggestion } from '@/lib/types';
import ChatItemDisplay from './ChatItemDisplay';
import { Skeleton } from './ui/skeleton';
import AutoScrollArea from './ui/autoscroll';
import PulsingLoader from './shared/PulsingLoader';
import { truncate } from '@/lib/strUtils';
import { toast } from '@/components/ui/use-toast'
import {
  isLoadingState,
  messagesIdState,
  messagesState,
  repoNameValidState,
  scrollToBottomState,
  searchMessageState
} from '@/state/atoms';
import { Octokit } from '@octokit/rest';
import { useAtom, useAtomValue, } from 'jotai';


const ChatMessagesDisplay = memo(({
  posthog_capture,
  messagesContainerRef,
  shownMessages,
  snippets,
  currentOwnerOfChatRef,
  stream,
  userMentionedPullRequests,
  backIndices,
  setSnippets,
  removeChanges,
  startChatStream,
  fixPrValidationErrors,
  chatWithAgent,
  userMentionedPullRequest,
  setUserMentionedPullRequest_,
  authorizedFetch,
  octokit
}: {
  posthog_capture: (event: string, properties: any) => void,
  messagesContainerRef: any,
  shownMessages: ChatItem[],
  snippets: Snippet[],
  currentOwnerOfChatRef: any,
  stream: MutableRefObject<ReadableStreamDefaultReader<Uint8Array> | undefined>
  userMentionedPullRequests: PullRequest[]
  backIndices: number[],
  setSnippets: any,
  removeChanges: () => void,
  startChatStream: (message: string, newMessages: Message[], snippets: Snippet[], annotations: Message["annotations"], branch: string) => Promise<void>,
  fixPrValidationErrors: (prValidationStatuses: PrValidationStatus[]) => Promise<void>,
  chatWithAgent: (currentMessages: Message[], currentSnippets: Snippet[], branch: string, currentAppliedChanges: StatefulCodeSuggestion[]) => Promise<void>,
  userMentionedPullRequest: PullRequest | undefined,
  setUserMentionedPullRequest_: Dispatch<SetStateAction<PullRequest | undefined>>
  authorizedFetch: (url: string, body?: { [key: string]: any }, options?: RequestInit) => Promise<Response>,
  octokit: Octokit | undefined
}) => {
  const messages = useAtomValue(messagesState)
  const messagesId = useAtomValue(messagesIdState)
  const isLoading = useAtomValue(isLoadingState)
  const repoNameValid = useAtomValue(repoNameValidState)
  const searchMessage = useAtomValue(searchMessageState)
  const [scrollToBottom, setScrollToBottom] = useAtom(scrollToBottomState)

  // detect and handle copy paste
  const handleOnCopy = useCallback(() => {
    // Get the id of the closest ancestor with an id
    posthog_capture('chat_changes_taken', {
      event: 'OnCopy',
    });
    console.log('on copy event triggered!');
  }, []);

  const handleValidatePR = useCallback(() => {
    toast({
      title: 'PR Validation',
      description: 'PR validation is currently disabled.',
    });
    // Uncomment the following line when you want to enable validation
    // validatePr(pr, index);
  }, []);

  useEffect(() => {
    if (scrollToBottom && messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: 'smooth'
      });
      setScrollToBottom(false);
    }
  }, [scrollToBottom, messagesContainerRef]);

  return (
    <>
    <AutoScrollArea
      ref={messagesContainerRef}
      className={`h-full w-full flex-grow overflow-y-auto rounded-xl flex flex-col items-center transition-all duration-700 ease-in-out ${
        messages.length > 0 || messagesId
          ? 'max-h-[100vh] pr-2 opacity-100'
          : 'max-h-0 opacity-0'
      } relative`}
      hidden={!repoNameValid && !messagesId}
    >
      <div
        className={`h-full ${messages.length <= 1 ? 'max-w-[800px]' : 'max-w-[1200px]'} mx-auto transition-[max-width] duration-300 ease-in-out`}
        onCopy={handleOnCopy}
      >
        {shownMessages.length > 0
          ? shownMessages.map((message, index) => (
              <ChatItemDisplay
                key={index}
                index={index}
                stream={stream}
                backIndices={backIndices}
                chatItem={message}
                snippets={snippets}
                currentOwnerOfChatRef={currentOwnerOfChatRef}
                userMentionedPullRequests={userMentionedPullRequests}
                setUserMentionedPullRequest_={setUserMentionedPullRequest_}
                onValidatePR={handleValidatePR}
                setSnippets={setSnippets}
                removeChanges={removeChanges}
                startChatStream={startChatStream}
                chatWithAgent={chatWithAgent}
                authorizedFetch={authorizedFetch}
                userMentionedPullRequest={userMentionedPullRequest}
                octokit={octokit}
                // fixPrValidationErrors={() => {
                //   const currentPrValidationStatuses =
                //     messages[backIndices[index]]!.annotations!
                //       .prValidationStatuses
                //   if (currentPrValidationStatuses) {
                //     fixPrValidationErrors(
                //       currentPrValidationStatuses
                //     )
                //   }
                // }}
              />
            ))
          : messagesId.length > 0 && (
              <div className="space-y-4">
                <Skeleton className="h-12 ml-32 rounded-md" />
                <Skeleton className="h-12 mr-32 rounded-md" />
                <Skeleton className="h-12 ml-64 rounded-md" />
                <Skeleton className="h-12 mr-64 rounded-md" />
              </div>
            )}
      </div>
      <div className="h-12" />
      <div className="absolute bottom-0 left-0 w-full h-12 bg-gradient-to-b from-transparent to-background pointer-events-none" />
      {isLoading && (
        <div className={`flex flex-row justify-around w-full absolute bottom-0`}>
          <div className={`flex flex-row justify-between items-center bottom-0 mb-6 w-full ${messages.length <= 1 ? 'max-w-[800px]' : 'max-w-[1200px]'} transition-[max-width] duration-300 ease-in-out`}>
            <div className="ml-2 flex space-x-2 items-center">
              <div className="flex justify-center">
                <PulsingLoader size={1} />
              </div>
              <p className="text-gray-500 center whitespace-nowrap overflow-hidden">
                {truncate(searchMessage, 100)}
              </p>
            </div>
          </div>
        </div>
      )}
    </AutoScrollArea>
    </>
  );
});

ChatMessagesDisplay.displayName = 'ChatMessagesDisplay';

export {
  ChatMessagesDisplay
}