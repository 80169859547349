import { appliedChangesAtom, messagesState, prValidationStatusesState, setShowSurveyState } from "@/state/atoms"
import { useAtom, useAtomValue } from "jotai"
import { useCallback, useEffect, useRef, } from "react"

export const useSurvey = () => {
  const [showSurvey, setShowSurvey] = useAtom(setShowSurveyState)
  const showSurveyTimeout = useRef<number | undefined>(null)

  const appliedChanges = useAtomValue(appliedChangesAtom)

  const appliedChangesExist = appliedChanges.length > 0

  const messages = useAtomValue(messagesState)
  
  const prValidationStatuses = useAtomValue(prValidationStatusesState)

  const resetShowSurveyTimeout = useCallback(() => {
    if (showSurvey) {
      setShowSurvey(false)
    }
    if (showSurveyTimeout.current) {
      clearTimeout(showSurveyTimeout.current) // clear the previous time out
    }

    // set the timeout again - two minute wait
    showSurveyTimeout.current = window.setTimeout(
      () => {
        const surveyID = import.meta.env.NEXT_PUBLIC_SURVEY_ID
        if (
          surveyID &&
          !localStorage.getItem(`hasInteractedWithSurvey_${surveyID}`) &&
          !showSurvey
        ) {
          setShowSurvey(true)
        }
      },
      5 * 60 * 1000
    )
  }, [showSurvey, showSurveyTimeout])

  useEffect(() => {
    resetShowSurveyTimeout()
  }, [appliedChangesExist, messages, prValidationStatuses])

  useEffect(() => {
    resetShowSurveyTimeout()
    return () => {
      if (showSurveyTimeout.current) {
        clearTimeout(showSurveyTimeout.current)
      }
    }
  }, []) // run on mount

  return { showSurvey, setShowSurvey, resetShowSurveyTimeout }
}