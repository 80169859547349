import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Card,
  Title,
  LineChart
} from "@tremor/react";
import { Button } from "./ui/button";

interface Telemetry {
  username: string;
  event: string;
  timestamp: string;
}

interface TelemetryDisplayProps {
  data: Telemetry[];
}

const TelemetryDisplay: React.FC<TelemetryDisplayProps> = ({ data }) => {
  if (!Array.isArray(data) || data.length === 0) {
    return <div className="text-white">No telemetry data available.</div>;
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const columns: (keyof Telemetry)[] = [
    "username",
    "event",
    "timestamp",
  ];

  const getBaseUrl = () => {
    if (typeof window !== 'undefined') {
      return `${window.location.protocol}//${window.location.host}`;
    }
    return '';
  };

  const TelemetryTitle = () => {
    const [host, setHost] = useState("");

    useEffect(() => {
      setHost(window.location.host);
    }, []);

    return (
      <Title className="text-white text-4xl font-bold">
        Telemetry for <a href="/" className="no-underline hover:underline" target="_blank" rel="noopener noreferrer">{host}</a>
      </Title>
    );
  };

  // Count events by day
  const eventCountByDay = data.reduce((acc, item) => {
    const date = new Date(item.timestamp).toISOString().split('T')[0];
    if (!acc[date]) {
      acc[date] = {};
    }
    if (!acc[date][item.event]) {
      acc[date][item.event] = 0;
    }
    acc[date][item.event]++;
    return acc;
  }, {} as Record<string, Record<string, number>>);

  // Prepare data for LineChart
  const chartData = Object.entries(eventCountByDay).map(([date, events]) => ({
    date,
    ...events
  })).sort((a, b) => a.date.localeCompare(b.date));

  // Get unique event types
  const eventTypes = Array.from(new Set(data.map(item => item.event)));

  const downloadCSV = () => {
    // Convert data to CSV
    const csvContent = [
      columns.join(','), // header
      ...data.map(row =>
        columns.map(column => {
          let cellData = row[column];
          if (typeof cellData === 'string' && cellData.includes(',')) {
            // Wrap in quotes if contains comma
            cellData = `"${cellData}"`;
          }
          return cellData;
        }).join(',')
      )
    ].join('\n');

    // Create Blob and download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "telemetry.csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="bg-zinc-900 p-6 min-h-screen w-full">
      <TelemetryTitle />
      {/* Tremor Line Chart for Event Counts */}
      <Card className="mt-8 bg-zinc-900">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-white">Event Counts Over Time</h2>
        </div>
        <LineChart
          className="mt-4 h-80 text-white [&_.recharts-cartesian-axis-tick-value]:fill-white [&_.recharts-line]:stroke-white [&_.recharts-dot]:stroke-white [&_.recharts-dot]:fill-white"
          data={chartData}
          index="date"
          categories={eventTypes}
          colors={["blue", "green", "red", "purple", "orange"]}
          yAxisWidth={60}
          showLegend={true}
          showXAxis={true}
          showYAxis={true}
          showGridLines={true}
          curveType="linear"
          connectNulls={true}
          showAnimation={true}
          showTooltip={true}
          autoMinValue={true}
          valueFormatter={(v) => v.toFixed(0)}
        />
      </Card>

      {/* Tremor Table */}
      <Card className="mt-4 bg-zinc-900">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-white">Raw Telemetry Data</h2>
          <Button onClick={downloadCSV} size="sm" variant="secondary" color="blue">
            Download CSV
          </Button>
        </div>
        <Table>
          <TableHead>
            <TableRow className="border-b border-gray-700">
              {columns.map((column) => (
                <TableHeaderCell key={column} className="text-gray-300 font-semibold">
                  {column.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase())}
                </TableHeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow key={rowIndex} className="border-b border-gray-700">
                {columns.map((column) => (
                  <TableCell key={`${rowIndex}-${column}`} className="text-gray-200">
                    {column === "timestamp" ? formatDate(row[column]) : row[column]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
};

export default TelemetryDisplay;