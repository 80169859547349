import { Button, Card, Table, TableBody, TableCell, TableHead, TableRow, Title } from '@tremor/react';
import React, { useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface CommitMetric {
  id: string;
  sha: string;
  file_name: string;
  org_name: string;
  repo_name: string;
  author: string;
  date: string;
  additions: number;
  deletions: number;
  language: string;
  pr_number: number;
  pr_state: string;
  pr_link: string;
  pr_is_merged: boolean;
  issue_link: number;
  issue_author: string;
  issue_title: string;
}

interface MetricsDisplayProps {
  commitMetrics: CommitMetric[];
}

const CommitMetricsDisplay: React.FC<MetricsDisplayProps> = ({ commitMetrics }) => {
  // Convert date strings to Date objects
  const metrics = commitMetrics.map((metric) => ({
    ...metric,
    date: new Date(metric.date),
  }));
  console.log(metrics);

  // Get the date range
  const dates = metrics.map((m) => m.date.getTime());
  const startDate = new Date(Math.min(...dates));
  const endDate = new Date(Math.max(...dates));

  // Generate weekly intervals
  const weeks = [];
  let currentDate = new Date(startDate);
  currentDate.setHours(0, 0, 0, 0);
  currentDate.setDate(currentDate.getDate() - currentDate.getDay()); // Start from Sunday

  while (currentDate <= endDate) {
    weeks.push(new Date(currentDate));
    currentDate = new Date(currentDate);
    currentDate.setDate(currentDate.getDate() + 7);
  }

  // Lines of code per language per week
  const linesOfCodeData = weeks.map((weekStart) => {
    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekEnd.getDate() + 7);

    let botUsername = process.env.GITHUB_BOT_USERNAME || "sweep-nightly[bot]";
    const strippedBotUsername = botUsername.replace(/\[bot\]$/, "");
    const updatedBotUsername = strippedBotUsername + "[bot]";

    const weekMetrics = metrics.filter(
      (m) => m.date >= weekStart && m.date < weekEnd && m.pr_is_merged && m.author === updatedBotUsername
    );

    const languageData: { [key: string]: number } = {};

    weekMetrics.forEach((m) => {
      const language = m.language || 'unknown';
      const lines = m.additions + m.deletions;
      languageData[language] = (languageData[language] || 0) + lines;
    });

    return {
      week: weekStart.toISOString().split('T')[0],
      ...languageData,
    };
  });

  // PR Metrics per week
  const prData = weeks.map((weekStart) => {
    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekEnd.getDate() + 7);

    const weekMetrics = metrics.filter(
      (m) => m.date >= weekStart && m.date < weekEnd
    );

    const prIds = new Set(weekMetrics.map((m) => m.pr_number));
    const mergedPrIds = new Set(
      weekMetrics.filter((m) => m.pr_is_merged).map((m) => m.pr_number)
    );
    const generatedPrs = prIds.size;
    const acceptedPrs = mergedPrIds.size;

    return {
      week: weekStart.toISOString().split('T')[0],
      'PRs Generated': generatedPrs,
      'PRs Accepted': acceptedPrs,
    };
  });

  // All repo names
  const repoNames = Array.from(new Set(commitMetrics.map((m) => m.repo_name)));

  // Function to download CSV
  const downloadCSV = () => {
    const headers = Object.keys(commitMetrics[0]).join(',');
    const rows = commitMetrics
      .map((m) =>
        Object.values(m)
          .map((value) => `"${value}"`)
          .join(',')
      )
      .join('\n');
    const csv = `${headers}\n${rows}`;

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "metrics.csv");
      link.style.visibility = 'hidden';
      document.body.append(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="p-4 text-white">
      <Title className="my-8 text-2xl">Commit Metrics for {repoNames.join(', ')}</Title>
      <LinesOfCodeChart data={linesOfCodeData} />
      <div className="h-16" />
      <PRChart data={prData} />
      <Card className="mt-8">
        <Title className="mt-8 text-xl">Percentage of PRs Accepted</Title>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Week</TableCell>
              <TableCell>PRs Generated</TableCell>
              <TableCell>PRs Accepted</TableCell>
              <TableCell>Percentage Accepted</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prData.map((metric, index) => (
              <TableRow key={index}>
                {Object.values(metric).map((value, idx) => (
                  <TableCell key={idx} className="py-1">
                    {value}
                  </TableCell>
                ))}
                <TableCell key={index} className="py-1">
                  {metric['PRs Generated'] > 0
                    ? `${((metric['PRs Accepted'] / metric['PRs Generated']) * 100).toFixed(0)}%`
                    : '0%'}
                  <div className="h-1 w-full bg-gray-200 rounded-full overflow-hidden">
                    <div
                      className="h-full bg-blue-500 transition-all duration-300 ease-in-out"
                      style={{
                        width: metric['PRs Generated'] > 0
                          ? `${Math.min((metric['PRs Accepted'] / metric['PRs Generated']) * 100, 100)}%`
                          : '0%'
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
      <Card className="mt-8">
        <Title className="text-xl">Raw Metrics</Title>
        <Button onClick={downloadCSV} className="mt-4">
          Download CSV
        </Button>
      </Card>
      <div className="mb-16 max-h-[500px] overflow-auto border border-gray-200 rounded-md">
        <Table className="w-full text-xs">
          <TableHead className="bg-gray-50 sticky top-0">
            <TableRow>
              {Object.keys(commitMetrics[0]).map((key) => (
                <TableCell key={key} className="py-2 px-3 font-medium text-gray-900">
                  {key}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {commitMetrics.map((metric, index) => (
              <TableRow key={index}>
                {Object.values(metric).map((value, idx) => (
                  <TableCell key={idx} className="py-1 px-3">
                    {value instanceof Date
                      ? value.toISOString()
                      : value.toString()}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

interface LinesOfCodeData {
  week: string;
  [key: string]: number | string;
}

interface LinesOfCodeChartProps {
  data: LinesOfCodeData[];
}

const languageColors: { [key: string]: string } = {
  python: 'green',
  typescript: 'blue',
  java: 'red',
  cpp: 'yellow',
  unknown: 'gray'
};

const LinesOfCodeChart: React.FC<LinesOfCodeChartProps> = ({ data }) => {
  const categories = useMemo(() => {
    const allCategories = new Set<string>();
    data.forEach(entry => {
      Object.keys(entry).forEach(key => {
        if (key !== 'week') allCategories.add(key);
      });
    });
    return Array.from(allCategories);
  }, [data]);

  const processedData = useMemo(() => {
    return data.map(entry => {
      const newEntry = { ...entry };
      categories.forEach(category => {
        if (!(category in newEntry)) {
          newEntry[category] = 0;
        }
      });
      return newEntry;
    });
  }, [data, categories]);

  return (
    <div className="w-full h-96">
      <h2 className="text-xl font-bold mb-4">Lines of Code Additions Merged by Sweep per Language per Week</h2>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={processedData}>
          <CartesianGrid strokeDasharray="3 3" stroke="white" />
          <XAxis dataKey="week" stroke="white" />
          <YAxis stroke="white" />
          <Tooltip />
          <Legend />
          {categories.map((category, index) => (
            <Line
              key={category}
              type="monotone"
              dataKey={category}
              stroke={languageColors[category] || `hsl(${index * 137.5 % 360}, 70%, 50%)`}
              activeDot={{ r: 4 }}
              strokeWidth={3}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

interface PRData {
  week: string;
  'PRs Generated': number;
  'PRs Accepted': number;
}

interface PRChartProps {
  data: PRData[];
}

const PRChart: React.FC<PRChartProps> = ({ data }) => {
  const colors = {
    'PRs Generated': 'blue',
    'PRs Accepted': 'green'
  };
  return (
    <div className="w-full h-96">
      <h2 className="text-xl font-bold mb-4">Pull Requests Generated by Sweep vs Accepted per Week</h2>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" stroke="white" />
          <XAxis dataKey="week" stroke="white" />
          <YAxis stroke="white" />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="PRs Generated"
            stroke={colors['PRs Generated']}
            activeDot={{ r: 4 }}
            strokeWidth={3}
          />
          <Line
            type="monotone"
            dataKey="PRs Accepted"
            stroke={colors['PRs Accepted']}
            activeDot={{ r: 4 }}
            strokeWidth={3}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CommitMetricsDisplay;
