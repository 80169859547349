import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

function useDefaultedState<T>(
  key: string,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState(() => {
    if (typeof window === 'undefined') {
      return defaultValue // SSR (server-side rendering
    }
    const storedValue = localStorage.getItem(key)
    return storedValue !== null ? JSON.parse(storedValue) : defaultValue
  })

  const prevStateRef = useRef<T>(state)

  useEffect(() => {
    if (typeof window === 'undefined') {
      return // Skip effect on SSR
    }

    if (state !== prevStateRef.current) {
      localStorage.setItem(key, JSON.stringify(state))
      prevStateRef.current = state
    }
  }, [key, state])

  return [state, setState]
}

function useUnsavedChangesAlert(hasUnsavedChanges: () => boolean) {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (hasUnsavedChanges()) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);
}

export { useDefaultedState, useUnsavedChangesAlert }
