import { forwardRef, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { ScrollArea } from '@/components/ui/scroll-area'

interface AutoScrollAreaProps {
  children: React.ReactNode
  className?: string
  threshold?: number
}

const AutoScrollArea = forwardRef<
  HTMLDivElement,
  AutoScrollAreaProps & React.ComponentPropsWithRef<typeof ScrollArea>
>(({ children, className = '', threshold = 50, ...props }, ref) => {
  const [userDidScroll, setUserDidScroll] = useState(false)
  const [stickToBottom, setStickToBottom] = useState(true)
  const scrollAreaRef = useRef<HTMLDivElement>(null)
  const prevScrollAreaHeight = useRef<number>(0)
  const scrollTimeout = useRef<ReturnType<typeof setTimeout> | undefined>(null)
  // const scrollUpTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)
  // const scrollDownTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)

  const combinedRef = useCallback(
    (element: HTMLDivElement | undefined) => {
      // @ts-expect-error
      scrollAreaRef.current = element
      if (typeof ref === 'function') {
        ref(element)
      } else if (ref) {
        ref.current = element
      }
    },
    [ref]
  )

  const handleScroll = useCallback((event: WheelEvent) => {
    if (scrollAreaRef.current) {
      const { scrollHeight, clientHeight, scrollTop } = scrollAreaRef.current
      const isNearBottom = scrollTop + clientHeight > scrollHeight - threshold

      if (isNearBottom && event.deltaY > 0) { // Scrolling down
        setStickToBottom(true)
        setUserDidScroll(false)
      } else {
        setStickToBottom(false)
        setUserDidScroll(true)
      }

      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current)
      }
      scrollTimeout.current = setTimeout(() => {
        setUserDidScroll(false)
      }, 1000)
    }
  }, [threshold])

  useLayoutEffect(() => {
    if (scrollAreaRef.current) {
      const { scrollHeight, clientHeight, scrollTop } = scrollAreaRef.current
      if (userDidScroll) {
        prevScrollAreaHeight.current = scrollAreaRef.current.scrollHeight || 0
        return
      }
      // Basically checks if the client's current position is above how much scrolling has been done previously
      // console.log(scrollTop, clientHeight, prevScrollAreaHeight.current, threshold)
      const isNearBottom = scrollTop + clientHeight > prevScrollAreaHeight.current - threshold

      if (isNearBottom || stickToBottom) {
        scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight;
        prevScrollAreaHeight.current = scrollAreaRef.current.scrollHeight;
      } else if (scrollHeight) {
        prevScrollAreaHeight.current = scrollHeight
      }
    }
  }, [threshold, scrollAreaRef.current?.scrollHeight, userDidScroll, stickToBottom])

  useEffect(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.addEventListener('wheel', handleScroll, {
        passive: true,
      })
      return () => {
        scrollAreaRef.current?.removeEventListener('wheel', handleScroll)
      }
    }
  }, [scrollAreaRef.current])

  return (
    <ScrollArea ref={combinedRef} className={className} {...props}>
      {children}
    </ScrollArea>
  )
})

AutoScrollArea.displayName = 'AutoScrollArea'
export default AutoScrollArea
