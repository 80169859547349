import {
  ChatItem,
  Message,
  PullRequest,
  Snippet,
  StatefulCodeSuggestion,
  ToolCallGroup,
} from '@/lib/types'
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  memo,
  useState,
} from 'react'
import MessageDisplay from './MessageDisplay'
import { Button } from './ui/button'
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaChevronUp,
} from 'react-icons/fa'
import { truncate } from 'lodash'
import { MarkdownRenderer } from './shared/MarkdownRenderer'
import PulsingLoader from './shared/PulsingLoader'
import { branchState, repoNameState } from '@/state/atoms'
import { useAtomValue } from 'jotai'
import { Octokit } from '@octokit/rest'


const START_TOKEN = '# Planning'

const cleanFunctionCalls = (str: string) => {
  return str
    .replaceAll(/<function_call>.*?<\/function_call>/gms, '')
    .replaceAll(/<update_user>.*?<\/update_user>/gms, '')
}

const parseOutPlanning = (str: string) => {
  let thinking = str
  if (str.startsWith('Certainly!')) {
    return cleanFunctionCalls(thinking)
  }
  if (str.includes(START_TOKEN)) {
    thinking = str.split(START_TOKEN)[1]
  } else {
    thinking = ''
  }
  return cleanFunctionCalls(thinking)
}

const ToolCallGroupCarousel = ({
  toolCallGroups,
}: {
  toolCallGroups: ToolCallGroup[]
}) => {
  const repoName = useAtomValue(repoNameState)
  const branch = useAtomValue(branchState)
  const [collapsed, setCollapsed] = useState(true)
  const [currentIndex_, setCurrentIndex] = useState<number | undefined>()
  const currentIndex = Math.max(
    0,
    currentIndex_ ??
      toolCallGroups.findLastIndex((toolCallGroup, index) =>
        index > 0 ? parseOutPlanning(toolCallGroup.thinking).length > 0 : true
      )
  )
  const currentToolCallGroup =
    toolCallGroups[currentIndex] ?? toolCallGroups[toolCallGroups.length - 1]
  if (toolCallGroups.length === 0) {
    return 
  }
  const searchAgentComplete = toolCallGroups.some(toolCallGroup => toolCallGroup.messages.some(message => message.function_call?.function_name == "done_file_search"))
  const cleanedThinking =
    currentIndex > 0
      ? parseOutPlanning(currentToolCallGroup.thinking)
      : currentToolCallGroup.thinking
  return (
    <div className="flex justify-start">
      <div className={`bg-zinc-800 transition-color text-sm p-3 rounded-xl mb-4 inline-block ${!collapsed ? 'w-full' : ''} text-left text-xs justify-between`}>
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setCollapsed(!collapsed)}
            >
              {collapsed ? <FaChevronDown /> : <FaChevronUp />}
            </Button>
          </div>
          <h2 className="text-sm font-bold grow text-center mr-4">
            {collapsed ? (searchAgentComplete ? `Search Agent's Process` : `Search agent is running... (${toolCallGroups.length})`) : truncate(cleanedThinking.trim(), { length: 100 }) ||
              'Sweep is analyzing...'}
          </h2>
          {!collapsed && (
            <div className="flex flex-row items-center">
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setCurrentIndex(currentIndex - 1)}
                disabled={currentIndex === 0}
              >
                <FaChevronLeft />
              </Button>
              <span className="text-zinc-300 mx-2 whitespace-nowrap">
                {currentIndex + 1} / {toolCallGroups.length}
              </span>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setCurrentIndex(currentIndex + 1)}
                disabled={currentIndex === toolCallGroups.length - 1}
              >
                <FaChevronRight />
              </Button>
            </div>
          )}
        </div>
        <div className={collapsed ? 'hidden' : ''}>
          {cleanedThinking.length > 0 ? (
            <MarkdownRenderer
              repoName={repoName}
              branch={branch}
              content={cleanedThinking || ''}
              className="reactMarkdown py-0 mb-4"
              disableCodeBlockHover
            />
          ) : (
            <div className="flex flex-row items-center justify-center p-4">
              <PulsingLoader size={1} />
            </div>
          )}
          {currentToolCallGroup.messages.map((message, index) => (
            // @ts-expect-error
            <MessageDisplay key={index} message={message} />
          ))}
        </div>
      </div>
    </div>
  )
}

const ChatItemDisplay = memo(({
  chatItem,
  stream,
  index,
  backIndices,
  snippets,
  className,
  userMentionedPullRequest,
  userMentionedPullRequests,
  setUserMentionedPullRequest_,
  onValidatePR,
  setSnippets,
  removeChanges,
  startChatStream,
  chatWithAgent,
  authorizedFetch,
  // fixPrValidationErrors,
  octokit,
  currentOwnerOfChatRef,
}: {
  chatItem: ChatItem
  stream: MutableRefObject<ReadableStreamDefaultReader<Uint8Array> | undefined>
  index: number
  backIndices: number[]
  snippets: Snippet[]
  className?: string
  userMentionedPullRequests: PullRequest[]
  userMentionedPullRequest: PullRequest | undefined,
  setUserMentionedPullRequest_: Dispatch<SetStateAction<PullRequest | undefined>>
  onValidatePR?: (pr: PullRequest) => void
  setSnippets: Dispatch<SetStateAction<Snippet[]>>
  removeChanges: () => void
  startChatStream: (message: string, newMessages: Message[], snippets: Snippet[], annotations: Message["annotations"], branch: string) => Promise<void>
  chatWithAgent: (currentMessages: Message[], currentSnippets: Snippet[], branch: string, currentAppliedChanges: StatefulCodeSuggestion[]) => Promise<void>
  authorizedFetch: (url: string, body?: { [key: string]: any }, options?: RequestInit) => Promise<Response>
  // fixPrValidationErrors: () => void
  octokit: Octokit | undefined
  currentOwnerOfChatRef: MutableRefObject<string>
}) => {
  if (!(Array.isArray(chatItem))) {
    return (
      <MessageDisplay
        index={index}
        stream={stream}
        backIndices={backIndices}
        message={chatItem as Message}
        snippets={snippets}
        className={className}
        userMentionedPullRequests={userMentionedPullRequests}
        userMentionedPullRequest={userMentionedPullRequest}
        setUserMentionedPullRequest_={setUserMentionedPullRequest_}
        onValidatePR={onValidatePR}
        setSnippets={setSnippets}
        removeChanges={removeChanges}
        startChatStream={startChatStream}
        chatWithAgent={chatWithAgent}
        authorizedFetch={authorizedFetch}
        // fixPrValidationErrors={fixPrValidationErrors}
        octokit={octokit}
        currentOwnerOfChatRef={currentOwnerOfChatRef}
      />
    )
  } else {
    return <ToolCallGroupCarousel toolCallGroups={chatItem} />
  }
});

ChatItemDisplay.displayName = 'ChatItemDisplay';

export default ChatItemDisplay;
