'use client';

import React, { useEffect, useState } from 'react';
import { useSession } from '@/hooks/useSession';
import { PostHogProvider } from 'posthog-js/react';
import { Provider } from 'jotai';
import { BACKEND_URL } from '@/lib/constants';
import { ThemeProvider } from '@/components/theme-provider';
import { toast } from '@/components/ui/use-toast';
import { Toaster } from '@/components/ui/toaster';
import MetricsDisplay from '@/components/MetricsDisplay';

const MetricsPage: React.FC = () => {
  const [metricsData, setMetricsData] = useState<any[]>([]);
  const { accessToken } = useSession();

  const fetchTelemetry = async (
    url: string,
    body: Record<string, any> = {},
    options: RequestInit = {}
  ) => {
    try {
      // TODO: refactor this out
    const response = await fetch(`${BACKEND_URL}/backend${url}`, {
        method: options.method || 'POST',
        headers: {
            ...options.headers,
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body:
            body && options.method !== 'GET'
            ? JSON.stringify({
                ...body,
                })
            : undefined,
        ...options,
        })


      if (!response.ok) {
        throw new Error('Failed to fetch telemetry: ' + response.statusText);
      }

      const data = await response.json();
      setMetricsData(data);
    } catch (error) {
      toast({
        title: 'Error fetching telemetry',
        description: error.message,
        variant: 'destructive',
      })
    }
    console.log(metricsData)
  };

  useEffect(() => {
    if (accessToken) {
        fetchTelemetry('/metrics', {}, {
            method: 'GET',
        })
    }
  }, [accessToken])

  return <MetricsDisplay data={metricsData} />;
};

export default function WrappedMetrics() {
  const component = (
    <ThemeProvider>
      <Toaster/>
      <Provider>
        <MetricsPage/>
      </Provider>
    </ThemeProvider>
  )
  if (import.meta.env.NEXT_PUBLIC_POSTHOG_KEY) {
    return (
      <PostHogProvider>
        {component}
      </PostHogProvider>
    )
  }
  return component
}
