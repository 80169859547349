import { Dispatch, SetStateAction, memo, useEffect, useRef } from 'react'
import { StatefulCodeSuggestion } from '@/lib/types'
import CodeMirrorMerge from 'react-codemirror-merge'
import { dracula } from '@uiw/codemirror-theme-dracula'
import { isEqual } from 'lodash'
import { languageMapping } from '@/lib/constants';
import { EditorView } from '@uiw/react-codemirror'

const Original = CodeMirrorMerge.Original
const Modified = CodeMirrorMerge.Modified

function CodeMirrorSuggestionEditor({
  suggestion,
  setSuggestedChange,
  splitDiff = false,
}: {
  suggestion: StatefulCodeSuggestion
  setSuggestedChange: Dispatch<SetStateAction<StatefulCodeSuggestion>>
  splitDiff?: boolean
}) {
  const fileExtension = suggestion.filePath.split('.').pop()
  // default to javascript
  let languageExtension = languageMapping['js']
  if (fileExtension) {
    languageExtension = languageMapping[fileExtension]
  }

  const isUpdatingRef = useRef(false)

  useEffect(() => {
    isUpdatingRef.current = false
  }, [suggestion])

  return (
    <>
      <CodeMirrorMerge
        theme={dracula}
        revertControls={splitDiff ? 'a-to-b' : undefined}
        collapseUnchanged={{
          margin: 3,
          minSize: 4,
        }}
        autoFocus={false}
        key={JSON.stringify(suggestion)}
        className={
          (splitDiff ? '' : 'hideOriginal ') + 'max-h-[800px] overflow-y-auto'
        }
        highlightChanges={false}
      >
        <Original
          value={suggestion.originalCode}
          readOnly
          extensions={[
            EditorView.editable.of(false),
            EditorView.lineWrapping,
            ...(languageExtension ? [languageExtension] : []),
          ]}
        />
        <Modified
          value={suggestion.newCode}
          readOnly={
            !(suggestion.state == 'done' || suggestion.state == 'error')
          }
          extensions={[
            EditorView.editable.of(
              suggestion.state == 'done' || suggestion.state == 'error'
            ),
            EditorView.lineWrapping,
            ...(languageExtension ? [languageExtension] : []),
          ]}
          onChange={(value) => {
            setSuggestedChange(
              new StatefulCodeSuggestion({
                ...suggestion,
                newCode: value,
              })
            )
          }}
        />
      </CodeMirrorMerge>
    </>
  )
}

const MemoizedCodeMirrorSuggestionEditor = memo(
  CodeMirrorSuggestionEditor,
  ({
    suggestion: prevSuggestion,
    splitDiff: prevSplitDiff,
  }, {
    suggestion: nextSuggestion,
    splitDiff: nextSplitDiff,
  }) => {
    return isEqual(prevSuggestion, nextSuggestion) && (prevSplitDiff === nextSplitDiff)
  }
)

export default MemoizedCodeMirrorSuggestionEditor