import { useEffect, useState } from 'react'
import { FaExclamationTriangle } from 'react-icons/fa'
import { FaArrowsRotate } from 'react-icons/fa6'
import { version } from '../lib/constants'

export function VersionChecker() {
  const [outdated, setOutdated] = useState<boolean>(false)

  useEffect(() => {
    const checkVersion = async () => {
      const response = await fetch('/api/version')
      const data = await response.json()
      if (data.version !== version) {
        setOutdated(true)
      }
    }

    checkVersion()
    const interval = setInterval(checkVersion, 5 * 60_000) // Check every 5 minutes

    return () => clearInterval(interval)
  }, [])

  return (
    <div
      className={`
        text-xs rounded-md 
        ${outdated ? 'bg-red-100 text-red-500 p-2' : 'text-gray-500'}
        transition-colors duration-300 ease-in-out
      `}
    >
      {outdated ? (
        <div>
          <FaExclamationTriangle className="mt-[-2px] mr-2 inline" />
          A new version is available. Please refresh the page to get the latest
          changes. (version {version?.slice(-6)}) <FaArrowsRotate className="inline mt-[-2px] ml-2 cursor-pointer" onClick={() => window.location.reload()} />
        </div>
      ) : (
        <>
          Version: {version?.slice(-6)}
        </>
      )}
    </div>
  )
}
