import posthog from 'posthog-js'

if (typeof window !== 'undefined' && import.meta.env.NEXT_PUBLIC_POSTHOG_KEY) {
  posthog.init(import.meta.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: '/ingest',
    ui_host: 'https://us.posthog.com',
    disable_session_recording: false,
    enable_recording_console_log: true,
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
        text: false,
        url: false,
        textarea: false,
      },
      maskInputFn(text: string, element?: HTMLElement) {
        const maskTypes = ['email', 'password']

        if (
          element &&
          (maskTypes.includes(element.getAttribute('type') || '') ||
            maskTypes.includes(element.getAttribute('id') || ''))
        ) {
          return '*'.repeat(text.length)
        }
        return text
      },
    },
  })
  posthog.debug(true)
}

export { posthog }
