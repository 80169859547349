import { useEffect } from 'react';
import { BACKEND_URL } from '@/lib/constants'
import { useSession } from '@/hooks/useSession';

const MetricsCSVPage = () => {
  const { accessToken } = useSession();

  useEffect(() => {
    if (accessToken) {
      const downloadCSV = async () => {
        try {
          const response = await fetch(`${BACKEND_URL}/backend/api/metrics.csv`, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          });
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = 'metrics.csv';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Error downloading CSV:', error);
        }
      };
      downloadCSV();
    }
  }, [accessToken]);

  return (
    <div className="text-white flex flex-col h-screen text-center items-center justify-center">
      <p>Download starting for metrics.csv</p>
      <br />
      <p>If the download doesn't start, please try to <a className="text-blue-500" href={`${BACKEND_URL}/backend/login`}>login</a> again.</p>
    </div>
  );
};

export default MetricsCSVPage;