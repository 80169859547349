import React, { useEffect, useMemo, useState } from 'react'
import { currentMessageState, useRewriteState } from '@/state/atoms'

import { useAtomValue, useSetAtom } from 'jotai'
import { GITHUB_BASE_URL } from "@/lib/constants";
import { useSession } from '../hooks/useSession'
import { repoNameState } from '../state/atoms'

import { Card, CardHeader, CardTitle, CardContent } from './ui/card'
import { FaBug, FaCode, FaCodeBranch, FaVial } from 'react-icons/fa';

// Task type constants for better maintainability
export const TASK_TYPES = {
  EXPLAIN_CODE: 'Explain complex code',
  ADD_TESTS: 'Add unit tests',
  ADD_PR_TESTS: 'Add unit tests to a PR',
  FIX_BUG: 'Fix a bug',
} as const;

const getExampleTasks = (): [string[], string[], React.ReactNode[]] => {
  const defaultExamples = [
    TASK_TYPES.EXPLAIN_CODE,
    TASK_TYPES.ADD_TESTS,
    TASK_TYPES.ADD_PR_TESTS,
    TASK_TYPES.FIX_BUG,
  ]
  const defaultExamplesInputs = [
    "Explain how <class_name> works to me.",
    'Add unit tests for path/to/file/a.py. You can look at path/to/file/test_a.py as an example.',
    'In <pr_link>, add unit tests for path/to/file/a.py. You can look at path/to/file/test_a.py as an example.',
    'In path/to/file/a.py, there is a bug where <bug description>. The desired behaviour is <desired behaviour>. Explain why this bug is happening and how to fix it.',
  ]
  const icons = [
    <FaCode className="inline mr-2 mt-[-3px]" />,
    <FaVial className="inline mr-2 mt-[-3px]" />,
    <FaCodeBranch className="inline mr-2 mt-[-3px]" />,
    <FaBug className='inline mr-2 mt-[-3px]' />,
  ]

  let exampleTasks
  try {
    exampleTasks = import.meta.env.NEXT_PUBLIC_EXAMPLE_TASKS
      ? JSON.parse(import.meta.env.NEXT_PUBLIC_EXAMPLE_TASKS)
      : defaultExamples
  } catch {
    exampleTasks = defaultExamples
  }

  let exampleTasksInputs
  try {
    exampleTasksInputs = import.meta.env.NEXT_PUBLIC_EXAMPLE_TASKS_INPUTS
      ? JSON.parse(import.meta.env.NEXT_PUBLIC_EXAMPLE_TASKS_INPUTS)
      : defaultExamplesInputs
  } catch {
    exampleTasksInputs = defaultExamplesInputs
  }

  return [exampleTasks, exampleTasksInputs, icons]
}

const ExampleTasks = () => {
  const [latestPRLink, setLatestPRLink] = useState<string | undefined>()
  const setCurrentMessage = useSetAtom(currentMessageState)
  const setUseRewrite = useSetAtom(useRewriteState)
  const repoName = useAtomValue(repoNameState)
  const { accessToken, octokit } = useSession()
  const [exampleTasks, exampleTasksInputs, icons] = useMemo(getExampleTasks, [])

  useEffect(() => {
    const fetchLatestPR = async () => {
      if (!accessToken || !repoName || !octokit) return

      try {
        const [owner, repo] = repoName.split('/')
        const response = await octokit.rest.pulls.list({
          owner,
          repo,
          state: 'open',
          sort: 'created',
          direction: 'desc',
          per_page: 1,
        })

        if (response.data.length > 0) {
          const prNumber = response.data[0].number
          setLatestPRLink(`https://${GITHUB_BASE_URL}/${repoName}/pull/${prNumber}`)
        }
      } catch (error) {
        console.error('Error fetching latest PR:', error)
      }
    }

    fetchLatestPR()
  }, [accessToken, repoName])

  const handleTaskClick = useMemo(() => (task: string, index: number) => {
    if (latestPRLink) {
      task = task.replace('<pr_link_goes_here>', latestPRLink)
    }
    // Set rewrite state based on the task type
    const isExplainCode = exampleTasks[index] === TASK_TYPES.EXPLAIN_CODE
    setUseRewrite(!isExplainCode) // Disable rewrite for "Explain complex code", enable for others
    setCurrentMessage(task)
    setTimeout(() => {
      const textarea = document.querySelector(
        '.sweep-chat-text-area'
      ) as HTMLTextAreaElement
      if (textarea) {
        textarea.focus()
        // Set initial height to prevent flickering
        const currentHeight = textarea.scrollHeight;
        textarea.style.height = `${currentHeight}px`;
        textarea.style.transition = 'height 0.2s ease-in-out';
        
        // Use RAF to ensure smooth transition
        requestAnimationFrame(() => {
          textarea.style.height = `${textarea.scrollHeight}px`;
        });
        
        textarea.setSelectionRange(textarea.value.length, textarea.value.length)
      }
    }, 0)
  }, [latestPRLink, setCurrentMessage, setUseRewrite, exampleTasks])

  return (
    <>
      <div className="example-tasks max-w-3xl mx-auto text-center h-full grid grid-cols-2 gap-6 mb-6">
        {exampleTasks?.map((task: string, index: number) => (
          <Card
            key={index}
            className="bg-zinc-800 transition-colors cursor-pointer border-zinc-700 text-left opacity-80 hover:opacity-100"
            onClick={() =>
              exampleTasksInputs &&
              exampleTasksInputs[index] &&
              handleTaskClick(exampleTasksInputs[index], index)
            }
          >
            <CardHeader className="pb-4">
              <CardTitle className="text-white text-lg">
                {icons[index]}
                {task}
              </CardTitle>
            </CardHeader>
            <CardContent className="text-zinc-400 text-xs ">
              {exampleTasksInputs[index]}
            </CardContent>
          </Card>
        ))}
      </div>
    </>
  )
}

export default ExampleTasks