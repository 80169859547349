import { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { isWhitelistedState } from '@/state/atoms'
import useAuthorizedFetch from './useAuthorizedFetch'

export const useCheckWhitelist = () => {
  const [isWhitelisted, setIsWhitelisted] = useAtom(isWhitelistedState)
  const [isCheckingWhitelist, setIsCheckingWhitelist] = useState(true)
  const authorizedFetch = useAuthorizedFetch()

  useEffect(() => {
    const attemptWhitelistCheck = async () => {
      try {
        const res = await authorizedFetch('/check_whitelist', {}, {}, false)
        return res.ok
      } catch (e) {
        console.error('Whitelist check failed:', e)
        throw e
      }
    }

    (async () => {
      try {
        setIsCheckingWhitelist(true)
        try {
          const isWhitelisted = await attemptWhitelistCheck()
          setIsWhitelisted(isWhitelisted)
          return
        } catch (firstError) {
          console.log('First whitelist check failed, retrying...', firstError)
        }
        await new Promise(resolve => setTimeout(resolve, 1000))
        try {
          const isWhitelisted = await attemptWhitelistCheck()
          setIsWhitelisted(isWhitelisted)
        } catch (secondError) {
          console.log('Second whitelist check failed', secondError)
          setIsWhitelisted(false)
        }
      } catch (e) {
        console.error('All whitelist check attempts failed:', e)
        setIsWhitelisted(false)
      } finally {
        setIsCheckingWhitelist(false)
      }
    })()
  }, [])

  return {
    isWhitelisted,
    isCheckingWhitelist
  }
}