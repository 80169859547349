import { appliedChangesAtom, branchState, featureBranchState, finishedCreatingBranchState, forkMessageState, messagesIdState, messagesState, prValidationStatusesState, pullRequestBodyState, pullRequestState, pullRequestTitleState, repoNameState, repoNameValidState, snippetsState, suggestedChangesState } from "@/state/atoms"
import { atom, useAtom, useSetAtom } from "jotai"
import { useSession } from "./useSession"
import { useCallback, useEffect, useRef } from "react"
import { useMatch } from "react-router-dom"
import useAuthorizedFetch from "./useAuthorizedFetch"
import { toast } from "@/components/ui/use-toast"
import { toSnakeCaseKeys } from "@/lib/strUtils"
import { Message, PrValidationStatus, PullRequest, Snippet, StatefulCodeSuggestion } from "@/lib/types"
import { debounce } from "lodash"

export const lastLoadedTimestampAtom = atom(0)

export const useFetchMessages = () => {
  const match = useMatch("/c/:defaultMessagesId");
  const defaultMessagesId = match?.params?.defaultMessagesId || "";
  const setMessagesId = useSetAtom(messagesIdState)
  const { accessToken, username } = useSession()
  const authorizedFetch = useAuthorizedFetch()
  const [lastLoadedTimestamp, setLastLoadedTimestamp] = useAtom(lastLoadedTimestampAtom)

  const previousRepoNameRef = useRef<string>('')
  const currentOwnerOfChatRef = useRef<string>('')

  // States to set
  const setRepoName = useSetAtom(repoNameState)
  const setRepoNameValid = useSetAtom(repoNameValidState)
  const setBranch = useSetAtom(branchState)
  const setFeatureBranch = useSetAtom(featureBranchState)
  const setMessages = useSetAtom(messagesState)
  const setSnippets = useSetAtom(snippetsState)
  const setSuggestedChanges = useSetAtom(suggestedChangesState)
  const setPullRequest = useSetAtom(pullRequestState)
  const setPullRequestTitle = useSetAtom(pullRequestTitleState)
  const setPullRequestBody = useSetAtom(pullRequestBodyState)
  const setAppliedChanges = useSetAtom(appliedChangesAtom)
  const setPrValidationStatuses = useSetAtom(prValidationStatusesState)
  const setFinishedCreatingBranch = useSetAtom(finishedCreatingBranchState)

  // Kevin: TBH idk what this is for
  const [,setForkMessage] = useAtom(forkMessageState)

  const checkChatOwner = async () => {
    try {
      const response = await authorizedFetch(
        `/get_chat_owner/${defaultMessagesId}`,
        {},
        { method: 'GET' }
      )
      const data = await response.json()
      return data.username
    } catch {
      return ''
    }
  }

  useEffect(() => {
    if (defaultMessagesId !== '') {
      checkChatOwner().then((chatOwner: string) => {
        currentOwnerOfChatRef.current =
          chatOwner || username || ''
      })
    }
  }, [])

  useEffect(() => {
    if (defaultMessagesId && accessToken) {
      loadMessages(defaultMessagesId)
    }
  }, [defaultMessagesId, accessToken])

  const loadMessages = async (messagesId: string) => {
    setMessagesId(messagesId)
    const response = await authorizedFetch(
      `/messages/load/${messagesId}?last_timestamp=${lastLoadedTimestamp}`,
      {},
      {
        method: 'GET',
      }
    )
    const data = await response.json()
    if (data.status == 'success') {
      const {
        repo_name,
        branch,
        feature_branch,
        messages,
        snippets,
        code_suggestions,
        pull_request,
        pull_request_title,
        pull_request_body,
        timestamp,
        applied_changes,
        pr_validation_statuses,
        username,
        finished_creating_branch,
      } = data.data
      console.log(
        `Loaded ${messages.length} messages from ${messagesId} with timestamp ${timestamp} and username ${username}`
      )
      setRepoName(repo_name)
      if (branch) {
        setBranch(branch)
      }
      if (feature_branch) {
        setFeatureBranch(feature_branch)
      }
      previousRepoNameRef.current = repo_name
      setRepoNameValid(true)
      setMessages(messages)
      setSnippets(snippets)
      setSuggestedChanges(code_suggestions)
      setPullRequest(pull_request)
      setPullRequestTitle(pull_request_title)
      setPullRequestBody(pull_request_body)
      setLastLoadedTimestamp(timestamp)
      setAppliedChanges(applied_changes || [])
      setPrValidationStatuses(pr_validation_statuses || [])
      setFinishedCreatingBranch(finished_creating_branch)
    } else if (data.status == 'no_update') {
      console.log('No new updates available')
    } else {
      toast({
        title: 'Failed to load messages',
        description: `The following error has occurred: ${data.error}`,
        variant: 'destructive',
        className: 'whitespace-break-spaces',
      })
    }
  }

  const save = useCallback(
    async (
      repoName: string,
      branch: string,
      featureBranch: string | undefined,
      messages: Message[],
      snippets: Snippet[],
      messagesId: string,
      userMentionedPullRequest: PullRequest | undefined,
      userMentionedPullRequests: PullRequest[] | undefined,
      commitToPR: boolean = false,
      suggestedChanges: StatefulCodeSuggestion[],
      pullRequest: PullRequest | undefined,
      pullRequestTitle: string | undefined,
      pullRequestBody: string | undefined,
      forkMessage: boolean = false,
      appliedChanges: StatefulCodeSuggestion[],
      prValidationStatuses: PrValidationStatus[],
      finishedCreatingBranch: boolean = false
    ) => {
      if (!accessToken) {
        return
      }
      const commitToPRString: string = commitToPR ? 'true' : 'false'
      const forkMessageString: string = forkMessage ? 'true' : 'false'
      const finishedCreatingBranchString: string = finishedCreatingBranch
        ? 'true'
        : 'false'
      const timestamp = Date.now()
      currentOwnerOfChatRef.current =
        currentOwnerOfChatRef.current || username || ''
      const saveResponse = await authorizedFetch(
        '/messages/save',
        toSnakeCaseKeys({
          repoName,
          branch,
          featureBranch,
          messages,
          snippets,
          messageId: messagesId,
          codeSuggestions: suggestedChanges,
          pullRequest,
          pullRequestTitle,
          pullRequestBody,
          userMentionedPullRequest,
          userMentionedPullRequests,
          commitToPr: commitToPRString,
          forkMessage: "false", // fork message causes strange bugs, disabling for now
          timestamp,
          appliedChanges,
          prValidationStatuses,
          username: currentOwnerOfChatRef.current,
          finishedCreatingBranch: finishedCreatingBranchString,
        })
      )
      const saveData = await saveResponse.json()
      console.log(
        `Saving ${messages.length} messages to ${messagesId} with timestamp ${timestamp} and username ${currentOwnerOfChatRef.current}`
      )
      if (saveData.status === 'success') {
        const { message_id } = saveData
        if (message_id && message_id !== messagesId && !messagesId) {
          setMessagesId(message_id)
          
          // You might want to do something with updatedUrl here, like updating the browser's URL
        }
      } else {
        console.warn('Failed to save message', saveData)
      }
    },
    [authorizedFetch]
  )
  const debouncedSave = useCallback(
    debounce(
      (...args: Parameters<typeof save>) => {
        save(...args)
        setForkMessage(false)
      },
      5000,
      { leading: true, maxWait: 10_000 }
    ),
    [currentOwnerOfChatRef.current]
  ) // can tune these timeouts

  return {
    previousRepoNameRef,
    currentOwnerOfChatRef,
    save,
    debouncedSave,
    loadMessages,
  }
}