import React, { useEffect, useState } from "react";
import {
  Card,
  LineChart,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Title
} from "@tremor/react";
import { Button } from "./ui/button";

interface Metric {
  last_modified_date: string;
  message_link: string;
  sessions_count: number;
  percentage_of_pr_lines_merged: number;
  number_of_pr_lines_merged: number;
  pull_request_url: string;
  org_name: string;
  repo_name: string;
  username: string;
}

interface WeeklySummary {
  weekStart: string;
  totalLinesMerged: number;
  cumulativeLinesMerged: number;
  weeklySessions: number;
}

interface MetricsDisplayProps {
  data: Metric[];
}

const MetricsDisplay: React.FC<MetricsDisplayProps> = ({ data }) => {
  if (!data || data.length === 0) {
    return 0;
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const columns: (keyof Metric)[] = [
    "last_modified_date",
    "message_link",
    "percentage_of_pr_lines_merged",
    "number_of_pr_lines_merged",
    "pull_request_url",
    "org_name",
    "repo_name",
    "username",
  ];

  const getBaseUrl = () => {
    if (typeof window !== 'undefined') {
      return `${window.location.protocol}//${window.location.host}`;
    }
    return '';
  };

  const MetricsTitle = () => {
    const [host, setHost] = useState("");

    useEffect(() => {
      setHost(window.location.host);
    }, []);

    return (
      <Title className="text-white text-4xl font-bold">
        Metrics for <a href="/" className="no-underline hover:underline" target="_blank" rel="noopener noreferrer">{host}</a>
      </Title>
    );
  };

  // Sum number_of_pr_lines_merged by day
  const summedChartData = data.reduce((acc, item) => {
    const date = new Date(item.last_modified_date).toISOString().split('T')[0];
    if (!acc[date]) {
      acc[date] = 0;
    }
    acc[date] += item.number_of_pr_lines_merged;
    return acc;
  }, {} as { [key: string]: number });

  // Helper function to get all dates between two dates
  const getDatesInRange = (startDate: Date, endDate: Date) => {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates.push(currentDate.toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const linesMergedMap = new Map(Object.entries(summedChartData).map(([date, sums]) => [date, sums]));
  const sessionsMap = new Map(data
    .filter(item => item.sessions_count !== undefined)
    .map(item => [item.last_modified_date.split(' ')[0], item.sessions_count]));

  // Find the earliest and latest dates across both datasets
  const allDates = [...linesMergedMap.keys(), ...sessionsMap.keys()];
  const minDate = new Date(Math.min(...allDates.map(d => new Date(d).getTime())));
  const maxDate = new Date(Math.max(...allDates.map(d => new Date(d).getTime())));
  // Generate all dates in the range
  const dateRange = getDatesInRange(minDate, maxDate);

  // Fill in missing dates with 0 values
  const filledLinesMergedData = dateRange.map(date => ({
    date,
    "Lines Merged": linesMergedMap.get(date) || 0
  }));
  const filledSessionsData = dateRange.map(date => ({
    date,
    "Sessions": sessionsMap.get(date) || 0
  }));

  const getWeekStart = (date: Date): Date => {
    const d = new Date(date);
    d.setDate(d.getDate() - d.getDay());
    d.setHours(0, 0, 0, 0);
    return d;
  };

  const weeklySummary: WeeklySummary[] = (() => {
    // Sort data by date in descending order
    const sortedData = [...data].sort((a, b) =>
      new Date(b.last_modified_date).getTime() - new Date(a.last_modified_date).getTime()
    );

    if (sortedData.length === 0) return [];

    const mostRecentDate = new Date(sortedData[0].last_modified_date);
    const weeklyData: WeeklySummary[] = [];
    let currentWeekStart = getWeekStart(mostRecentDate);
    let currentWeekData: WeeklySummary = {
      weekStart: currentWeekStart.toISOString().split('T')[0],
      totalLinesMerged: 0,
      cumulativeLinesMerged: 0,
      weeklySessions: 0
    };

    sortedData.forEach(item => {
      const itemDate = new Date(item.last_modified_date);
      if (itemDate >= currentWeekStart) {
        // Item belongs to the current week
        currentWeekData.totalLinesMerged += item.number_of_pr_lines_merged;
        currentWeekData.weeklySessions += item.sessions_count || 0;
      } else {
        // Start a new week
        weeklyData.push(currentWeekData);
        currentWeekStart = new Date(currentWeekStart.getTime() - 7 * 24 * 60 * 60 * 1000);
        currentWeekData = {
          weekStart: currentWeekStart.toISOString().split('T')[0],
          totalLinesMerged: item.number_of_pr_lines_merged,
          cumulativeLinesMerged: 0,
          weeklySessions: item.sessions_count || 0
        };
      }
    });

    // Add the last week's data
    weeklyData.push(currentWeekData);

    // Calculate cumulative total
    let runningTotal = 0;
    // This double reverse is not ideal but we do this so that we can show the most recent weeks at the top
    // and correctly calculate the cumulative lines merged
    weeklyData.reverse().forEach(week => {
      runningTotal += week.totalLinesMerged;
      week.cumulativeLinesMerged = runningTotal;
    });
    weeklyData.reverse();
    
    return weeklyData;
  })();

  const downloadCSV = () => {
    // Convert data to CSV
    const csvContent = [
      columns.join(',') + ',Weekly Sessions', // header
      ...data.map(row =>
        columns.map(column => {
          let cellData = row[column];
          if (typeof cellData === 'string' && cellData.includes(',')) {
            // Wrap in quotes if contains comma
            cellData = `"${cellData}"`;
          }
          return cellData;
        }).join(',') + ',' + (row.sessions_count || 0)
      )
    ].join('\n');

    // Create Blob and download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "metrics.csv");
      link.style.visibility = 'hidden';
      document.body.append(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="bg-zinc-900 p-6 min-h-screen w-full">
      <MetricsTitle />
      {/* Tremor Line Chart for Sum of Lines Merged */}
      <Card className="mt-8 bg-zinc-900">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-white">Sweep Lines of Code Merged</h2>
        </div>
        <LineChart
          className="mt-4 h-80 text-white [&_.recharts-cartesian-axis-tick-value]:fill-white [&_.recharts-line]:stroke-white [&_.recharts-dot]:stroke-white [&_.recharts-dot]:fill-white"
          data={filledLinesMergedData}
          index="date"
          categories={["Lines Merged"]}
          colors={["white"]}
          yAxisWidth={60}
          showLegend={false}
          showXAxis
          showYAxis
          showGridLines
          curveType="linear"
          connectNulls
          showAnimation
          showTooltip
          autoMinValue
          valueFormatter={(v) => v.toFixed(0)}
        />
      </Card>

      {/* Tremor Line Chart for Sessions */}
      <Card className="mt-8 bg-zinc-900">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-white">Sweep Sessions</h2>
        </div>
        <LineChart
          className="mt-4 h-80 text-white [&_.recharts-cartesian-axis-tick-value]:fill-white [&_.recharts-line]:stroke-purple-400 [&_.recharts-dot]:stroke-purple-400 [&_.recharts-dot]:fill-purple-400"
          data={filledSessionsData}
          index="date"
          categories={["Sessions"]}
          colors={["white"]}
          yAxisWidth={60}
          showLegend={false}
          showXAxis
          showYAxis
          showGridLines
          curveType="linear"
          connectNulls
          showAnimation
          showTooltip
          autoMinValue
          valueFormatter={(v) => v.toFixed(0)}
        />
      </Card>

      {/* Tremor Table */}
      <Card className="mt-4 bg-zinc-900">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-white">Raw Metrics</h2>
          <Button onClick={downloadCSV} size="sm" variant="secondary" color="blue">
            Download CSV
          </Button>
        </div>
        <div className="overflow-x-scroll">
          <Table>
            <TableHead>
              <TableRow className="border-b border-gray-700">
                {columns.map((column) => (
                  <TableHeaderCell
                    key={column}
                    className="text-gray-300 font-semibold whitespace-normal"
                  >
                    {column.replaceAll("_", " ").replaceAll(/\b\w/g, (l) => l.toUpperCase())}
                  </TableHeaderCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, rowIndex) => (
                <TableRow key={rowIndex} className="border-b border-gray-700">
                  {columns.map((column) => (
                    <TableCell key={`${rowIndex}-${column}`} className="text-gray-200">
                      {column === "last_modified_date" ? (
                        formatDate(row[column])
                      ) : column === "message_link" ? (
                        <a href={`${getBaseUrl()}/c/${row[column]}`} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">
                          {row[column]}
                        </a>
                      ) : column === "pull_request_url" ? (
                        <a href={row[column]} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">
                          {row[column]}
                        </a>
                      ) : column === "percentage_of_pr_lines_merged" ? (
                        `${row[column]}%`
                      ) : (
                        row[column]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        
        <Card className="mt-8 bg-zinc-900">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-white">Weekly Summary</h2>
        </div>
        <Table>
          <TableHead>
            <TableRow className="border-b border-gray-700">
              <TableHeaderCell className="text-gray-300 font-semibold">Week Starting</TableHeaderCell>
              <TableHeaderCell className="text-gray-300 font-semibold">Total Lines Merged</TableHeaderCell>
              <TableHeaderCell className="text-gray-300 font-semibold">Cumulative Lines Merged</TableHeaderCell>
              <TableHeaderCell className="text-gray-300 font-semibold">Weekly Sessions</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {weeklySummary.map((week, index) => (
              <TableRow key={index} className="border-b border-gray-700">
                <TableCell className="text-gray-200">{week.weekStart}</TableCell>
                <TableCell className="text-gray-200">{week.totalLinesMerged}</TableCell>
                <TableCell className="text-gray-200">{week.cumulativeLinesMerged}</TableCell>
                <TableCell className="text-gray-200">{week.weeklySessions}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
      </Card>
    </div>
  );
};

export default MetricsDisplay;