import { createContext, useContext, useState, Dispatch, SetStateAction } from "react"
import { Button } from "./button"

const CarouselContext = createContext<{ 
  currentIndex: number, 
  setCurrentIndex: Dispatch<SetStateAction<number>>,
  maxIndex: number
}>({ 
  currentIndex: 0,
  setCurrentIndex: () => {},
  maxIndex: 0
})

export const Carousel = ({ children, items, className }: { children: React.ReactNode, items: React.ReactNode[], className?: string }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const maxIndex = items.length - 1
  return (
    <CarouselContext.Provider value={{ currentIndex, setCurrentIndex, maxIndex }}>
      <div className={className}>
        <div className="my-4 mb-8">
          {items[currentIndex]}
        </div>
        {children}
      </div>
    </CarouselContext.Provider>
  )
}

export const CarouselNavigatorButtons = () => {
  const { currentIndex, setCurrentIndex, maxIndex } = useContext(CarouselContext)
  return (
    <div className="flex justify-start space-x-2">
      <Button variant="ghost" className="text-blue-500 hover:text-blue-500" onClick={() => setCurrentIndex(Math.max(0, currentIndex - 1))} disabled={currentIndex === 0}>Back</Button>
      {currentIndex < maxIndex && (
        <Button variant="ghost" className="text-blue-500 hover:text-blue-500" onClick={() => setCurrentIndex(Math.min(maxIndex, currentIndex + 1))}>Next</Button>
      )}
    </div>
  )
}