import { messagesIdState, repoNameState, snippetsState } from "@/state/atoms"
import { useAtomValue } from "jotai"
import { usePostHog } from "posthog-js/react"
import { useCallback } from "react"


export default function usePostHogCapture() {
  const posthog = usePostHog()

  const repoName = useAtomValue(repoNameState)
  const snippets = useAtomValue(snippetsState)
  const messagesId = useAtomValue(messagesIdState)

  return useCallback(
    (event: string, metadata: { [key: string]: any } = {}) => {
      posthog.capture(event, {
        repoName,
        snippets,
        messagesId,
        ...metadata,
      })
    },
    [repoName, snippets, messagesId]
  )
}
