import { memo, useCallback} from 'react'
import { StatefulCodeSuggestion } from '@/lib/types'
// import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import ReactDiffViewer, { DiffMethod } from "@/components/diffViewer/index";
import SyntaxHighlighter from '@/lib/syntaxHighlighter'



function DiffViewer({
  suggestion,
  splitDiff = false,
  streaming = false,
}: {
  suggestion: StatefulCodeSuggestion
  splitDiff?: boolean
  streaming?: boolean
}) {
  const fileExtension = suggestion.filePath.split('.').pop()
  const renderContent = useCallback((str: string) => {
    return (
      <SyntaxHighlighter
        language={fileExtension}
        customStyle={{
          wordBreak: 'break-all',
        }}
        className="!bg-transparent !p-0 !m-0 !break-all !overflow-y-hidden whitespace-pre-wrap SyntaxHighlighter border-radius-none"
      >
        {str}
      </SyntaxHighlighter>
    )
  }, [fileExtension])
  return (
    <ReactDiffViewer
      // For reference: https://github.com/aeolun/react-diff-viewer-continued?tab=readme-ov-file#overriding-styles
      styles={{
        diffContainer: {
          fontSize: '0.75rem',
          "& pre": {
            lineHeight: "20px",
            padding: 0,
          },
          borderTop: '1px solid hsl(215, 18%, 18%)',
        },
        marker: {
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
        },
        gutter: {
          minWidth: "35px",
          paddingRight: "8px",
          paddingLeft: "8px",
        },
        codeFold: {
          '& > *:first-child': !splitDiff ? {
            display: 'none',
          } : {},
          '& > *:nth-child(2)': splitDiff ? {
            display: 'none',
          } : {},
          '& > *:nth-child(3)': !splitDiff ? {
            display: 'none',
          } : {},
          '& > *:nth-child(5)': splitDiff ? {
            display: 'none',
          } : {},
          "& a": {
            textDecoration: "none !important",
            // fontWeight: "normal",
            fontSize: '0.75rem',
          }
        },
        line: {
          '& > *:first-child': !splitDiff ? {
            display: 'none',
          } : {},
          '& > *:nth-child(2)': splitDiff ? {
            display: 'none',
          } : {},
          '& > *:nth-child(3)': !splitDiff ? {
            display: 'none',
          } : {
            userSelect: 'none', // hack to prevent LHS to be selected
          },
          '& > *:nth-child(5)': splitDiff ? {
            display: 'none',
          } : {},
          wordBreak: "break-all",
        },
        variables: {
          dark: {
            addedBackground: "hsl(150, 32%, 23%)",
            addedGutterBackground: "hsl(150, 32%, 23%)",
            addedGutterColor: "hsl(220, 13%, 97%)",
            wordAddedBackground: "hsl(150, 32%, 23%)",
            removedBackground: "hsl(350, 25%, 26%)",
            removedGutterBackground: "hsl(350, 25%, 26%)",
            removedGutterColor: "hsl(220, 13%, 97%)",
            wordRemovedBackground: "hsl(350, 25%, 26%)",
            diffViewerBackground: "hsl(240, 4%, 20%)",
            emptyLineBackground: "hsl(240, 4%, 23%)",
            gutterColor: "hsl(220, 13%, 91%)",
            gutterBackground: "hsl(240, 4%, 20%)",
            gutterBackgroundDark: "hsl(240, 4%, 16%)",
            codeFoldBackground: "hsl(215, 18%, 18%)",
            codeFoldGutterBackground: "hsl(215, 18%, 18%)",
            diffViewerColor: "hsl(220, 13%, 91%)", // gray-200 in HSL
          }
        }
      }}
      oldValue={suggestion.originalCode}
      newValue={suggestion.newCode}
      splitView={splitDiff}
      useDarkTheme
      renderContent={renderContent}
      compareMethod={DiffMethod.LINES}
      disableWordDiff
      streaming={streaming}
    />
  );
}

const MemoizedDiffViewer = memo(
  DiffViewer,
  ({
    suggestion: prevSuggestion,
    splitDiff: prevSplitDiff,
  }, {
    suggestion: nextSuggestion,
    splitDiff: nextSplitDiff,
  }) => {
    return prevSuggestion.originalCode == nextSuggestion.originalCode && prevSuggestion.newCode == nextSuggestion.newCode && prevSplitDiff === nextSplitDiff
  }
)

export default MemoizedDiffViewer