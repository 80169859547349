'use client'

import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  FaCodeBranch,
  FaExclamationTriangle,
  FaUndo,
} from 'react-icons/fa'
import { Button } from '@/components/ui/button'
import { useLocalStorage } from 'usehooks-ts'
import { AutoComplete } from '@/components/ui/autocomplete'
import { Toaster } from '@/components/ui/toaster'
import { toast } from '@/components/ui/use-toast'
import { ToastAction } from '@/components/ui/toast'
import Survey from './Survey'
import * as jsonpatch from 'fast-json-patch'
import ExampleTasks from './ExampleTasks'
import { DEFAULT_MODEL, modelMap } from '@/lib/constants'
import { escapeRegExp, getFirstName } from '@/lib/strUtils'
import {
  ChatItem,
  ChatSummary,
  Message,
  PrValidationStatus,
  PullRequest,
  Repository,
  Snippet,
  StatefulCodeSuggestion,
  ToolCallGroup,
} from '@/lib/types'

import { ContextSideBar } from './shared/ContextSideBar'
import parsePullRequests from '@/lib/parsePullRequest'

import { cloneDeep, trimStart, truncate } from 'lodash'
import { streamResponseMessages } from '@/lib/streamingUtils'
// @ts-expect-error
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from './ui/resizable'
import { withLoading } from '@/lib/contextManagers'
import { useDefaultedState, useUnsavedChangesAlert } from '@/lib/hooks'
import { parseFiles } from '@/lib/parseFiles'
import { VersionChecker } from './VersionChecker'
import updateFavicon from '@/lib/updateFavicon'
import { ReportBug } from './shared/ReportBug'
import { PostHogContext } from '@/lib/contexts'
import { useSession } from '@/hooks/useSession'
import { 
  appliedChangesAtom,
  branchState,
  commitMessageState,
  commitToPRState,
  currentMessageState,
  featureBranchState,
  finishedCreatingBranchState,
  forkMessageState,
  isCreatingPullRequestState,
  isLoadingState,
  isRunningCiAtom,
  isWhitelistedState,
  messagesIdState,
  messagesState, 
  prValidationStatusesState, 
  pullRequestBodyState, 
  pullRequestState, 
  pullRequestTitleState, 
  repoNameDisabledState, 
  repoNameState, 
  repoNameValidState, 
  reposState, 
  searchMessageState, 
  snippetsState, 
  streamActiveState,
  suggestedChangesState,
  useRewriteState,
} from '@/state/atoms'
import { ChatControls } from './ChatControls'
import { Navbar } from './NavBar'

import { ChatMessagesDisplay } from './ChatMessagesDisplay'
import { askNotificationPermission, countTokensForMessage, formatTime, getNewBranchName, sum } from '@/lib/appUtils'
import { useAtom, useSetAtom } from 'jotai';
import { getUserFacingErrorMessage } from '@/lib/errorMessageUtils'
import SweepExplanation from './Explanation'
import MessageEditor from './MessageEditor'
import AppWrapper from './AppWrapper'
import { useBranches } from '@/hooks/useBranches'
import { useCheckWhitelist } from '@/hooks/useCheckWhitelist'
import useAuthorizedFetch from '@/hooks/useAuthorizedFetch'
import { useFetchMessages } from '@/hooks/useFetchMessages'
import { useSurvey } from '@/hooks/useSurvey'
import { useChatActiveTimer } from '@/hooks/useChatActiveTimer'
import usePostHogCapture from '@/hooks/usePosthogCapture'
import SweepingGif from "@/../public/sweeping.gif";

type AgenticStreamState = [string, Snippet[], Message[]]

const renderWhiteListLoading = () => (
  <div className="w-full flex items-center justify-center grow">
    <div className="flex flex-col items-center gap-4 p-8">
      <h2 className="text-2xl font-semibold text-white/90">
        Checking access...
      </h2>
      <div className="w-48 h-1 bg-zinc-800 rounded-full overflow-hidden">
        <div 
          className="h-full bg-blue-500 rounded-full animate-[loading_1.5s_ease-in-out_infinite]"
        />
      </div>
    </div>
  </div>
);

// cleanPullRequestTitle removed in b69f71fe4c746e3b2c0e50991d0acf0eeafc8a52
function App() {
  const { previousRepoNameRef, debouncedSave, currentOwnerOfChatRef } = useFetchMessages()
  const previousMessagesIdRef = useRef<string | null>(null);
  const [forkMessage, setForkMessage] = useAtom(forkMessageState)
  const [model, setModel] = useLocalStorage<keyof typeof modelMap>(
    'model-v11.04',
    DEFAULT_MODEL
  ) // TODO: use atom
  const [repoName, setRepoName] = useAtom(repoNameState);
  const [branch, setBranch] = useAtom(branchState)
  
  const [repoNameValid, setRepoNameValid] = useAtom(repoNameValidState)
  const [repoNameDisabled, setRepoNameDisabled] = useAtom(repoNameDisabledState)

  // used to determine which snippets should be bolded when hovering over code entities in messages
  const [searchMessage, setSearchMessage] = useAtom(searchMessageState)
  const [snippets, setSnippets] = useAtom(snippetsState)
  const [messages, setMessages] = useAtom(messagesState)
  const [currentMessage, setCurrentMessage] = useAtom(currentMessageState)
  const [isLoading, setIsLoading] = useAtom(isLoadingState)
  const stream = useRef<ReadableStreamDefaultReader<Uint8Array> | null>(null)
  const [isStreamActive, setIsStreamActive] = useAtom(streamActiveState);
  const changesScrollAreaRef = useRef<HTMLDivElement>(null)
  const repoNameSelectorRef = useRef<HTMLInputElement>(null)
  const branchSelectorRef = useRef<HTMLInputElement>(null)
  const { showSurvey, setShowSurvey, resetShowSurveyTimeout } = useSurvey()
  const [useSearchAgent, setUseSearchAgent] = useDefaultedState<boolean>('useSearchAgentFixed', true)
  const [useRewrite] = useAtom(useRewriteState)
  const [useFollowUpSearchAgent, setUseFollowUpSearchAgent] =
    useDefaultedState<boolean>('useFollowUpSearchAgent', true)

  const [suggestedChanges, setSuggestedChanges] = useAtom(suggestedChangesState)
  const [appliedChanges, setAppliedChanges] = useAtom(appliedChangesAtom)
  
  const [pullRequestTitle, setPullRequestTitle] = useAtom(pullRequestTitleState)
  const [pullRequestBody, setPullRequestBody] = useAtom(pullRequestBodyState)
  const setCommitMessage = useSetAtom(commitMessageState)
  const setIsRunningCi = useSetAtom(isRunningCiAtom)

  const [messageEditorOpen, setMessageEditorOpen] = useState<boolean>(false)
  const [newMessage, setNewMessage] = useState<string | undefined>()
  const [pulls, setPulls] = useState<PullRequest[]>([])

  const [finishedCreatingBranch, setFinishedCreatingBranch] = useAtom(finishedCreatingBranchState)
  const setIsCreatingPullRequest = useSetAtom(isCreatingPullRequestState)
  const [userMentionedPullRequest_, setUserMentionedPullRequest_] =
    useState<PullRequest | undefined>() // null means default, otherwise it means user has a preference
  const [pullRequest, setPullRequest] = useAtom(pullRequestState)
  const [featureBranch, setFeatureBranch] = useAtom(featureBranchState)
  const [commitToPR, setCommitToPR] = useAtom(commitToPRState) // controls whether or not we commit to the userMetionedPullRequest or create a new pr
  const messagesContainerRef = useRef<HTMLDivElement>(null)
  const authorizedFetch = useAuthorizedFetch()

  const [prValidationStatuses, setPrValidationStatuses] = useAtom(prValidationStatusesState)

  const { accessToken, octokit, user, username } = useSession()

  const [repos, setRepos] = useAtom(reposState)
  const { userBranches } = useBranches()
  const { isWhitelisted, isCheckingWhitelist } = useCheckWhitelist()
  const [messagesId, setMessagesIdAtom] = useAtom(messagesIdState)
  const setMessagesId = useCallback((newMessagesId: string | ((prev: string) => string)) => {
    setMessagesIdAtom((prevMessagesId) => {
      const nextMessagesId = typeof newMessagesId === 'function' 
        ? newMessagesId(prevMessagesId) 
        : newMessagesId;
      
      // Update the ref with the current previous messagesId
      previousMessagesIdRef.current = prevMessagesId;
      
      return nextMessagesId;
    });
  }, [setMessagesIdAtom]);

  const [previousChats, setPreviousChats] = useLocalStorage<ChatSummary[]>(
    'previousChats',
    []
  )
  const markChatAsDeleted = useCallback((messagesId: string) => {
    setPreviousChats((prevChats) =>
      prevChats.map((chat) =>
        chat.messagesId === messagesId ? { ...chat, isDeleted: true } : chat
      )
    )
  }, [setPreviousChats])
  // determines whether we want to for a message or not - should always be when user sends a message or edits a previous message

  // if !latestVersion we are in dev
  const { chatTimer, setIsChatActive } = useChatActiveTimer()

  const messagesExist = useMemo(() => messages.length > 0, [messages.length])

  const userMentionedPullRequests = useMemo(() => {
    const pullRequests = messages
      .filter((message) => (message.annotations?.pulls?.length || 0) > 0)
      .flatMap((message) => message.annotations?.pulls ?? [])
    return pullRequests || []
  }, [messages.length])

  const userMentionedPullRequest = useMemo(() => {
    if (userMentionedPullRequest_) {
      return userMentionedPullRequest_
    }
    return userMentionedPullRequests.length > 0
      ? userMentionedPullRequests[userMentionedPullRequests.length - 1]
      : null
  }, [userMentionedPullRequests, userMentionedPullRequest_])

  const hasUnsavedChanges = useCallback(() => isStreamActive, [isStreamActive]);
  useUnsavedChangesAlert(hasUnsavedChanges);

  useEffect(() => {
    setIsStreamActive(stream.current !== null);
    // throw new Error("Not implemented");
  }, [stream.current === null]);

  useEffect(() => {
    const previousMessagesId = previousMessagesIdRef.current;
    if (
      messagesId &&
      !previousChats.some((chat) => chat.messagesId === messagesId) &&
      messagesExist
    ) {
      let initialMessage = trimStart(messages[0].content, "# ") || "Untitled Sweep Thread"

      // Find existing messages that match the pattern "initialmessage - number"
      const existingChats = previousChats.filter((chat) => {
        const regex = new RegExp(
          `^${escapeRegExp(initialMessage)}(?: - \\d+)?$`
        )
        try {
          return regex.test(chat.initialMessage)
        } catch {
          return false
        }
      })

      // Calculate the next suffix
      let suffix = 0
      if (existingChats.length > 0) {
        const suffixes = existingChats.map((chat) => {
          const match = chat.initialMessage.match(/ - (\d+)$/)
          return match ? parseInt(match[1], 10) : 0
        })
        suffix = Math.max(...suffixes) + 1
      }

      // Append suffix if needed
      if (suffix > 0) {
        initialMessage = `${initialMessage} - ${suffix}`
      }

      // Check if the messages belonged to another user
      currentOwnerOfChatRef.current =
        currentOwnerOfChatRef.current || username || ''
      
      const newChatSummary: ChatSummary = {
        messagesId: messagesId,
        createdAt: new Date().toISOString(),
        initialMessage: initialMessage,
        username: currentOwnerOfChatRef.current,
        isDeleted: false,
      }

      // get the previous chat name and reassign it to this one
      const previousChat = previousChats.find(chat => chat.messagesId === previousMessagesId);
      if (previousChat?.displayName) {
        newChatSummary.displayName = previousChat.displayName;
      }

      setPreviousChats([
        ...previousChats,
        newChatSummary
      ])
      previousMessagesIdRef.current = messagesId;
    }
  }, [
    messagesId,
    messagesExist,
    messages.length,
    previousChats,
    username,
    authorizedFetch,
  ])


  useEffect(() => {
    if (messagesId) {
      window.history.pushState({}, '', `/c/${messagesId}`)
    }
  }, [messagesId])

  useEffect(() => {
    if (accessToken) {
      ;(async () => {
        const maxPages = 5
        let allRepositories: Repository[] = []
        let page = 1
        let response
        do {
          response = await octokit.rest.repos.listForAuthenticatedUser({
            visibility: 'all',
            sort: 'pushed',
            per_page: 100,
            page: page,
          })
          allRepositories = allRepositories.concat(response.data)
          setRepos(allRepositories)
          page++
        } while (response.data.length > 0 && page < maxPages)
      })()
    }
  }, [accessToken])

  useEffect(() => {
    for (const message of messages) {
      if (
        message.annotations?.pulls &&
        message.annotations.pulls.length > 0 &&
        message.annotations.pulls[0].branch
      ) {
        if (message.annotations.pulls[0].status === 'closed') {
          setBranch(message.annotations.pulls[0].branch)
        } else {
          const baseBranch = message.annotations.pulls[0].baseBranch
          if (baseBranch) {
            setBranch(baseBranch)
          }
        }
      }
    }
  }, [messages.length])

  useEffect(() => {
    if (repoName && accessToken) {
      ;(async () => {
        const repoData = await octokit.rest.repos.get({
          owner: repoName.split('/')[0],
          repo: repoName.split('/')[1],
        })
        setBranch((branch) => {
          return branch == '' ? repoData.data.default_branch : branch
        })
      })()
    }
  }, [repoName, accessToken])


  useEffect(() => {
    if (messages.length > 0 && snippets.length > 0) {
      debouncedSave(
        repoName,
        branch,
        featureBranch,
        messages,
        snippets,
        messagesId,
        userMentionedPullRequest,
        userMentionedPullRequests,
        commitToPR,
        suggestedChanges,
        pullRequest,
        pullRequestTitle,
        pullRequestBody,
        forkMessage,
        appliedChanges,
        prValidationStatuses,
        finishedCreatingBranch
      )
    }
  }, [
    repoName,
    branch,
    featureBranch,
    messages,
    snippets,
    userMentionedPullRequest,
    userMentionedPullRequests,
    commitToPR,
    suggestedChanges,
    pullRequest,
    pullRequestTitle,
    pullRequestBody,
    debouncedSave,
    messagesId,
    appliedChanges,
    prValidationStatuses,
    currentOwnerOfChatRef.current,
    finishedCreatingBranch,
  ])

  useEffect(() => {
    updateFavicon(false)

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        updateFavicon(false) // Turn off the notification bubble when the user re-enters the chat
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  const posthog_capture = usePostHogCapture()

  // lastAssistantMessageIndex removed in b69f71fe4c746e3b2c0e50991d0acf0eeafc8a52

  const removeChanges = useCallback(() => {
    return () => {
      setFinishedCreatingBranch(false)
      setSuggestedChanges([])
      setAppliedChanges([])
      const currentFeatureBranch =
        messages.findLast((m) => m.annotations?.featureBranch)?.annotations
          ?.featureBranch ||
        featureBranch ||
        branch
      setFeatureBranch(currentFeatureBranch)
    }
  }, [messages.length, featureBranch, branch, setFinishedCreatingBranch, setSuggestedChanges, setAppliedChanges, setFeatureBranch])

  const searchAgent = useCallback(
    async (
      message: string,
      newMessages: Message[],
      snippets: Snippet[],
      annotations: Message['annotations'],
      branch: string
    ) => {
      if (!useFollowUpSearchAgent && newMessages.length > 1) {
        return { currentMessages: newMessages, currentSnippets: snippets }
      }

      setIsLoading(true)
      setSearchMessage('Cloning repository...')
      resetShowSurveyTimeout()
      let currentSnippets = snippets
      let currentMessages = newMessages

      await withLoading(
        setIsLoading,
        async () => {
          resetShowSurveyTimeout()
          let streamedMessage: string = ''
          let streamedMessages: Message[] = []

          askNotificationPermission()
          const snippetsResponse = await (useSearchAgent
            ? authorizedFetch(`/search_agent`, {
                repo_name: repoName,
                query: message,
                messages: newMessages,
                annotations,
                existing_snippets: snippets,
                branch: branch,
                model: model,
                use_patch: true,
              })
            : authorizedFetch(`/search`, {
                repo_name: repoName,
                query: message,
                annotations,
                branch: branch,
              }))

          let prevSnippets = snippets
          for await (const patch of streamResponseMessages(
            snippetsResponse,
            stream
          )) {
            resetShowSurveyTimeout()
            if (JSON.stringify(patch) == '[]') {
              continue
            }
            if (patch && patch[0] && patch[0].status == 'error') {
              throw new Error(patch[0].error)
            }
            try {
              if (useSearchAgent) {
                ;[streamedMessage, currentSnippets, streamedMessages] =
                  jsonpatch.applyPatch(
                    [
                      streamedMessage,
                      currentSnippets,
                      streamedMessages,
                    ] as AgenticStreamState,
                    patch
                  ).newDocument
              } else {
                ;[streamedMessage, currentSnippets, streamedMessages] = patch
              }
            } catch (error: any) {
              // Log the error using posthog_capture
              posthog_capture('applyPatch_error', {
                error: error.message,
                useSearchAgent,
                patch: JSON.stringify(patch),
                streamedMessage,
                currentSnippets,
                streamedMessages,
              })
            }
            if (currentSnippets) {
              currentSnippets = [...currentSnippets]
            }
            if (
              JSON.stringify(prevSnippets) !== JSON.stringify(currentSnippets)
            ) {
              setSnippets([...currentSnippets])
              prevSnippets = currentSnippets
            }
            setSearchMessage(streamedMessage)
            if (useSearchAgent && streamedMessages) {
              currentMessages = [
                ...newMessages,
                ...streamedMessages.map((message) => {
                  const copiedMessage = { ...message }
                  if (copiedMessage.function_call) {
                    return {
                      ...copiedMessage,
                      function_call: {
                        ...copiedMessage.function_call,
                        is_complete: true
                      }
                    } as Message;
                  }
                  return copiedMessage as Message
                }),
              ]
              setMessages(currentMessages)
            }
          }
          setSearchMessage('')
          if (currentSnippets.length === 0) {
            throw new Error('No snippets found')
          }
        },
        (error: Error) => {
          if (error.message === 'Stream interrupted by user') {
            throw error
          }
          setSearchMessage('')
          toast({
            title: 'Failed to search codebase',
            description: `${getUserFacingErrorMessage(error.message)}`,
            variant: 'destructive',
            duration: Infinity,
          })
          posthog_capture('chat errored', {
            error: error.message,
            stack: error.stack,
            url: window.location.href,
            endpoint: 'searchAgent',
          })
          if (error.message === 'Stream interrupted by user') {
            throw error
          }
        }
      )

      currentMessages = currentMessages.map((message) => {
        const copiedMessage = { ...message }
        if (copiedMessage.function_call) {
          return {
            ...copiedMessage,
            function_call: {
              ...copiedMessage.function_call,
              is_complete: true
            }
          } as Message;
        }
        return copiedMessage as Message
      })

      return { currentMessages, currentSnippets }
    },
    [
      repoName,
      snippets,
      useSearchAgent,
      authorizedFetch,
      posthog_capture,
      branch,
      useSearchAgent,
      useFollowUpSearchAgent,
    ]
  )

  const chatWithAgent = useCallback(
    async (
      currentMessages: Message[],
      currentSnippets: Snippet[],
      branch: string,
      currentAppliedChanges: StatefulCodeSuggestion[]
    ) => {
      let streamedMessages: Message[] = []
      setMessages([
        ...currentMessages,
        { content: 'Loading...', role: 'assistant' } as Message,
      ])
      console.log(
        'chatting with context:',
        currentMessages,
        currentSnippets,
        branch,
        userMentionedPullRequest
      )
      await withLoading(
        setIsLoading,
        async () => {
          resetShowSurveyTimeout()
          const chatResponse = await authorizedFetch('/chat', {
            messages: currentMessages,
            snippets: currentSnippets,
            model,
            branch: branch,
            modify_files_dict: currentAppliedChanges.reduce((acc, change) => {
              acc[change.filePath] = {
                original_contents: change.originalCode,
                contents: change.newCode
              }
              return acc
            }, {} as { [key: string]: {original_contents: string, contents: string} }),
          })
          let messageLength = currentMessages.length
          let appliedChanges: StatefulCodeSuggestion[] = cloneDeep(currentAppliedChanges)
          let prevSuggestedChanges: StatefulCodeSuggestion[] = []
          let prevMessages: Message[] = []
          let patchRetryCount = 0
          const PATCH_MAX_RETRIES = 3
          for await (const patches of streamResponseMessages(
            chatResponse,
            stream
          )) {
            if (JSON.stringify(patches) == '[]') {
              continue
            }
            resetShowSurveyTimeout()
            for (const patch of patches) {
              if (patch.status == 'error') {
                throw new Error(patch.error)
              }
            }
            let patchApplied = false
            while (!patchApplied && patchRetryCount < PATCH_MAX_RETRIES) {
              try {
                streamedMessages = jsonpatch.applyPatch(
                  streamedMessages,
                  patches
                ).newDocument
                patchApplied = true
                patchRetryCount = 0 // Reset retry count on success
              } catch (e: any) {
                console.warn(
                  `Error applying patch (attempt ${patchRetryCount + 1}):`,
                  e
                )
                console.log('Problematic patches:', patches)
                posthog_capture('chat patch error', {
                  user: username,
                  attempt: patchRetryCount + 1,
                  error: e.message,
                  stack: e.stack,
                  patches: JSON.stringify(patches),
                  streamedMessagesState: JSON.stringify(streamedMessages),
                  url: window.location.href,
                })
                patchRetryCount++
                if (patchRetryCount >= PATCH_MAX_RETRIES) {
                  console.error(
                    'Max retries reached. Skipping problematic patch.'
                  )
                  patchApplied = true // Force exit the retry loop
                }
                await new Promise((resolve) => setTimeout(resolve, 1000)) // Wait for 1 second before retrying
              }
            }
            appliedChanges = streamedMessages
              .flatMap((message) => {
                if (
                  message.role == 'assistant' &&
                  message.annotations?.codeSuggestions
                ) {
                  console.log(
                    'suggestions',
                    message.annotations?.codeSuggestions
                  )
                  return message.annotations?.codeSuggestions
                }
                return []
              })
            const currentStreamedMessages = streamedMessages.map((message) => {
              const copiedMessage = cloneDeep(message)
              if (copiedMessage.annotations?.codeSuggestions) {
                copiedMessage.annotations.codeSuggestions = undefined
              }
              return copiedMessage
            })
            if (
              JSON.stringify(prevMessages) !==
              JSON.stringify(currentStreamedMessages)
            ) {
              setMessages([...currentMessages, ...currentStreamedMessages])
              prevMessages = currentStreamedMessages
            }
            if (
              JSON.stringify(prevSuggestedChanges) !==
                JSON.stringify(appliedChanges) &&
              appliedChanges.some(
                (change) =>
                  (change.originalCode || '').trim().length > 0 ||
                  (change.newCode || '').trim().length > 0
              )
            ) {
              if (appliedChanges.some(change => !change.originalCode.includes('TODO') && change.newCode && change.newCode.includes('TODO'))) {
                // check if currentMessageState is empty, if so fill it in with a message
                const isCurrentMessageEmpty = currentMessage.length === 0
                const todoDescription = 'Sweep has added a TODO in the code changes. You can ask Sweep to "Implement the TODOs you mentioned" to address these.' + (isCurrentMessageEmpty ? ' We\'ve filled this in for you (press command/ctrl + enter to send).' : '')
                toast({
                  title: 'TODOs Detected In New Code',
                  description: todoDescription,
                  variant: 'information',
                  duration: Infinity,
                })
                if (isCurrentMessageEmpty) {
                  setCurrentMessage('Implement the TODOs you mentioned.')
                }
              }
              setAppliedChanges(appliedChanges)
              prevSuggestedChanges = appliedChanges
            }
            if (streamedMessages.length > messageLength) {
              messageLength = streamedMessages.length
            }
          }
          if (appliedChanges.length > 0) {
            setTimeout(
              () =>
                changesScrollAreaRef.current?.scrollTo({
                  top: changesScrollAreaRef.current?.scrollHeight,
                  behavior: 'smooth',
                }),
              100
            )
            if (appliedChanges.some((change) => change.state != 'done')) {
              toast({
                title: 'Some changes failed to apply.',
                description:
                  'You can still create the branch, but there may be unintended changes. You may need to apply the changes manually.',
                variant: 'destructive',
                duration: Infinity,
              });
            }
            // Run PR metadata generation in the background using a non-awaited async call
            setPullRequest(undefined)
            setPullRequestTitle(undefined)
            setPullRequestBody(undefined)
            setCommitMessage(undefined);
            setIsRunningCi(true);
            (async () => {
              try {
                const prMetadata = await authorizedFetch('/create_pull_metadata', {
                  repo_name: repoName,
                  modify_files_dict: appliedChanges.reduce((acc, change) => {
                    acc[change.filePath] = {
                      original_contents: change.originalCode,
                      contents: change.newCode
                    }
                    return acc
                  }, {} as { [key: string]: {original_contents: string, contents: string} }),
                  messages,
                })
                const prData = await prMetadata.json()
                const { title, description, commit_message, branch: featureBranch } = prData
                setPullRequestTitle(title || 'Sweep Chat Suggested Changes')
                setPullRequestBody(description || 'Suggested changes by Sweep Chat.')
                setCommitMessage(commit_message || 'Suggested changes by Sweep Chat.')
                setFeatureBranch(featureBranch)
              } catch (error) {
                console.error('Error generating PR metadata:', error)
                toast({
                  title: 'Failed to generate PR metadata',
                  description: 'An error occurred while generating the PR title/description and commit message.',
                  variant: 'destructive',
                  duration: 5000,
                })
              }
            })()
          }
        },
        (error: Error) => {
          // Implement the new error handling logic
          setMessages((prevMessages) => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            const secondLastMessage = prevMessages[prevMessages.length - 2];

            if (lastMessage.role === 'user' || (lastMessage.role === 'function' && lastMessage.function_call?.function_name === 'analysis' && secondLastMessage?.role === 'user')) {
              // Remove the last message (and analysis block if it exists)
              const newMessages = prevMessages.slice(0, -1);
              if (lastMessage.role === 'function' && lastMessage.function_call?.function_name === 'analysis') {
                newMessages.pop(); // Remove the analysis block as well
              }

              // Set the current message to the content of the last user message
              const lastUserMessage = prevMessages.findLast((message) => message.role === 'user');
              if (lastUserMessage) {
                setCurrentMessage(lastUserMessage.content);
              }

              return newMessages;
            }
            return prevMessages;
          });

          if (error.message === 'Stream interrupted by user') {
            throw error
          }
          console.error(error)
          toast({
            title: 'Chat stream failed',
            description: error.message,
            variant: 'destructive',
            duration: Infinity,
            action: (
              <ToastAction altText="Retry" onClick={() => {
                const lastUserMessageIndex = currentMessages.findLastIndex((m) => m.role === 'user');
                const newCurrentMessages = currentMessages.slice(0, lastUserMessageIndex + 1)
                withLoading(setIsLoading, () => chatWithAgent(newCurrentMessages, currentSnippets, branch, currentAppliedChanges))
              }}>
                <FaUndo className="mr-2"/>Retry
              </ToastAction>
            )
          })
          setIsLoading(false)
          posthog_capture('chat errored', {
            error: error.message,
            stack: error.stack,
            url: window.location.href,
            endpoint: 'chatWithAgent',
          })

          throw error
        }
      )

      setIsLoading(false)
      resetShowSurveyTimeout()
      const lastMessage = streamedMessages[streamedMessages.length - 1]
      if (document.hidden) {
        new Notification(lastMessage.content.split('\n')[0], {
          body: lastMessage.content.split('\n').slice(1).join('\n'),
          icon: 'https://avatars.githubusercontent.com/ml/15116',
        })
        updateFavicon(true)
      }
      posthog_capture('chat succeeded')
    },
    [
      repoName,
      model,
      authorizedFetch,
      posthog_capture,
      branch,
      userMentionedPullRequest,
      suggestedChanges,
      appliedChanges,
      setCurrentMessage
    ]
  )

  const startChatStream = useCallback(
    async (
      message: string,
      newMessages: Message[],
      snippets: Snippet[],
      annotations: Message['annotations'],
      branch: string
    ) => {
      withLoading(setIsChatActive, async () => {
        const { currentMessages, currentSnippets } = await searchAgent(
          message,
          newMessages,
          snippets,
          annotations,
          branch
        )
        await chatWithAgent(currentMessages, currentSnippets, branch, appliedChanges)
      })
    },
    [searchAgent, chatWithAgent]
  )

  const rewriteQuery = useCallback(async (
    currentMessage: string
  ) => {
    setNewMessage('')
    setSearchMessage('Sweep is rewriting your message...')

    // PULLS START
    const pulls = await parsePullRequests(repoName, currentMessage, octokit!)
    let currentBranch = branch
    if (pulls.length > 0) {
      currentBranch = pulls[pulls.length - 1].branch
      setBranch(currentBranch)
    }
    setPulls(pulls)
    // PULLS END

    const rewrittenQuery = await authorizedFetch('/rewrite_query', {
      query: currentMessage,
      branch: currentBranch,
      pulls: pulls,
    })
    let searchMessage = ''
    let newMessage = ''
    let currentSnippets: Snippet[] = []
    for await (const patches of streamResponseMessages(rewrittenQuery, stream)) {
      console.log("patches", patches)
      if (JSON.stringify(patches) == '[]') {
        continue
      }
      resetShowSurveyTimeout()
      for (const patch of patches) {
        if (patch.status == 'error') {
          throw new Error(patch.error)
        }
      }
      [searchMessage, newMessage, currentSnippets] = jsonpatch.applyPatch(
        [
          searchMessage,
          newMessage,
          currentSnippets,
        ] as any,
        patches
      ).newDocument
      setNewMessage(newMessage)
      setSearchMessage(searchMessage)
      setSnippets(currentSnippets)
    }
  }, [repoName, accessToken, currentMessage, branch])

  const sendMessage = useCallback(async () => {
    const message = newMessage || currentMessage
    setNewMessage('') // reset newMessage
    localStorage.removeItem('persistedMessage')

    if (message.length > 10_000) {
      toast({
        title: 'Message Too Long',
        description: 'Your message is very long and can cause client-side issues. Consider mentioning the file and changing the branch or linking a PR to include additional context instead.',
        variant: 'warning',
        duration: 4000,
      })
    }

    stream.current?.cancel()
    stream.current = null

    if (!messageEditorOpen && messages.length === 0 && useRewrite) {
      setNewMessage(currentMessage)
      setMessageEditorOpen(true)
      rewriteQuery(currentMessage)
      return
    }

    currentOwnerOfChatRef.current = username || ''
    setForkMessage(true)
    resetShowSurveyTimeout() // reset inactivity timer for showing the survey
    posthog_capture('chat submitted', {
      useSearchAgent,
      snippets,
      message,
    })

    // PULLS START
    const pulls = await parsePullRequests(repoName, message, octokit!)
    let currentBranch = branch
    if (pulls.length > 0) {
      setCommitToPR(true)
      const lastPull = pulls[pulls.length - 1]
      currentBranch = (lastPull.status === 'closed' || lastPull.status === 'merged') ? lastPull.baseBranch : lastPull.branch
      setBranch(currentBranch)
    }
    // PULLS END

    // USE NEW BASE BRANCH
    let currentFeatureBranch =
      messages.findLast((m) => m.annotations?.featureBranch)?.annotations
        ?.featureBranch || featureBranch

    if (finishedCreatingBranch && currentFeatureBranch) {
      currentBranch = currentFeatureBranch
      setBranch(currentBranch)
      setFeatureBranch(getNewBranchName())
    }

    setFinishedCreatingBranch(false)
    // END NEW BASE BRANCH

    // FILES START
    const files = await parseFiles(
      repoName,
      message,
      octokit!,
      currentBranch
    )
    // FILES END

    let newMessages: Message[] = [
      ...messages,
      { content: message, role: 'user', annotations: { pulls, files } },
    ]
    console.log("setting messages2", messages)
    setMessages(newMessages)
    setCurrentMessage('')
    startChatStream(
      message,
      newMessages,
      snippets,
      { pulls },
      currentBranch
    )
  }, [
    messageEditorOpen,
    messages,
    useSearchAgent,
    snippets,
    newMessage,
    currentMessage,
    repoName,
    octokit,
    branch,
    posthog_capture,
    startChatStream,
    userMentionedPullRequests,
  ])

  // validatePR function removed in b69f71fe4c746e3b2c0e50991d0acf0eeafc8a52

  const fixPrValidationErrors = useCallback(
    async (prValidationStatuses: PrValidationStatus[]) => {
      const failedPrValidationStatuses = prValidationStatuses?.find(
        (status) => status.status === 'failure' && status.stdout.length > 0
      )
      // sometimes theres no stdout for some reason, will look into this
      if (!failedPrValidationStatuses) {
        toast({
          title: 'No failed PR checks.',
          description: 'Please try again later.',
          variant: 'destructive',
          className: 'whitespace-break-spaces',
        })
        return
      }
      const content = `Help me fix the following CI/CD pipeline errors:\n\`\`\`\n${failedPrValidationStatuses?.stdout}\n\`\`\``

      setMessages((currentMessages) => {
        const newMessages: Message[] = [
          ...currentMessages,
          {
            role: 'user',
            content: content,
          },
        ]
        startChatStream(content, newMessages, snippets, { pulls: [] }, branch)
        return newMessages
      })
    },
    [branch, snippets, startChatStream]
  )

  const reset = useCallback(async () => {
    // This function's a bit messed up. For whatever reason, it would reset and then load the previous chat back
    setMessagesId('')
    if (isStreamActive) {
      stream.current?.cancel()
      stream.current = null
    }
    
    setIsCreatingPullRequest(false)
    setAppliedChanges([])
    setPrValidationStatuses([])
    setUseSearchAgent(true)
    setMessages([])
    if (messages.length > 0) {
      setCurrentMessage('')
    }
    setIsLoading(false)
    setSnippets([])
    setSearchMessage('')
    setSuggestedChanges([])
    setFinishedCreatingBranch(false)
    setPullRequest(undefined)
    setFeatureBranch(undefined)
    setPullRequestTitle(undefined)
    setPullRequestBody(undefined)
    setCommitToPR(false)
    window.history.pushState({}, '', '/')
  }, [repoName, messages.length])

  const checkBranchExists = useCallback(
    async (branchName: string) => {
      if (branchName === '') {
        return true
      }
      if (!octokit || !repoName) return false
      try {
        const [owner, repo] = repoName.split('/')
        let result = await octokit.rest.repos.getBranch({
          owner,
          repo,
          branch: branchName,
        })
        if (result.data.name !== branchName) {
          return false
        }
        return true
      } catch {
        return false
      }
    },
    [octokit, repoName]
  )

  const onBlur = useCallback(
    async (repoName: string) => {
      const cleanedRepoName = repoName.replaceAll(/\s/g, '') // might be unsafe but we'll handle it once we get there
      setRepoName(cleanedRepoName)
      const didChange = cleanedRepoName !== previousRepoNameRef.current
      previousRepoNameRef.current = cleanedRepoName
      if (cleanedRepoName === '') {
        setRepoNameValid(false)
        return
      }
      if (!cleanedRepoName.includes('/')) {
        setRepoNameValid(false)
        toast({
          title: 'Invalid repository name',
          description:
            "Please enter a valid repository name in the format 'owner/repo'",
          variant: 'destructive',
          duration: Infinity,
          className: 'whitespace-break-spaces',
        })
        return
      }
      let data
      await withLoading(
        setRepoNameDisabled,
        async () => {
          const response = await authorizedFetch(
            `/repo?repo_name=${cleanedRepoName}`,
            {},
            {
              method: 'GET',
            }
          )
          data = await response.json()
          if (!data.success) {
            setRepoNameValid(false)
            toast({
              title: 'Failed to load repository',
              description: data.error,
              variant: 'destructive',
              duration: Infinity,
              action: import.meta.env.NEXT_PUBLIC_INSTALL_URL && (
                <ToastAction altText="Install" onClick={() => {
                  window.location.href = import.meta.env.NEXT_PUBLIC_INSTALL_URL
                }}>
                  Install
                </ToastAction>
              ),
            })
          } else {
            setRepoNameValid(true)
            toast({
              className: 'loaded-repo-success-toast',
              title: 'Successfully loaded repository',
              variant: 'default',
            })
          }
          let currentOctokit = octokit
          try {
            const repo = await currentOctokit.rest.repos.get({
              owner: cleanedRepoName.split('/')[0],
              repo: cleanedRepoName.split('/')[1],
            })
            setBranch(repo.data.default_branch)
          } catch {
            toast({
              title: `Failed to fetch repository details`,
              variant: 'destructive',
            })
          }
          if (didChange) {
            reset()
          }
        },
        (error) => {
          if (error.message === 'Stream interrupted by user') {
            return
          }
          setRepoNameValid(false)
          toast({
            title: 'Failed to load repository',
            description: error.message,
            variant: 'destructive',
            duration: Infinity,
            action: import.meta.env.NEXT_PUBLIC_INSTALL_URL && (
              <ToastAction altText="Install" onClick={() => {
                window.location.href = import.meta.env.NEXT_PUBLIC_INSTALL_URL
              }}>
                Install
              </ToastAction>
            ),
          })
        }
      )
    },
    [setRepoName, repoName, authorizedFetch]
  )

  const navbar = useMemo(
    () =>
      accessToken && (
        <Navbar
          previousChats={previousChats}
          useSearchAgent={useSearchAgent}
          setUseSearchAgent={setUseSearchAgent}
          model={model}
          setModel={setModel}
          repoNameValid={repoNameValid}
          onBlur={onBlur}
          checkBranchExists={checkBranchExists}
          markChatAsDeleted={markChatAsDeleted}
          setPreviousChats={setPreviousChats}
          repoNameSelectorRef={repoNameSelectorRef}
          branchSelectorRef={branchSelectorRef}
        />
      ),
    [
      previousChats,
      messagesId,
      useSearchAgent,
      model,
      isLoading,
      messagesExist,
      repoNameDisabled,
      repoNameValid,
      repos,
      onBlur,
      markChatAsDeleted
    ]
  )

  const showContextSidebar = snippets.length > 0 && messages.length > 0

  const contextSideBar = useMemo(() => {
    const totalTokens =
      sum(
        snippets.map((snippet) => {
          const lines = snippet.content.split('\n')
          const relevantLines = lines.slice(snippet.start - 1, snippet.end)
          const relevantContent = relevantLines.join('\n')
          return relevantContent.length / 4
        })
      ) + sum(messages.map((message) => countTokensForMessage(message)))
    return (
      <ContextSideBar
        snippets={snippets}
        setSnippets={setSnippets}
        authorizedFetch={authorizedFetch}
        stream={stream}
        totalTokens={totalTokens}
      />
    )
  }, [snippets, searchMessage, appliedChanges, messages.length, pullRequest, pullRequestTitle, pullRequestBody, featureBranch])

  const [chatItems, backIndices] = useMemo(() => {
    let chatItems: ChatItem[] = []
    let backIndices: number[] = []
    let currentGroup: ToolCallGroup[] = []
    let updateUserMessages: Message[] = []
  
    for (let index = 0; index < messages.length; index++) {
      const message = messages[index]
      if (
        chatItems.length === 0 ||
        message.role !== 'function' ||
        message.function_call?.function_name === 'analysis'
      ) {
        if (currentGroup.length > 0) {
          chatItems.push(currentGroup)
          backIndices.push(chatItems.length - 1)
          chatItems.push(...updateUserMessages)
          backIndices.push(...Array.from({ length: updateUserMessages.length }, (_, i) => chatItems.length - 1 - i));
          currentGroup = []
          updateUserMessages = []
        }
        chatItems.push(message)
        backIndices.push(index)
      } else if (
        message.role === 'function' &&
        message.function_call?.function_name === 'deciding'
      ) {
        currentGroup.push({
          messages: [],
          thinking: message.function_call?.thinking || '',
        })
      } else if (currentGroup.length > 0) {
        currentGroup[currentGroup.length - 1].messages.push(message)
        if (message.function_call?.function_name === 'update_user') {
          updateUserMessages.push(message)
        }
      } else {
        chatItems.push(message)
        backIndices.push(index)
      }
    }
    if (currentGroup.length > 0) {
      chatItems.push(currentGroup)
      backIndices.push(messages.length - 1)
    }
    return [chatItems, backIndices]
  }, [messages]);

  const shownMessages = chatItems

  return (
    <>
      <main className="flex h-screen flex-col items-center justify-between p-12 pt-20">
        <PostHogContext.Provider value={{ posthog_capture }}>
          <ReportBug />
          {navbar}
          <Toaster />
          {showSurvey && import.meta.env.NEXT_PUBLIC_SURVEY_ID && (
            <Survey
              onClose={(didSubmit) => {
                setShowSurvey(false)
                if (didSubmit) {
                  toast({
                    title: 'Thanks for your feedback!',
                    description: (
                      <span>
                        We&apos;ll reach back out shortly. If have any other
                        feedback, feel free to have a chat with us at{' '}
                        <a
                          className="text-blue-500 hover:text-blue-400 underline"
                          href="https://calendly.com/sweep-ai/user-interview"
                        >
                          https://calendly.com/sweep-ai/user-interview
                        </a>
                        .
                      </span>
                    ),
                  })
                }
              }}
            />
          )}
          {isCheckingWhitelist ? (
            renderWhiteListLoading()
          ) : isWhitelisted ? (
            repoNameValid || messagesId ? (
              <ResizablePanelGroup className="mt-2" direction="horizontal">
                <ResizablePanel
                  defaultSize={65}
                  className="flex flex-col items-center mx-auto"
                >
                  <ChatMessagesDisplay
                    posthog_capture={posthog_capture}
                    messagesContainerRef={messagesContainerRef}
                    shownMessages={shownMessages}
                    snippets={snippets}
                    currentOwnerOfChatRef={currentOwnerOfChatRef}
                    stream={stream}
                    userMentionedPullRequests={userMentionedPullRequests}
                    backIndices={backIndices}
                    setSnippets={setSnippets}
                    removeChanges={removeChanges}
                    startChatStream={startChatStream}
                    fixPrValidationErrors={fixPrValidationErrors}
                    chatWithAgent={chatWithAgent}
                    userMentionedPullRequest={userMentionedPullRequest}
                    setUserMentionedPullRequest_={setUserMentionedPullRequest_}
                    authorizedFetch={authorizedFetch}
                    octokit={octokit}
                  />
                  <div className="w-full my-auto flex flex-col justify-around items-center">
                    {messages.length === 0 && messagesId === '' && (
                      <div className="mb-4 mt-4">
                        <h2 className="text-4xl text-white mb-2 transition-opacity duration-500 ease-in-out w-[800px] text-center">
                          You&apos;re working in
                          <br/>
                        </h2>
                        <div className="flex flex-row items-center justify-center">
                          <code className='mb-4 text-center text-4xl max-w-[800px] break-words'>
                            <span 
                              className="cursor-pointer text-blue-400 hover:text-blue-300 transition-colors"
                              onClick={() => repoNameSelectorRef.current?.focus()}
                            >
                              {repoName || 'your codebase'}
                            </span>:
                            <span 
                              className="text-blue-400 cursor-pointer hover:text-blue-300 transition-colors"
                              onClick={() => branchSelectorRef.current?.focus()}
                            >
                              {branch}
                            </span>
                          </code>
                        </div>
                      </div>
                    )}
                    {messages.length === 0 && messagesId === '' && (
                      <ExampleTasks />
                    )}
                    <ChatControls
                      sendMessage={sendMessage}
                      useFollowUpSearchAgent={useFollowUpSearchAgent}
                      setUseFollowUpSearchAgent={setUseFollowUpSearchAgent}
                      currentOwnerOfChatRef={currentOwnerOfChatRef}
                      stream={stream}
                    />
                  </div>
                </ResizablePanel>
                {showContextSidebar && (
                  <ResizableHandle className="ml-4 bg-transparent" withHandle />
                )}
                <ResizablePanel
                  defaultSize={35}
                  className="h-full"
                  hidden={!showContextSidebar}
                >
                  {contextSideBar}
                </ResizablePanel>
              </ResizablePanelGroup>
            ) : (
              <div className="w-full flex items-center justify-center grow flex-col" hidden={repoNameValid}>
                <div className="flex flex-col gap-16 max-w-4xl border-l-4 border-zinc-800 pl-8 py-4">
                  <div className={`flex flex-col gap-4 text-left`}>
                    <h2 className="text-4xl font-bold mb-12 text-left">
                      Welcome to Sweep, {getFirstName(user.name || '')}!
                    </h2>
                    <h2 className="text-2xl font-bold text-left">
                      1. Install Sweep on your organization
                    </h2>
                    <p className="text-gray-400 px-1 mb-2">
                      This gives Sweep access to your repositories and updates Sweep when you open issues and pull requests.
                    </p>

                    <Button className="w-48 text-left bg-zinc-800 hover:bg-zinc-700 text-white" asChild>
                      <a href={import.meta.env.NEXT_PUBLIC_INSTALL_URL}>
                        Install Sweep
                      </a>
                    </Button>
                  </div>
                  <div className={`flex flex-col gap-4 text-left`}>
                    <h2 className="text-2xl font-bold text-left">
                      2. Select a repository
                    </h2>
                    <p className="text-gray-400 mb-2">
                      Make sure Sweep is installed on the owner of the repository.
                    </p>
                    <div id="repository-selector-middle" className="flex items-center">
                      <AutoComplete
                        options={repos.map((repo) => ({
                          label: repo.full_name,
                          value: repo.full_name,
                        }))}
                        placeholder="Repository name"
                        emptyMessage="Type in your repository name (repo_owner/repo_name)."
                        value={{ label: repoName, value: repoName }}
                        onValueChange={(option) => setRepoName(option.value)}
                        disabled={repoNameDisabled}
                        onBlur={onBlur}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="w-full flex items-center justify-around grow">
              <div className="flex flex-row items-center gap-4 mx-auto space-x-8" hidden={repoNameValid}>
                <img src={SweepingGif} className="w-64 h-64 rounded-full object-cover shadow-sm"/>
                <div className="flex flex-col gap-4 max-w-xl">
                  <h2 className="text-2xl font-bold mb-2">
                    You&apos;re almost there!
                  </h2>
                  <p>
                    Welcome to the last step before being able to use Sweep! Sweep can help you:
                  </p>
                  <ul className="list-disc list-inside ml-2">
                    <li>Explain complex code in your codebase.</li>
                    <li>Write tests for pull requests.</li>
                    <li>Fix well-specified bugs.</li>
                  </ul>
                  <p>
                    However, to ensure you have the best experience possible, please book an onboarding call workshop meeting with {import.meta.env.NEXT_PUBLIC_ACCESS_PENDING_COMPANY_DESCRIPTION}, via:
                  </p>
                  <ul className="list-disc list-inside ml-2">
                    <li>Email <a href={`mailto:${import.meta.env.NEXT_PUBLIC_SWEEP_SUPPORT_EMAIL}`} className="text-blue-400 hover:text-blue-300 transition-colors">{import.meta.env.NEXT_PUBLIC_SWEEP_SUPPORT_EMAIL}</a>.</li>
                    <li>Book directly via <a href="https://calendly.com/william-sweep/quick-chat" className="text-blue-400 hover:text-blue-300 transition-colors">our Calendly</a>.</li>
                  </ul>
                </div>
              </div>
            </div>
          )}
          <MessageEditor
            newMessage={newMessage || ''}
            setNewMessage={setNewMessage}
            searchMessage={searchMessage}
            snippets={snippets}
            setSnippets={setSnippets}
            messageEditorOpen={messageEditorOpen}
            setMessageEditorOpen={setMessageEditorOpen}
            currentMessage={currentMessage}
            sendMessage={sendMessage}
            pulls={pulls}
          />
          <div className="fixed bottom-2 left-2 p-2 flex">
            {import.meta.env.DEV ? (
              <div className="text-xs rounded-md text-gray-500">
                {chatTimer > 0 && formatTime(chatTimer)}
              </div>
            ) : (
              <VersionChecker />
            )}
          </div>
        </PostHogContext.Provider>
      </main>
    </>
  )
}

const WrappedApp = AppWrapper(App);

export default WrappedApp;
