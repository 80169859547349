import { FaBug } from 'react-icons/fa'
import { Button } from '../ui/button'
import { memo, useState } from 'react'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../ui/card'
import { toast } from '../ui/use-toast'
import { posthog } from '@/lib/posthog'
import { repoNameState } from '@/state/atoms'
import { useAtomValue } from 'jotai'

export const ReportBug = memo(() => {
  const [isOpen, setIsOpen] = useState(false)
  const [feedback, setFeedback] = useState('')
  const surveyID = import.meta.env.NEXT_PUBLIC_SURVEY_ID
  const repoName = useAtomValue(repoNameState)

  const handleFeedbackSubmit = () => {
    setIsOpen(false)
    posthog.capture('survey sent', {
      $survey_id: surveyID,
      $survey_response: feedback,
    })
    toast({
      title: 'Bug Report Submitted',
      description:
        "Thank you for your feedback! We'll shoot you a message if we have any other questions and let you know when it's fixed!",
    })
  }

  return (
    <div className="fixed bottom-0 right-0 p-4 z-10">
      {!isOpen ? (
        <Button
          variant="outline"
          onClick={() => setIsOpen(true)}
          className="shadow-sm shadow-zinc-800"
        >
          <FaBug className="mr-2" />
          Report Bug
        </Button>
      ) : (
        <Card className="w-[500px] z-10 shadow-lg shadow-zinc-900">
          <CardHeader>
            <CardTitle>Report Bug</CardTitle>
          </CardHeader>
          <CardContent>
            Email us at <Button variant="link" className="text-md p-0 text-blue-400 hover:text-blue-300 transition-colors" asChild><a href={`mailto:support@sweep.dev?subject=Sweep Chat Bug Report&body=On ${window.location.href}, in the \`${repoName}\` repository, I am running into a bug where ...`}>support@sweep.dev</a></Button> to report a bug.
          </CardContent>
          <CardFooter className="flex justify-end space-x-2">
            <Button variant="outline" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              className="bg-blue-900 text-white hover:bg-blue-800"
              onClick={handleFeedbackSubmit}
            >
              Submit
            </Button>
          </CardFooter>
        </Card>
      )}
    </div>
  )
});
ReportBug.displayName = 'ReportBug';
