import React, { FC } from 'react'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card'
import SyntaxHighlighter from '@/lib/syntaxHighlighter'
import { FaExclamationTriangle } from 'react-icons/fa'
import CopyButton from '../CopyButton'
import { streamActiveState } from '@/state/atoms'
import { useAtom } from 'jotai'

interface CodeBlockProps {
  match: RegExpExecArray | undefined
  children: React.ReactNode
  rest: { [key: string]: any }
  metaData?: any
}

const CodeBlock: FC<CodeBlockProps> = ({ match, children, rest, metaData }) => {
  const [isStreamActive] = useAtom(streamActiveState);
  const snippetCodeMatches: { [key: string]: any } =
    isStreamActive ? {} : (metaData?.snippetCodeMatches || {})
  // Extract the code as a string from children
  const code = String(children)
  // Find the best match from the snippetCodeMatches dictionary
  let bestMatch: { [key: string]: any } | undefined
  for (const [key, value] of Object.entries(snippetCodeMatches)) {
    let strippedKey = key.replaceAll(/\s/g, '')
    let strippedCode = code.replaceAll(/\s/g, '')
    if (strippedKey == strippedCode) {
      bestMatch = value
      break
    }
  }

  const bestFile = bestMatch ? Object.keys(bestMatch)[0] || '' : ''
  const firstMatch = bestFile ? bestMatch![bestFile][0] : undefined
  const score = bestFile ? firstMatch.score : 0

  const highlightedCode = (
    <SyntaxHighlighter
      {...rest}
      language={match ? match[1] : undefined}
      customStyle={{
        marginTop: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
      showLineNumbers={score > 90}
      startingLineNumber={score > 90 ? firstMatch.start + 3 : 0}
    >
      {String(children).replace(/\n$/, '')}
    </SyntaxHighlighter>
  )

  if (!metaData || isStreamActive) {
    return (

      <div className="flex justify-between relative">
        <CopyButton value={String(children)} className="absolute right-0 mt-2 mr-2" />
        <SyntaxHighlighter
          {...rest}
          language={match ? match[1] : undefined}
          customStyle={{
            marginTop: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
          showLineNumbers={score > 90}
          startingLineNumber={score > 90 ? firstMatch.start + 3 : 0}
          className="!rounded w-full"
        >
          {String(children).replace(/\n$/, '')}
          </SyntaxHighlighter>
      </div>
    )
  }

  return (
    <span>
      <HoverCard openDelay={1000} closeDelay={100}>
        <HoverCardTrigger style={{ textDecoration: 'none' }}>
          <div
            className={`font-mono mt-3 p-4 bg-zinc-700 text-zinc-200 rounded-t-md text-pretty flex justify-between items-center whitespace-pre-wrap break-all`}
          >
            {bestFile ? (
              <div>
                <div className="text-base font-bold">
                  {bestFile.split('/').pop()}:{firstMatch.start + 3}
                  {firstMatch.start + 3 != firstMatch.end - 2 &&
                    `-${firstMatch.end - 2}`}
                  &nbsp;{score < 98 && `(${Math.round(score)}% match)`}
                </div>
                <div className="text-zinc-400 text-xs">{bestFile}</div>
              </div>
            ) : (
              <div>
                <FaExclamationTriangle
                  className="inline mr-4"
                  style={{ marginTop: -3 }}
                />
                <span className="text-sm">Not found in context</span>
              </div>
            )}
            <CopyButton value={code} />
          </div>
          {highlightedCode}
        </HoverCardTrigger>
        <HoverCardContent
          className="w-full max-h-[400px] overflow-y-auto"
          hidden={!(bestMatch && Object.keys(bestMatch).length > 0)}
        >
          {bestMatch && Object.keys(bestMatch).length > 0 && (
            <div>
              {Object.entries(bestMatch).map(
                ([snippet_name, match_objects]: [string, any]) => (
                  <div key={snippet_name}>
                    {match_objects.map((match_object: any, index: number) => (
                      <div key={index} className="mb-4">
                        <div className="font-mono">
                          <span className="text-md font-bold">
                            {snippet_name.split('/').pop()}:
                            {match_object.start + 3}-{match_object.end - 2}
                          </span>
                          <br />
                          <span className="text-xs">{snippet_name}</span>
                        </div>
                        <SyntaxHighlighter
                          {...rest}
                          language={match ? match[1] : undefined}
                          lineProps={(lineNumber: number) => {
                            return [
                              match_object.start + 1,
                              match_object.start + 2,
                              match_object.end - 1,
                              match_object.end,
                            ].includes(lineNumber)
                              ? {
                                  style: { opacity: '50%', fontWeight: 'bold' },
                                }
                              : {}
                          }}
                          startingLineNumber={match_object.start + 1}
                        >
                          {String(match_object.code).replace(/\n$/, '')}
                        </SyntaxHighlighter>
                      </div>
                    ))}
                  </div>
                )
              )}
            </div>
          )}
        </HoverCardContent>
      </HoverCard>
    </span>
  )
}

export { CodeBlock }
