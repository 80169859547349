import { useContext, useState } from 'react'
import { Button } from './ui/button'
import { FaCopy } from 'react-icons/fa'
import { FaCircleCheck } from 'react-icons/fa6'
import { cn } from '@/lib/utils'
import { PostHogContext } from '@/lib/contexts'

export default function CopyButton({
  value,
  className,
  ...props
}: { value: string; className?: string } & React.ComponentProps<
  typeof Button
>) {
  const [copied, setCopied] = useState(false)
  const { posthog_capture } = useContext(PostHogContext)
  return (
    <Button
      onClick={() => {
        setCopied(true)
        navigator.clipboard.writeText(value)
        posthog_capture('chat_changes_taken', {
          event: 'Copy button clicked',
          content_copied: value,
        })
        setTimeout(() => {
          setCopied(false)
        }, 2000)
      }}
      className={cn(
        'bg-transparent hover:bg-zinc-200 hover:bg-opacity-20 text-white font-sans px-3 py-1',
        className
      )}
      {...props}
    >
      {copied ? <FaCircleCheck /> : <FaCopy />}
    </Button>
  )
}
