import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import jsx from 'react-syntax-highlighter/dist/esm/languages/prism/jsx'
import tsx from 'react-syntax-highlighter/dist/esm/languages/prism/tsx'
import python from 'react-syntax-highlighter/dist/esm/languages/prism/python'
import go from 'react-syntax-highlighter/dist/esm/languages/prism/go'
import ruby from 'react-syntax-highlighter/dist/esm/languages/prism/ruby'
import java from 'react-syntax-highlighter/dist/esm/languages/prism/java'
import bash from 'react-syntax-highlighter/dist/esm/languages/prism/bash'
import { codeStyle } from './constants'
import { ComponentProps } from 'react'
import { cn } from './utils'

SyntaxHighlighter.registerLanguage('js', jsx)
SyntaxHighlighter.registerLanguage('jsx', jsx)
SyntaxHighlighter.registerLanguage('javascript', jsx)
SyntaxHighlighter.registerLanguage('ts', tsx)
SyntaxHighlighter.registerLanguage('tsx', tsx)
SyntaxHighlighter.registerLanguage('python', python)
SyntaxHighlighter.registerLanguage('go', go)
SyntaxHighlighter.registerLanguage('ruby', ruby)
SyntaxHighlighter.registerLanguage('java', java)

SyntaxHighlighter.registerLanguage('sh', bash)
SyntaxHighlighter.registerLanguage('bash', bash)
SyntaxHighlighter.registerLanguage('zsh', bash)
SyntaxHighlighter.registerLanguage('shell', bash)


export default function Highlighter({
  customStyle,
  ...props
}: ComponentProps<typeof SyntaxHighlighter>) {
  return (
    <SyntaxHighlighter
      PreTag="div"
      style={codeStyle}
      customStyle={{
        backgroundColor: '#333',
        ...customStyle,
      }}
      className={cn(
        'rounded-xl whitespace-pre-wrap break-all',
        props.className
      )}
      lineProps={{ style: { flexWrap: 'wrap', overflowWrap: 'break-word' } }}
      wrapLines
      wrapLongLines
      {...props}
    />
  )
}
