import { createRoot } from 'react-dom/client'
import App from './components/App'
import TelemetryPage from './pages/Telemetry'
import MetricsPage from './pages/Metrics'
import CommitMetricsPage from './pages/CommitMetrics'
import { RouterProvider, createBrowserRouter, redirect } from 'react-router-dom'
import { BACKEND_URL } from './lib/constants';
import MetricsCSVPage from './pages/MetricsCSVPage'

const router = createBrowserRouter([
  {
    path: "/",
    element: <App key={window.location.pathname} />,
  },
  {
    path: "/api/auth/callback/github", // ugly URL for backwards compatibility with NextAuth
    element: <App key={window.location.pathname} />,
  },
  {
    path: "/c/:messagesId",
    element: <App key={window.location.pathname} />,
  },
  {
    path: "/backend/login",
    loader: () => {
      return redirect(`${BACKEND_URL}/backend/login`);
    },
  },
  {
    path: "/telemetry",
    element: <TelemetryPage />,
  },
  {
    path: "/old_metrics",
    element: <MetricsPage />,
  },
  {
    path: "/metrics",
    element: <CommitMetricsPage />,
  },
  {
    path: "/metrics.csv",
    element: <MetricsCSVPage />,
  }
]);

createRoot(document.querySelector('#root')!).render(
  <RouterProvider router={router} />
)
