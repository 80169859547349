import faviconSvg from '../../public/icon.png'

const updateFavicon = (notify: boolean): void => {
  const canvas: HTMLCanvasElement = document.createElement('canvas')
  const size: number = 64 // Canvas size for drawing
  canvas.width = size
  canvas.height = size
  const context: CanvasRenderingContext2D | undefined = canvas.getContext('2d')

  if (context) {
    const img: HTMLImageElement = new Image()
    img.src = faviconSvg

    img.onload = (): void => {
      context.beginPath()
      context.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2)
      context.clip()

      context.drawImage(img, 0, 0, size, size)

      if (notify) {
        context.fillStyle = 'red'
        context.beginPath()
        context.arc(size - 18, 18, 8, 0, Math.PI * 2)
        context.fill()
      }

      // Update favicon
      const link: HTMLLinkElement =
        document.querySelector("link[rel*='icon']") ||
        document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      link.href = canvas.toDataURL('image/x-icon')
      document.querySelectorAll('head')[0].append(link)
    }

    img.onerror = (ev): void => {
      console.error('Failed to load the favicon image.', ev)
    }
  } else {
    console.error('Failed to get canvas context.')
  }
}

export default updateFavicon
