// general purpose utility functions for App.tsx

import { Message } from "./types"

const sum = (arr: number[]) => arr.reduce((acc, cur) => acc + cur, 0)

const getNewBranchName = () => {
  return (
    'sweep/chat-suggested-changes-' +
    new Date()
      .toISOString()
      .replace('T', '_')
      .replace(':', '_')
      .replace('.', '_')
  )
}

const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
}

function askNotificationPermission() {
  if (!('Notification' in window)) {
    console.warn('This browser does not support notifications.')
    return
  }
  Notification.requestPermission()
}

const countTokensForMessage = (message: Message) => {
  const TOKEN_PER_CHAR = 4
  let chars = 0
  if (message.role === 'assistant' || message.role === 'user') {
    chars = message.content.length
  } else {
    chars =
      sum(
        Object.values(message.function_call?.function_parameters || {}).map(
          (value) => value.length
        )
      ) + (message.function_call?.thinking?.length || 0)
    if (
      !(
        message.function_call?.function_name === 'vector_search' ||
        message.function_call?.function_name === 'access_file' ||
        message.function_call?.function_name === 'add_files_to_context'
      )
    ) {
      chars += message.content.length
    }
  }
  return Math.ceil(chars / TOKEN_PER_CHAR)
}

export { 
  sum,
  getNewBranchName,
  formatTime, 
  askNotificationPermission, 
  countTokensForMessage 
}