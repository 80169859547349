import React, { createContext, useContext } from 'react'
import { SnippetBadge } from './SnippetBadge'
import { Message, Snippet } from '@/lib/types'
import { Dispatch, MutableRefObject, SetStateAction, memo, useCallback, useMemo, useState, useEffect } from 'react'
import { FaArrowLeft, FaArrowRight, FaCheckCircle, FaCircleNotch, FaClock, FaExclamationTriangle, FaFolderOpen, FaRedo, FaSync, FaTrash, FaUndo } from 'react-icons/fa'
import { StatefulCodeSuggestion } from '@/lib/types'
import AutoScrollArea from '../ui/autoscroll'
import { Button } from '../ui/button'
import { FaCodeBranch, FaVial } from 'react-icons/fa'
import SuggestedChangeDisplay from '../SuggestedChangeDisplay'
import PulsingLoader from './PulsingLoader'
import { withLoading } from '@/lib/contextManagers'
import { toast } from '@/components/ui/use-toast'
import { Alert, AlertDescription, AlertTitle } from '../ui/alert'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip'
import { FaClockRotateLeft, FaCodeCommit, FaCodePullRequest } from 'react-icons/fa6'
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '../ui/alert-dialog'
import { Label } from '../ui/label'
import { Input } from '../ui/input'
import { Textarea } from '../ui/textarea'
import { useSession } from '@/hooks/useSession'
import { appliedChangesAtom, appliedChangesHistoryAtom, branchState, commitMessageState, doCreatePullRequestState, featureBranchState, messagesState, pullRequestBodyState, pullRequestTitleState, repoNameState, searchMessageState, streamActiveState, isRunningCiAtom } from '@/state/atoms'
import { useAtom, useAtomValue } from 'jotai'
import { PrValidationStatus, SnakeCaseKeys } from '../../lib/types'
import { streamResponseMessages } from '@/lib/streamingUtils'
import { toCamelCaseKeys } from '@/lib/strUtils'
import PrValidationStatusesDisplay from '../PrValidationStatusesDisplay'
import { prValidationStatusesState } from '@/state/atoms'
import { GITHUB_BASE_URL } from '@/lib/constants'
import { Switch } from '../ui/switch'
import { cloneDeep, truncate } from 'lodash'
import CopyButton from '../CopyButton'
import { PollingStatus, useFetchPolledAt } from '@/hooks/useFetchPolledAt'
import { Carousel, CarouselNavigatorButtons } from '../ui/carousel'

const CodeBlockWithCopy = ({ children }: { children: string }) => {
  return (
    <pre className="text-sm mt-2 p-4 bg-zinc-900 rounded-md w-full font-mono relative whitespace-pre-wrap overflow-x-auto break-all">
      {children}
      <CopyButton className="absolute right-1 top-1" value={children} />
    </pre>
  )
}

const HealthDisplay_ = ({
  value,
  percentage
}: {
  value: number
  percentage: number
}) => {
  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="relative w-14 h-14">
            <svg className="w-full h-full" viewBox="0 0 100 100">
              <circle
                className="text-zinc-700 stroke-current"
                strokeWidth="8"
                cx="50"
                cy="50"
                r="44"
                fill="transparent"
              ></circle>
              <circle
                className={`progress-ring__circle stroke-current ${percentage > 75 ? 'text-red-600' : (percentage > 25 ? 'text-yellow-600' : 'text-white-600')
                  }`}
                strokeWidth="8"
                strokeLinecap="round"
                cx="50"
                cy="50"
                r="44"
                fill="transparent"
                strokeDasharray={`${2 * Math.PI * 40}`}
                strokeDashoffset={`${2 * Math.PI * 40 * (1 - percentage / 100)}`}
                style={{
                  transition: 'stroke-dashoffset 0.35s',
                  transform: 'rotate(-90deg)',
                  transformOrigin: '50% 50%',
                }}
              ></circle>
            </svg>
            <div className="absolute inset-0 flex items-center justify-center">
              <span className="text-xs font-semibold">{(value / 1000).toPrecision(2)}k</span>
            </div>
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>
            {(value / 1000).toPrecision(2)}k ({Math.round(percentage)}% of max tokens) used in context. Model deteriorates after 25%. Reduce context by removing large irrelevant files.
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

const useOS = () => {
  return navigator.userAgent.includes('Macintosh') ? 'mac' : (navigator.userAgent.includes('Windows') ? 'windows' : 'linux')
}


const ToolTipModal = () => {
  const branch = useAtomValue(branchState)
  const { pollingStatus } = useFetchPolledAt()
  const { accessToken } = useSession()
  const os = useOS()

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="primary">
          <FaSync className="mr-2 inline" />Sync to Local
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Sync Sweep&apos;s Changes Locally</AlertDialogTitle>
          <AlertDialogDescription>
            This will sync Sweep&apos;s changes to your local file system.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <Carousel items={[
          <div className="p-2">
            <h2 className="text-2xl font-bold">
              1. Installation
            </h2>
            {import.meta.env.NEXT_PUBLIC_LOCAL_SYNC_DOCS_URL ? (
              <p className="text-sm mt-4">
                Install the Sweep CLI by following the instructions <a href={import.meta.env.NEXT_PUBLIC_LOCAL_SYNC_DOCS_URL} target="_blank" className="text-blue-500 hover:text-blue-500">here</a>. (5 min)
              </p>
            ) : (
              <>
                <p className="text-sm mt-4">
                  a. You need Python 3.10+. You can find instructions <a href="https://www.python.org/downloads/release/python-31015" target="_blank" className="text-blue-500 hover:text-blue-500">here</a>.
                </p>
                <p className="text-sm mt-4">
                  b. Install uv. This makes installing pip packages much faster.
                </p>
                <CodeBlockWithCopy>
                  {`${os === 'windows' ? 'powershell -ExecutionPolicy ByPass -c "irm https://astral.sh/uv/install.ps1 | iex"' : 'curl -LsSf https://astral.sh/uv/install.sh | sh'}`}
                </CodeBlockWithCopy>
                <p className="text-sm mt-4">
                  c. Install the Sweep CLI (requires Python 3.10+).
                </p>
                <CodeBlockWithCopy>
                  {`uv tool install sweepai`}
                </CodeBlockWithCopy>
              </>
            )}
          </div>,
          <div className="p-2">
            <h2 className="text-2xl font-bold">
              2. Stash your Local Changes
            </h2>
            <p className="text-sm mt-4">
              Stash any local changes you don't want to lose and check out to <code>{branch}</code>.
            </p>
            <CodeBlockWithCopy>
              {`git add . && git stash\ngit checkout ${branch}`}
            </CodeBlockWithCopy>
          </div>,
          <div className="p-2">
            <h2 className="text-2xl font-bold">
              3. Sync Your Changes
            </h2>
            <p className="text-sm mt-4">
              Run the following in your terminal to sync your local changes with Sweep's.
            </p>
            {/* TODO: Make the environment variable a separate step. */}
            <CodeBlockWithCopy>
              {`${os === 'windows' ? `$env:GITHUB_PAT = "${accessToken}"` : `export GITHUB_PAT="${accessToken}"`}\nuvx sweepai ${window.location.href as string}`}
            </CodeBlockWithCopy>
            {
              pollingStatus == PollingStatus.Connected ?
                <div className="text-green-500 w-full text-center mt-8"><FaCheckCircle className="mr-2 inline" />Connected</div>
              :
                <div className="text-yellow-500 w-full text-center mt-8"><FaCircleNotch className="mr-2 inline animate-spin" />Checking</div>
            }
          </div>
        ]}>
          <AlertDialogFooter className="flex !justify-between items-center w-full">
            <CarouselNavigatorButtons />
            <AlertDialogCancel>Close</AlertDialogCancel>
          </AlertDialogFooter>
        </Carousel>
      </AlertDialogContent>
    </AlertDialog>
  )
}

const HealthDisplay = memo(HealthDisplay_, (prev, next) => prev.value.toPrecision(2) === next.value.toPrecision(2))

const AppliedChangesDisplay = memo(({
  appliedChanges,
  setAppliedChanges,
  featureBranch,
  setFeatureBranch,
  messages,
  setMessages,
  authorizedFetch,
  stream
}: {
  appliedChanges: StatefulCodeSuggestion[]
  setAppliedChanges: Dispatch<SetStateAction<StatefulCodeSuggestion[]>>
  featureBranch: string | undefined
  setFeatureBranch: Dispatch<SetStateAction<string | undefined>>
  messages: Message[]
  setMessages: Dispatch<SetStateAction<Message[]>>
  authorizedFetch: (url: string, body?: { [key: string]: any }, options?: RequestInit) => Promise<Response>
  stream: MutableRefObject<ReadableStreamDefaultReader<Uint8Array> | undefined>
}) => {
  const repoName = useAtomValue(repoNameState)
  const [branch, setBranch] = useAtom(branchState);
  const [pullRequestTitle, setPullRequestTitle] = useAtom(pullRequestTitleState)
  const [pullRequestBody, setPullRequestBody] = useAtom(pullRequestBodyState)
  const [commitMessage, setCommitMessage] = useAtom(commitMessageState)
  const [isAutoFixing] = useState(false)
  const [creatingBranch, setCreatingBranch] = useState(false)

  const {undo, canUndo, redo, canRedo} = useAtomValue(appliedChangesHistoryAtom)

  const [isValidatingChanges, setIsValidatingChanges] = useState(false)
  const [prValidationStatuses, setPrValidationStatuses] = useAtom(prValidationStatusesState)
  const isStreamActive = useAtomValue(streamActiveState)
  const [isRunningCi, setIsRunningCi] = useAtom(isRunningCiAtom)

  const [doCreatePullRequest, setDoCreatePullRequest] = useAtom(doCreatePullRequestState)
  const [isWritingPullRequestMetadata, setIsWritingPullRequestMetadata] = useState(false)
  const { username } = useSession()

  const { pollingStatus, pullLocalChanges, changesOutdated } = useFetchPolledAt()
  console.log(import.meta.env.NEXT_PUBLIC_LOCAL_SYNC_ENABLED)

  useEffect(() => {
    if (isRunningCi) {
      validateChanges();
    }
  }, [isRunningCi]);

  const writePullRequestMetadata = async () => {
    if (pullRequestTitle && pullRequestBody && commitMessage) {
      return
    }
    await withLoading(
      setIsWritingPullRequestMetadata,
      async () => {
        const prMetadata = await authorizedFetch('/create_pull_metadata', {
          repo_name: repoName,
          modify_files_dict: appliedChanges.reduce((acc, change) => {
            acc[change.filePath] = {
              original_contents: change.originalCode,
              contents: change.newCode
            }
            return acc
          }, {} as { [key: string]: {original_contents: string, contents: string} }),
          messages,
        })
        const prData = await prMetadata.json()
        const { title, description, commit_message, branch: featureBranch } = prData
        setPullRequestTitle(title || 'Sweep Chat Suggested Changes')
        setPullRequestBody(description || 'Suggested changes by Sweep Chat.')
        setCommitMessage(commit_message || 'Suggested changes by Sweep Chat.')
        setFeatureBranch(featureBranch)
      }
    )
  }
  const createPullRequest = async () => {
    await withLoading(
      setCreatingBranch,
      async () => {
        const file_changes = appliedChanges.reduce(
          (
            acc: { [key: string]: string },
            suggestion: StatefulCodeSuggestion
          ) => {
            acc[suggestion.filePath] = suggestion.newCode
            return acc
          },
          {}
        )
        let response: Response | undefined
        const createdBranch =
          featureBranch ||
          'sweep-chat-patch-' +
          new Date().toISOString().split('T')[0] // use ai for better branch name, title, and body later
        
        const originalUserRequest = messages[0]?.content || '';
        const truncatedUserRequest = truncate(originalUserRequest, { length: 1000 });

        response = await authorizedFetch(`/create_pull`, {
          file_changes: file_changes,
          base_branch: branch,
          branch: createdBranch,
          title: pullRequestTitle,
          body:
            `This pull request was created by Sweep to resolve the following request by @${username}:\n\n> ${truncatedUserRequest.replaceAll(/\n/g, '\n> ')}\n\n**Continue chatting at ${window.location.href}.**\n\n` +
            pullRequestBody,
        })
        setFeatureBranch(createdBranch)
        const data = await response!.json()
        const {
          pull_request: pullRequest,
          new_branch: newBranch,
        } = data
        pullRequest.branch = newBranch
        console.log('pullrequest', pullRequest)
        // for commits, show a different message
        const newMessages: Message[] = [
          ...messages,
          {
            content: `Pull request created: [https://${GITHUB_BASE_URL}/${repoName}/pull/${pullRequest.number}](https://${GITHUB_BASE_URL}/${repoName}/pull/${pullRequest.number})`,
            role: 'assistant',
            annotations: {
              pulls: [pullRequest],
            },
          },
        ]
        console.log(pullRequest)
        setMessages(newMessages)
        setAppliedChanges([])

        toast({
          title: `Pull request created: https://${GITHUB_BASE_URL}/${repoName}/pull/${pullRequest.number}`,
          variant: 'default',
          duration: 5000,
        })
      },
      (error: any) => {
        
        toast({
          title: 'Failed to Create Pull Request',
          description: `An error occurred while creating the pull request: ${error.message}`,
          variant: 'destructive',
          duration: Infinity,
        })
      }
    )
  }
  const createBranch = async () => {
    await withLoading(
      setCreatingBranch,
      async () => {
        if (!featureBranch) {
          toast({
            title: 'Failed to create branch',
            description: 'Please enter a branch name',
            variant: 'destructive',
            duration: Infinity,
          })
          return
        }
        const branchResponse = await authorizedFetch('/create_branch', {
          repo_name: repoName,
          file_changes: appliedChanges.reduce(
            (acc: { [key: string]: string }, suggestion) => {
              acc[suggestion.filePath] = suggestion.newCode
              return acc
            },
            {}
          ),
          branch: featureBranch,
          base_branch: branch,
        })

        const branchData = await branchResponse.json()
        const currentFeatureBranch = branchData.branch || featureBranch
        setFeatureBranch(currentFeatureBranch)
        setBranch(currentFeatureBranch)

        setMessages((messages_) => [
          ...messages_,
          {
            content: `Changes pushed to branch [${currentFeatureBranch}](https://${GITHUB_BASE_URL}/${repoName}/tree/${currentFeatureBranch}). To check out the branch:\n\n\`\`\`bash\ngit fetch && git checkout ${currentFeatureBranch}\n\`\`\``,
            role: 'assistant',
          },
        ])

        if (currentFeatureBranch) {
          setAppliedChanges([])
        }
      },
    )
  }
  const validateChanges = useCallback(
    async () => {
      const fileChanges = appliedChanges.reduce((acc: { [key: string]: string }, suggestion) => {
        acc[suggestion.filePath] = suggestion.newCode
        return acc
      }, {})
      setPrValidationStatuses([])
      withLoading(
        setIsValidatingChanges,
        async () => {
          const response = await authorizedFetch(`/validate_changes`, {
            file_changes: fileChanges,
            branch: branch,
          })
          let currentPrValidationStatuses: PrValidationStatus[] = []
          let currentFileChanges: StatefulCodeSuggestion[] = cloneDeep(appliedChanges)
          for await (const streamedPrValidationStatuses of streamResponseMessages(
            response,
            stream
          )) {
            const { statuses, file_changes } = streamedPrValidationStatuses
            currentPrValidationStatuses = statuses.map(
              (status: SnakeCaseKeys<PrValidationStatus>) =>
                toCamelCaseKeys(status)
            )

            setPrValidationStatuses(currentPrValidationStatuses)

            currentFileChanges = currentFileChanges.map((change) => (new StatefulCodeSuggestion({
              ...change,
              newCode: file_changes[change.filePath] || change.newCode,
            })))

            setAppliedChanges(currentFileChanges)
          }
          setIsRunningCi(false)
        },
        (error) => {
          toast({
            title: 'Error validating PR',
            description: `Please try again later.\n${error.message}`,
            variant: 'destructive',
            className: 'whitespace-break-spaces',
          })
          setIsRunningCi(false)
        }
      )
    },
    [branch, messages, authorizedFetch, appliedChanges, setIsRunningCi]
  )
  return (
    <div className="mt-4 overflow-y-auto rounded-lg bg-zinc-900">
      <div className="flex justify-between align-start">
        <div className="flex items-center align-middle mb-4 justify-between w-full">
          <h2 className="text-xl font-bold">Changes</h2>
          <div className="flex items-center justify-start space-x-2 grow ml-4">
            <Button variant="ghost" className="text-gray-500 hover:bg-transparent hover:text-gray-300 text-xs" disabled={!canUndo} onClick={undo} size="xs">
              <FaUndo />
            </Button>
            <Button variant="ghost" className="text-gray-500 hover:bg-transparent hover:text-gray-300 text-xs" disabled={!canRedo} onClick={redo} size="xs">
              <FaRedo />
            </Button>
          </div>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button variant="ghost" className="text-gray-500 hover:bg-transparent hover:text-gray-300">
                <FaTrash />
                {/* User got confused when we called this "Discard", thinking it was undo. */}
                &nbsp;&nbsp;Clear
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  Are you sure you want to reject the changes? This cannot
                  be undone.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction asChild>
                  <Button
                    variant="destructive"
                    onClick={() => {
                      setPrValidationStatuses([])
                      setAppliedChanges([])
                    }}
                    className="bg-red-600 hover:bg-red-700 text-white"
                  >
                    Yes, Discard All Changes
                  </Button>
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </div>
      {appliedChanges.length > 0 ? (
        appliedChanges.map((suggestion, index) => (
          <SuggestedChangeDisplay
            repoName={repoName}
            branch={branch}
            suggestion={suggestion}
            setSuggestedChange={(newSuggestion) => {
              setAppliedChanges((prevAppliedChanges) =>
                prevAppliedChanges.map((suggestion, i) =>
                  i === index
                    ? (typeof newSuggestion === 'function'
                      ? newSuggestion(suggestion)
                      : newSuggestion)
                    : suggestion
                )
              )
            }}
            key={`${index}-${JSON.stringify(suggestion)}`}
            isAutoFixing={isAutoFixing}
            removeSuggestedChange={() =>
              setAppliedChanges(
                appliedChanges.filter((_, i) => i !== index)
              )
            }
          />
        ))
      ) : (
        <div className="flex justify-center items-center my-4">
          <p className="text-gray-500">
            No changes staged yet.
          </p>
        </div>
      )}
      {isStreamActive ? (
        <div className="flex-col items-center justify-around w-full pb-2 mb-4 px-2">
          <div className="flex items-center justify-center mb-8 text-center">
            <PulsingLoader size={1.5} />
          </div>
          <p className="text-center">
            Applying the diff patches and fixing errors. This
            may take a few minutes.
          </p>
        </div>
      ) : (appliedChanges.length > 1 ? (
        <p className="text-xs text-gray-500 mb-6 text-center">
          {appliedChanges.length} files changed
        </p>
      ) : undefined)}
      {isValidatingChanges && prValidationStatuses.length === 0 && (
        <div className="text-center">
          <div className="flex items-center justify-center mt-2">
            <PulsingLoader size={1.5} />
          </div>
          <p className="mt-6 mb-6">Validating changes...</p>
        </div>
      )}
      <PrValidationStatusesDisplay statuses={prValidationStatuses} />
      {creatingBranch ? (
        <div className="text-center">
          <div className="flex items-center justify-center mt-2">
            <PulsingLoader size={1.5} />
          </div>
          <p className="mt-6">Pushing changes to branch...</p>
        </div>
      ) : (
        <div className="flex flex-col space-y-3">
          <div className="flex justify-end space-x-4">
            {/* <Button
              variant="secondary"
              disabled={isValidatingChanges || !(import.meta.env.NEXT_PUBLIC_ENABLE_CI === "true")}
              onClick={validateChanges}
            >
              <FaVial />
              &nbsp;&nbsp;Run CI
            </Button> */}
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button
                  variant="secondary"
                  disabled={isStreamActive}
                  onClick={writePullRequestMetadata}
                >
                  <FaCodeCommit />
                  &nbsp;&nbsp;Commit
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent className="p-8">
                <AlertDialogHeader>
                  <AlertDialogTitle className="my-4">Commit Changes</AlertDialogTitle>
                </AlertDialogHeader>
                <div>
                  <Label>Branch</Label>
                  <Input
                    value={branch}
                    onChange={(e) => setBranch(e.target.value)}
                    className="w-full mb-4 text-zinc-300 grow"
                  />
                </div>
                <div className="mb-4">
                  <Label>Commit Message</Label>
                  <Input
                    className="flex items-center"
                    value={commitMessage || ''}
                    onChange={(e) => setCommitMessage(e.target.value)}
                    placeholder="Commit message"
                    disabled={commitMessage == undefined}
                    maxLength={50}
                  />
                </div>
                <div className="flex justify-end my-2">
                  <AlertDialogCancel className="mr-4">Cancel</AlertDialogCancel>
                  <Button
                    id="commit-to-branch-button"
                    variant="primary"
                    onClick={async () => {
                      await withLoading(
                        setCreatingBranch,
                        async () => {
                          const file_changes = appliedChanges.reduce(
                            (
                              acc: { [key: string]: string },
                              suggestion: StatefulCodeSuggestion
                            ) => {
                              acc[suggestion.filePath] = suggestion.newCode
                              return acc
                            },
                            {}
                          )
                          let response: Response | undefined
                          response = await authorizedFetch(`/commit_to_branch`, {
                            file_changes: file_changes,
                            base_branch: branch,
                            commit_message: commitMessage,
                          })

                          const data = await response!.json()
                          const { sha } = data

                          // for commits, show a different message
                          const newMessages: Message[] = [
                            ...messages,
                            {
                              content: `Changes commited into branch ${branch} via [https://${GITHUB_BASE_URL}/${repoName}/commit/${sha}](https://${GITHUB_BASE_URL}/${repoName}/commit/${sha}).`,
                              role: 'assistant',
                            },
                          ]
                          setMessages(newMessages)
                          setAppliedChanges([])

                          toast({
                            title: `Changes commited to branch ${branch} via https://${GITHUB_BASE_URL}/${repoName}/commit/${sha}.`,
                            variant: 'default',
                            duration: 5000,
                          })
                        }, (error: any) => {
                          
                          toast({
                            title: `Failed to commit to branch ${branch}`,
                            description: `An error occurred while commiting to the branch: ${error.message}`,
                            variant: 'destructive',
                            duration: Infinity,
                          })
                        }
                      )
                    }}
                  >
                    Commit
                  </Button>
                </div>
              </AlertDialogContent>
            </AlertDialog>
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button
                  variant="secondary"
                  disabled={isStreamActive}
                  onClick={writePullRequestMetadata}
                >
                  <FaCodeBranch />
                  &nbsp;&nbsp;Create Branch
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent className="p-8 max-w-[800px]">
                <AlertDialogHeader>
                  <AlertDialogTitle className="my-6">Push Changes</AlertDialogTitle>
                </AlertDialogHeader>
                <div className="flex grow items-center mb-2">
                  <Input
                    className="flex items-center"
                    value={branch || ''}
                    onChange={(e) => setBranch(e.target.value)}
                    placeholder="Base Branch"
                  />
                  <FaArrowLeft className="mx-4" />
                  <Input
                    className="flex items-center"
                    value={featureBranch || ''}
                    onChange={(e) => setFeatureBranch(e.target.value)}
                    placeholder="Feature Branch"
                  />
                </div>
                <div className="flex items-center w-full mb-2">
                  <Switch
                    id="create-pull-request-switch"
                    checked={doCreatePullRequest}
                    onCheckedChange={(checked) => setDoCreatePullRequest(checked)}
                  />
                  <Label htmlFor="create-pull-request-switch" className="ml-4">Create a pull request?</Label>
                </div>
                <div className="mt-4" style={{ opacity: doCreatePullRequest ? 1 : 0.5 }}>
                  <Label>Title</Label>
                  <Input
                    value={pullRequestTitle || ''}
                    onChange={(e) => setPullRequestTitle(e.target.value)}
                    placeholder="Pull Request Title"
                    className="w-full mb-2 text-zinc-300"
                    disabled={pullRequestTitle == undefined || isWritingPullRequestMetadata || !doCreatePullRequest}
                  />
                  <Label>Body</Label>
                  <Textarea
                    value={pullRequestBody || ''}
                    onChange={(e) => setPullRequestBody(e.target.value)}
                    placeholder="Pull Request Body"
                    className="w-full mb-2 text-zinc-300"
                    disabled={pullRequestTitle == undefined || isWritingPullRequestMetadata || !doCreatePullRequest}
                    rows={8}
                  />
                </div>
                {isWritingPullRequestMetadata && (
                  <div className="text-center">
                    <div className="flex items-center justify-center mt-2">
                      <PulsingLoader size={1.5} />
                    </div>
                    <p className="mt-6">Generating branch name...</p>
                  </div>
                )}
                <div className="flex justify-end mb-2 mt-6">
                  <AlertDialogCancel className="mr-4">Cancel</AlertDialogCancel>
                  <Button
                    id="create-pull-request-button-inner"
                    variant="primary"
                    onClick={doCreatePullRequest ? createPullRequest : createBranch}
                    disabled={
                      creatingBranch ||
                      !featureBranch ||
                      !pullRequestTitle ||
                      !pullRequestBody
                    }
                  >
                    {doCreatePullRequest ? <FaCodePullRequest /> : <FaCodeBranch />}
                    &nbsp;&nbsp;
                    {doCreatePullRequest ? 'Create Pull Request' : 'Create Branch'}
                  </Button>
                </div>
              </AlertDialogContent>
            </AlertDialog>
            {import.meta.env.NEXT_PUBLIC_LOCAL_SYNC_ENABLED === 'true' && (
              pollingStatus == PollingStatus.Connected ? (
                <Button
                  variant="primary"
                  onClick={pullLocalChanges}
                  disabled={pollingStatus != PollingStatus.Connected || !changesOutdated}
                >
                  <FaSync />&nbsp;&nbsp;Pull Local Changes
                </Button>
              ): (
                <ToolTipModal />
              )
            )}
          </div>
          {import.meta.env.NEXT_PUBLIC_LOCAL_SYNC_ENABLED === 'true' && (
            pollingStatus == PollingStatus.Connected ? (
              changesOutdated ? (
                <div className="text-yellow-500 text-right"><FaClockRotateLeft className="mr-2 inline" />Click to Refresh Files</div>
              ): (
                <div className="text-green-500 text-right"><FaCheckCircle className="mr-2 inline" />Connected to Local</div>
              )
            ) : pollingStatus == PollingStatus.Stale ?
              <div className="text-yellow-500 text-right"><FaCircleNotch className="mr-2 inline animate-spin" />Local Connection Inactive</div>
            : pollingStatus == PollingStatus.Disconnected ?
              <div className="text-red-500 text-right"><FaExclamationTriangle className="mr-2 inline" />Disconnected from Local </div>
            : null
          )}
        </div>
      )}
      {appliedChanges.some(
        (suggestion) => suggestion.state == 'error'
      ) &&
        appliedChanges.length > 0 &&
        !isStreamActive && (
          <Alert className="mb-4 bg-yellow-900">
            <FaExclamationTriangle className="h-4 w-4" />
            <AlertTitle>Warning</AlertTitle>
            <AlertDescription>
              Some patches failed to validate, so you may get
              some unexpected changes. You can try to manually
              create a PR with the proposed changes. If you
              think this is an error, please to report this to
              us.
            </AlertDescription>
          </Alert>
        )}
    </div>
  );
});
AppliedChangesDisplay.displayName = 'AppliedChangesDisplay';

const ContextSideBar = memo(({
  snippets,
  setSnippets,
  authorizedFetch,
  stream,
  totalTokens,
}: {
  snippets: Snippet[]
  setSnippets: Dispatch<SetStateAction<Snippet[]>>
  authorizedFetch: (url: string, body?: { [key: string]: any }, options?: RequestInit) => Promise<Response>
  stream: MutableRefObject<ReadableStreamDefaultReader<Uint8Array> | undefined>
  totalTokens: number
}) => {
  const repoName = useAtomValue(repoNameState)
  const branch = useAtomValue(branchState);
  const searchMessage = useAtomValue(searchMessageState)
  const [messages, setMessages] = useAtom(messagesState);
  const [featureBranch, setFeatureBranch] = useAtom(featureBranchState)

  const [appliedChanges, setAppliedChanges] = useAtom(appliedChangesAtom)

  // use this to prevent unnecessary rerenders
  const removeOption = useMemo(() => ['remove'], []);

  if (!repoName) {
    return
  }
  return (
    <div className="w-full flex flex-col h-full bg-zinc-900 rounded-lg p-4">
      <div className="flex px-4 items-center">
        <h2 className="text-3xl font-bold">Context</h2>
        <div className="grow"></div>
        {totalTokens > 0 && (
          <div className="p-4 flex">
            <HealthDisplay
              value={totalTokens}
              percentage={Math.min((totalTokens / (200 * 1000)) * 100, 100)}
            />
          </div>
        )}
      </div>
      {snippets.length > 0 || appliedChanges.length > 0 || searchMessage.length > 0 ? (
        <AutoScrollArea className="w-full flex-1 rounded-md p-4 overflow-x-auto overflow-y-auto max-h-full grow">
          {snippets.length > 0 && (
            <>
              <h3 className="text-xl font-bold mb-4">Files</h3>
              {snippets.map((snippet, index) => (
                <React.Fragment key={index}>
                  <SnippetBadge
                    key={index}
                    snippet={snippet}
                    repoName={repoName}
                    branch={branch}
                    snippets={snippets}
                    setSnippets={setSnippets}
                    options={removeOption}
                  />
                  <br />
                </React.Fragment>
              ))}
            </>
          )}
          {appliedChanges.length > 0 && (
            <AppliedChangesDisplay
              appliedChanges={appliedChanges}
              setAppliedChanges={setAppliedChanges}
              featureBranch={featureBranch}
              setFeatureBranch={setFeatureBranch}
              messages={messages}
              setMessages={setMessages}
              authorizedFetch={authorizedFetch}
              stream={stream}
            />
          )}
        </AutoScrollArea>
      ) : (
        <div className="h-full w-full rounded-md border flex flex-col justify-center items-center text-zinc-700">
          <FaFolderOpen className="mb-6" size={150} />
          <h2 className="text-lg font-bold mb-1">No context yet</h2>
          <p>Start chatting with Sweep to get started</p>
        </div>
      )}
    </div>
  )
});

ContextSideBar.displayName = 'ContextSideBar';

export { ContextSideBar }
