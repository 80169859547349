import { GITHUB_BASE_URL, codeStyle, getFileEndingsToLanguages } from '@/lib/constants'
import Markdown from 'react-markdown'
import SyntaxHighlighter from '@/lib/syntaxHighlighter'
import remarkGfm from 'remark-gfm'
// @ts-expect-error
import * as Diff from 'diff'
import { Snippet, StatefulCodeSuggestion } from '@/lib/types'

import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card'
import { SnippetBadge } from './SnippetBadge'
import { CodeBlock } from './CodeBlock'
import { useCallback, useMemo, useState } from 'react'
import CopyButton from '../CopyButton'
import { getDiffMetadata, getLanguageFromPath, stripTripleQuotes } from '@/lib/strUtils'
import { Toggle } from '../ui/toggle'
import { FaColumns } from 'react-icons/fa'

import { streamActiveState } from '@/state/atoms'
import { useAtom } from 'jotai';
import DiffViewer from '../DiffViewer'

// model forgets </new_code> sometimes
const CODE_CHANGE_START = '<code_change>'
const FILE_PATH_PATTERN = /<file_path>\n*(?<filePath>[\s\S]+?)\n*($|<\/file_path>)/
const JUSTIFICATION_PATTERN = /<justification>\n*(?<justification>[\s\S]+?)\n*($|<\/justification>)/
const ORIGINAL_CODE_PATTERN = /(<original_code>\n*(?<originalCode>[\s\S]+?)\n*(<\/original_code>)?(<\/new_code>)?)?\n*/ // sometimes it messes up the tags
const NEW_CODE_PATTERN = /<new_code>\n*(?<newCode>[\s\S]+?)\n*($|\n*(<\/new_code>)?)\s*/
const CLOSING_TAG_PATTERN = /(?<closingTag><\/code_change>)/

// THIS IS NOW UNIT TESTED, THIS IS SAFE
const CODE_CHANGE_PATTERN = new RegExp(
  `${CODE_CHANGE_START}\\s*${FILE_PATH_PATTERN.source}\\s*($|${JUSTIFICATION_PATTERN.source}\\s*($|${ORIGINAL_CODE_PATTERN.source}\\s*($|${NEW_CODE_PATTERN.source}\\s*($|${CLOSING_TAG_PATTERN.source}))))`,
  'gs'
)

const parseCodeOccurencesInSnippets = (
  codeContent: string,
  contextSnippets: Snippet[]
) => {
  let codeLinesBySnippet: {
    [fileName: string]: {
      snippet: Snippet
      lineDict: { [lineNumber: number]: [string, string] }
    }
  } = {}

  if (contextSnippets) {
    const escapedCodeContent = codeContent.replaceAll(
      /[.*+?^${}()|[\]\\]/g,
      '\\$&'
    )
    const regex = new RegExp(`\\b${escapedCodeContent}\\b`, 'g')

    contextSnippets.forEach((snippet) => {
      let snippetMatches: { [lineNumber: number]: [string, string] } = {}
      const lines = snippet.content.split('\n')

      lines.forEach((line, index) => {
        const matches = line.match(regex)
        if (matches) {
          matches.forEach((match) => {
            snippetMatches[snippet.start + index + 1] = [match, line]
          })
        }
      })

      codeLinesBySnippet[snippet.file_path] = {
        snippet: snippet,
        lineDict: snippetMatches,
      }
    })
  }

  return codeLinesBySnippet
}

const DiffRenderer = ({ match }: { match: any }) => {
  let { filePath, originalCode = '', newCode, closingTag } = match.groups || {}
  originalCode = stripTripleQuotes(originalCode)
  newCode = stripTripleQuotes(newCode)
  const [splitDiff, setSplitDiff] = useState(false)
  let _result = ''
  if (newCode == undefined) {
    _result = originalCode
  } else {
    let diffLines = Diff.diffLines(
      originalCode.trimEnd().replace(/^\n+/, '') + '\n',
      newCode.trimEnd().replace(/^\n+/, '') + '\n'
    )
    if (!closingTag) {
      if (diffLines.length >= 2) {
        const lastDiff = diffLines[diffLines.length - 1]
        const secondLastDiff = diffLines[diffLines.length - 2]
        if (
          lastDiff.added &&
          lastDiff.value.trim().split('\n').length === 1 &&
          secondLastDiff.removed &&
          secondLastDiff.value.trim().split('\n').length > 1
        ) {
          const temp = diffLines[diffLines.length - 1]
          diffLines[diffLines.length - 1] = diffLines[diffLines.length - 2]
          diffLines[diffLines.length - 2] = temp
          diffLines[diffLines.length - 2].value = ''
          diffLines[diffLines.length - 1].removed = false
        }
      }
    }
    _result = diffLines
      .map(
        (
          {
            added,
            removed,
            value,
          }: { added?: boolean; removed?: boolean; value: string },
          index: number
        ): string => {
          let symbol = added ? '+' : (removed ? '-' : ' ')
          if (
            index === diffLines.length - 1 &&
            index === diffLines.length - 2 &&
            removed &&
            !closingTag
          ) {
            symbol = ' '
          }
          const results =
            symbol + value.trimEnd().replaceAll('\n', '\n' + symbol)
          return results
        }
      )
      .join('\n')
  }
  const { numLinesAdded, numLinesRemoved } = getDiffMetadata({
    originalCode: originalCode || '',
    newCode: newCode || '',
  })
  return (
    <>
      <div className="text-white mt-4 flex justify-between items-center rounded-t-md bg-zinc-700 p-4 whitespace-pre-wrap break-words">
        <div className="flex flex-col">
          <div className="text-base font-mono font-bold">
            {filePath.split('/').pop()}{' '}
            {numLinesAdded > 0 && (
              <span className="text-green-500">+{numLinesAdded}</span>
            )}{' '}
            {numLinesRemoved > 0 && (
              <span className="text-red-500">-{numLinesRemoved}</span>
            )}{' '}
          </div>
          <div className="text-xs text-gray-400">{filePath}</div>
        </div>
        <div className="flex items-center gap-2">
          <Toggle
            pressed={splitDiff}
            onPressedChange={setSplitDiff}
            className="font-sans"
          >
            <FaColumns />
          </Toggle>
          <CopyButton value={newCode} />
        </div>
      </div>
      {!originalCode && newCode ? (
        <>
          <div className={`w-full p-2 relative ${closingTag === undefined ? 'bg-gradient-to-b from-zinc-800 to-transparent' : 'bg-zinc-800'} transition-colors duration-300`}>
            <div className="absolute top-4 right-2 z-10">
              <CopyButton value={newCode} />
            </div>
            <SyntaxHighlighter
              language={getFileEndingsToLanguages(
                getLanguageFromPath(filePath) || 'js'
              )}
              customStyle={{
                marginTop: 0,
                backgroundColor: "transparent",
              }}
            >
              {newCode}
            </SyntaxHighlighter>
          </div>
        </>
      ): (
        <DiffViewer
          suggestion={new StatefulCodeSuggestion({
            filePath,
            originalCode,
            fileContents: originalCode,
            newCode,
            state: 'done',
          })}
          splitDiff={splitDiff}
          streaming={closingTag === undefined}
        />
      )}
    </>
  )
}

const MarkdownRenderer = ({
  content,
  className,
  snippets,
  repoName,
  branch,
  metaData,
  disableCodeBlockHover = false,
}: {
  content: string
  className?: string
  snippets?: Snippet[]
  repoName: string
  branch: string
  metaData?: any
  disableCodeBlockHover?: boolean
}) => {
  const [isStreamActive] = useAtom(streamActiveState);
  content = content
    .replace('<code_entity_relationship_map>', '\n\n```\n')
    .replace('</code_entity_relationship_map>', '```\n\n')
  const matches = Array.from(content.matchAll(CODE_CHANGE_PATTERN))
  let transformedContent = content

  const codeSuggestions = useMemo(
    () =>
      matches.map((match, index) => {
        return <DiffRenderer key={index} match={match} />
      }),
    [matches]
  )

  for (const [i, match] of Array.from(matches.entries())) {
    transformedContent = transformedContent.replace(
      match[0],
      `\`\`\`json-diff\n${i}\n\`\`\``
    )
  }

  const getAllSnippetsInContext = useCallback(
    (codeContent: string) => {
      if (!codeContent || typeof codeContent !== 'string') {
        return []
      }
      let matchingSnippets: Snippet[] = []

      if (snippets && codeContent) {
        const escapedCodeContent = codeContent.replaceAll(
          /[.*+?^${}()|[\]\\]/g,
          '\\$&'
        )
        const regex = new RegExp(`\\b${escapedCodeContent}\\b`, 'g')
        try {
          matchingSnippets = snippets.filter(
            (s) => regex.test(s.content) || regex.test(s.file_path)
          )
        } catch {
          return []
        }
      }

      return matchingSnippets
    },
    [snippets]
  )

  const renderer = {
    code(props: any) {
      const { children, className, ...rest } = props
      const allSnippetsInContext = getAllSnippetsInContext(String(children))
      const codeLinesBySnippet = parseCodeOccurencesInSnippets(
        String(children),
        allSnippetsInContext
      )
      const match = /language-(\w+)/.exec(className || '')
      if (match) {
        if (props?.className === 'language-json-diff') {
          const index = parseInt(String(children))
          return codeSuggestions[index]
        } else if (props?.className === 'language-diff') {
          return (
            <SyntaxHighlighter
              {...rest} // eslint-disable-line
              PreTag="div"
              language={match[1]}
              style={codeStyle}
              customStyle={{
                background: 'linear-gradient(to bottom, #333, transparent)',
              }}
              className="rounded-xl hover:cursor-pointer"
            >
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          )
        } else {
          // special case for language matches, we will attempt to find the snippet it belongs to
          return (
            <CodeBlock match={match} rest={rest} metaData={metaData}>
              {children}
            </CodeBlock>
          )
        }
      } else {
        const isOrangeCode = allSnippetsInContext && allSnippetsInContext.length === 0
        const codeBlock = (
          <code
            {...rest}
            className={`rounded-xl whitespace-pre-wrap break-words ${className} ${!disableCodeBlockHover && !isOrangeCode ? 'hover:cursor-pointer hover:underline' : ''}`}
            style={{
              color: isOrangeCode ? 'orange' : 'light-green',
            }} // Conditionally apply color
          >
            {children}
          </code>
        )
        if (snippets == undefined || isStreamActive || disableCodeBlockHover || isOrangeCode) {
          return codeBlock
        }
        return (
          <HoverCard openDelay={1000} closeDelay={100}>
            <HoverCardTrigger
              style={{ textDecoration: 'none' }}
              onClick={() =>
                window.open(
                  `https://${GITHUB_BASE_URL}/search?q=repo:${repoName} ${children}&type=code`
                )
              }
            >
              {codeBlock}
            </HoverCardTrigger>
            <HoverCardContent className="w-full max-h-[500px] overflow-y-auto">
              {allSnippetsInContext && allSnippetsInContext.length > 0 ? (
                <div>
                  {Object.entries(codeLinesBySnippet).map(
                    ([fileName, { snippet, lineDict }]) => {
                      if (Object.keys(lineDict).length === 0) {
                        // special case if snippet is there but not lineDict
                        return (
                          <SnippetBadge
                            key={fileName}
                            snippet={snippet}
                            repoName={repoName}
                            branch={branch}
                            className="text-[8px]"
                            snippets={snippets || []}
                            options={[]}
                          />
                        )
                      }
                      const sortedLineNumbers = Object.keys(lineDict)
                        .map(Number)
                        .sort((a, b) => a - b)
                      const minLineNumber = sortedLineNumbers[0]
                      const maxLineNumber =
                        sortedLineNumbers[sortedLineNumbers.length - 1]

                      const lines = []
                      for (let i = minLineNumber; i <= maxLineNumber; i++) {
                        lines.push(lineDict[i] ? lineDict[i][1] : '')
                      }
                      const fileExtension = fileName.split('.').pop() || ''

                      return (
                        <div key={fileName} style={{ marginBottom: '20px' }}>
                          <div className="font-mono">
                            <span className="text-md font-bold">
                              {fileName.split('/').pop()}
                            </span>
                            <br />
                            <span className="text-xs">{fileName}</span>
                          </div>
                          <SyntaxHighlighter
                            language={getFileEndingsToLanguages(fileExtension)}
                            customStyle={{
                              background: 'linear-gradient(to bottom, #333, transparent)',
                              maxHeight: '200px',
                              maxWidth: '1000px',
                            }}
                            className="rounded-xl hover:cursor-pointer"
                            lineNumberStyle={(lineNumber) => {
                              if (lineDict[lineNumber]) {
                                return {} // Keep these lines visible and normal
                              }
                              return { display: 'none' } // Hide lines that don't exist in lineDict
                            }}
                            startingLineNumber={minLineNumber}
                            showLineNumbers
                            wrapLines
                          >
                            {String(lines.join('\n')).replace(/\n$/, '')}
                          </SyntaxHighlighter>
                        </div>
                      )
                    }
                  )}
                </div>
              ) : (
                <div>
                  This code entity could not be matched to any of the current
                  snippets in context. This means that this entity could be
                  hallucinated.
                </div>
              )}
            </HoverCardContent>
          </HoverCard>
        )
      }
    },
  }

  return (
    <Markdown
      className={`${className} reactMarkdown`}
      remarkPlugins={[remarkGfm]}
      components={renderer}
    >
      {transformedContent}
    </Markdown>
  )
}

export { CODE_CHANGE_PATTERN, MarkdownRenderer }
