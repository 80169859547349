import { useCallback, useEffect } from "react";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
// @ts-expect-error
import Cookies from 'js-cookie';
import { Octokit } from "@octokit/rest";
import { BACKEND_URL, GITHUB_API_BASE_URL } from "../lib/constants";
import { createStorageAtom } from "../state/atoms";
import { posthog } from "../lib/posthog";

interface User {
  username: string;
  email: string;
  name: string;
  image: string;
}

export const accessTokenAtom = atom<string | null>(null);
export const userAtom = createStorageAtom<User | null>('user', null);

const useAccessToken = () => {
  const [accessToken, setAccessToken_] = useAtom(accessTokenAtom);

  useEffect(() => {
    const accessToken = Cookies.get('accessToken');
    if (accessToken) {
      setAccessToken_(accessToken);
    }
  }, []);

  const setAccessToken = (accessToken: string) => {
    setAccessToken_(accessToken);
    Cookies.set('accessToken', accessToken, { 
      secure: true,
      sameSite: 'strict'
    });
  };

  return { accessToken, setAccessToken };
}

export const useGitHubCallback = () => {
    const { setAccessToken } = useAccessToken();
    const setUser = useSetAtom(userAtom);
  
    useEffect(() => {
      if (window.location.pathname === '/api/auth/callback/github') {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');
        if (code && state) {
          (async () => {
            const response = await fetch(`${BACKEND_URL}/backend/auth?${new URLSearchParams({ code, state }).toString()}`, {
              method: 'GET',
              headers: {
                'Accept': 'application/json'
              }
            });
            if (response.ok) {
              const {access_token, ...user} = await response.json();
              setAccessToken(access_token);
              setUser(user);
              window.history.pushState({}, '', '/');
            }
          })();
        }
      }
    }, [setAccessToken]);

  const signIn = useCallback(() => {
    window.location.href = '/backend/login';
  }, []);

  return { signIn };
};


export const useSession = () => {
  const { accessToken } = useAccessToken();
  const userData = useAtomValue(userAtom);
  const user = userData as User;
  const username = user?.username;
  const octokit = new Octokit({ auth: accessToken, baseUrl: `https://${GITHUB_API_BASE_URL}` });

  useEffect(() => {
    if (username) {
      posthog.identify(username, {
        username: username,
        email: user?.email,
        name: user?.name,
        image: user?.image,
      });
    }
  }, [username, user]);

  const signOut = useCallback(() => {
    Cookies.remove('accessToken');
    window.location.href = '/';
  }, []);

  return {
    user,
    username, 
    accessToken,
    octokit,
    signOut,
  };
}

