import React, { memo, useEffect, useRef } from 'react';
import { Textarea } from './ui/textarea';
import { Button } from './ui/button';
import { Dialog, DialogContent, DialogTrigger } from './ui/dialog';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { Switch } from './ui/switch';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './ui/tooltip';
import { FaArrowUp, FaShareAlt, FaStop } from 'react-icons/fa';
import { toast } from './ui/use-toast';
import { currentMessageState, isLoadingState, messagesIdState, messagesState, repoNameState, streamActiveState, useRewriteState } from '@/state/atoms';
import { useSession } from '@/hooks/useSession';
import { useAtom, useAtomValue } from 'jotai';

const ChatControls = memo(({
  sendMessage,
  useFollowUpSearchAgent,
  setUseFollowUpSearchAgent,
  currentOwnerOfChatRef,
  stream,
}: {
  sendMessage: () => void;
  useFollowUpSearchAgent: boolean;
  setUseFollowUpSearchAgent: (use: boolean) => void;
  currentOwnerOfChatRef: React.MutableRefObject<string>;
  stream: React.MutableRefObject<any>;
}) => {
  const messages = useAtomValue(messagesState)
  const messagesId = useAtomValue(messagesIdState)
  const [currentMessage, setCurrentMessage] = useAtom(currentMessageState)
  const repoName = useAtomValue(repoNameState)
  const [isLoading, setIsLoading] = useAtom(isLoadingState)
  const isStreamActive = useAtomValue(streamActiveState)
  const [useRewrite, setUseRewrite] = useAtom(useRewriteState)
  const { username } = useSession()

  const currentMessageRef = useRef<HTMLTextAreaElement>(null)

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (
      e.key === 'Enter' &&
      (e.ctrlKey || e.metaKey) &&
      currentMessage.trim().length > 0 &&
      !isLoading &&
      !isStreamActive
    ) {
      sendMessage();
      e.currentTarget.style.height = '56px';
      e.stopPropagation();
      e.preventDefault();
    } else if (e.key === 'Backspace' && e.currentTarget.value === '') {
      e.currentTarget.style.height = '56px';
    }
  };

  const adjustTextareaHeight = () => {
    if (currentMessageRef.current) {
      currentMessageRef.current.style.height = '56px';
      currentMessageRef.current.style.height = `${currentMessageRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [currentMessage]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentMessage(e.target.value);
    adjustTextareaHeight();
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${window.location.origin}/c/${messagesId}`);
    toast({
      title: 'Link copied',
      description: 'The link to your current session has been copied to your clipboard.',
    });
  };

  useEffect(() => {
    if (currentMessage) {
      currentMessageRef.current?.focus()
    }
  }, [currentMessage])

  return (
    <div
      className={`w-full ${
        messages.length <= 1 ? 'max-w-[800px]' : 'max-w-[1200px]'
      } border border-t-2 p-4 bg-zinc-800 rounded-xl transition-[max-width] duration-300 ease-in-out shadow-md`}
    >
      <Textarea
        id="sweep-chat-text-area"
        className="p-2 mb-4 sweep-chat-text-area border-none bg-transparent resize-none outline-none focus:border-none focus:outline-none shadow-none focus-visible:ring-offset-0 focus-visible:ring-0 overflow-y-auto transition-[height] duration-200 ease-in-out"
        data-ph-capture-attribute-current-message={currentMessage}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        style={{ minHeight: 56, height: 56, maxHeight: 300 }}
        value={currentMessage}
        placeholder={
          messages.length > 0
            ? `Ask a follow-up question...`
            : `Ask Sweep about ${repoName.split('/')[1] || 'your codebase'}...`
        }
        ref={currentMessageRef}
        autoFocus
      />
      <div className="flex items-start justify-between">
        <Dialog>
          <DialogTrigger asChild>
            <Button className="mr-4" variant="secondary" disabled={messagesId === ''}>
              <FaShareAlt />
              &nbsp;&nbsp;Share
            </Button>
          </DialogTrigger>
          <DialogContent className="w-[800px] p-16">
            <h2 className="text-2xl font-bold mb-4 text-center">Share the Conversation</h2>
            <p className="text-center">Share your chat session with a team member.</p>
            <Input
              value={`${typeof window !== 'undefined' ? window.location.origin : ''}/c/${messagesId}`}
              onClick={copyToClipboard}
              disabled
            />
            <Button className="mt-2" variant="secondary" onClick={copyToClipboard}>
              Copy
            </Button>
          </DialogContent>
        </Dialog>
        <div className="flex items-center justify-between">
          {messages.length > 1 ? (
            <TooltipProvider delayDuration={200}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="flex items-center justify-between ml-4">
                    <Label
                      htmlFor="use-search-agent"
                      className={`cursor-help w-full text-white ${
                        messages.length === 0 || stream.current !== null ? 'opacity-50' : ''
                      }`}
                      style={{
                        opacity: messages.length === 0 || isStreamActive ? 0.5 : 1,
                      }}
                    >
                      Instant Reply
                    </Label>
                    <Switch
                      id="use-search-agent"
                      checked={!useFollowUpSearchAgent}
                      onCheckedChange={() => setUseFollowUpSearchAgent(!useFollowUpSearchAgent)}
                      className="ml-2 data-[state=checked]:bg-blue-700"
                      thumbClassName="bg-white"
                      disabled={messages.length === 0 || isStreamActive}
                    />
                  </div>
                </TooltipTrigger>
                <TooltipContent className="max-w-xs">
                  <p>
                    Useful for follow up questions. Enabling makes Sweep skip researching your
                    codebase (~2-5 minutes).
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : (
            <div className="flex items-center justify-between ml-4">
              <Label
                htmlFor="use-rewrite"
                className={`cursor-help w-full text-white ${
                  isStreamActive ? 'opacity-50' : ''
                }`}
                style={{ opacity: isStreamActive ? 0.5 : 1 }}
              >
                Rewrite Prompt
              </Label>
              <Switch
                id="use-rewrite"
                checked={useRewrite}
                onCheckedChange={() => setUseRewrite(!useRewrite)}
                className="ml-2 data-[state=checked]:bg-blue-700"
                thumbClassName="bg-white"
              />
            </div>
          )}
          {!isStreamActive ? (
            <Button
              id="sweep-chat-send-button"
              className="ml-4 text-white rounded-full px-3 text-sm"
              variant="primary"
              onClick={() => {
                sendMessage();
                const textarea = document.querySelector('.sweep-chat-text-area');
                currentOwnerOfChatRef.current = username || '';
                if (textarea) {
                  // Use RAF to ensure smooth transition
                  requestAnimationFrame(() => {
                    (textarea as HTMLTextAreaElement).style.height = '56px';
                  });
                }
              }}
              disabled={isLoading || currentMessage.trim().length === 0}
            >
              <FaArrowUp />
            </Button>
          ) : (
            <Button
              className="ml-4 rounded-full px-3 bg-gray-200 hover:bg-white text-zinc-800 text-sm"
              size="sm"
              variant="secondary"
              onClick={async () => {
                setIsLoading(false);
                stream.current = undefined;
                const lastUserMessage = messages.findLast(message => message.role === 'user')?.content;
                if (lastUserMessage && currentMessage === '') {
                  setCurrentMessage(lastUserMessage)
                  toast({
                    description: `If you want to rewrite your question, we recommend starting a new chat for the best experience.`,
                    duration: 5000,
                  })
                }
              }}
              disabled={!isStreamActive}
            >
              <FaStop />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
});

ChatControls.displayName = 'ChatControls';

export {
  ChatControls
}