import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "@/components/ui/alert-dialog"
import { Button } from "@/components/ui/button";
import { FaArrowRight, FaTimes } from "react-icons/fa";
import { MarkdownRenderer } from "./shared/MarkdownRenderer";
import { useAtomValue, useAtom } from "jotai";
import { branchState, repoNameState, streamActiveState } from "@/state/atoms";
import { PullRequest, Snippet } from "@/lib/types";
import PulsingLoader from "./shared/PulsingLoader";
import { truncate } from "@/lib/strUtils";
import AutoScrollArea from "./ui/autoscroll";
import { SnippetBadge } from "./shared/SnippetBadge";
import { useRef, useEffect } from "react";
import { Textarea } from "./ui/textarea";
import PullRequestDisplay from "./PullRequestDisplay";
import { useState } from "react";

export default function MessageEditor({
    newMessage,
    setNewMessage,
    searchMessage,
    snippets,
    setSnippets,
    messageEditorOpen,
    setMessageEditorOpen,
    currentMessage,
    sendMessage,
    pulls,
}: {
    newMessage: string
    setNewMessage: (message: string) => void
    searchMessage: string
    snippets: Snippet[]
    setSnippets: (snippets: Snippet[]) => void
    messageEditorOpen: boolean
    setMessageEditorOpen: (open: boolean) => void
    currentMessage: string
    sendMessage: () => void
    pulls: PullRequest[]
}) {
    const repoName = useAtomValue(repoNameState)
    const branch = useAtomValue(branchState)
    const [isStreamActive, setIsStreamActive] = useAtom(streamActiveState)
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const [isButtonEnabled, setIsButtonEnabled] = useState(false)

    useEffect(() => {
        if (messageEditorOpen && textareaRef.current) {
            textareaRef.current.focus()
        }
    }, [messageEditorOpen])

    useEffect(() => {
        setIsButtonEnabled(false)
        const timer = setTimeout(() => {
            setIsButtonEnabled(true)
        }, 250)
        return () => clearTimeout(timer)
    }, [newMessage])

    return (
        <AlertDialog open={messageEditorOpen} onOpenChange={setMessageEditorOpen}>
            <AlertDialogContent className="w-[1200px] max-w-none">
                <AlertDialogHeader>
                    <AlertDialogTitle>
                        Sweep is rewriting your request...
                    </AlertDialogTitle>
                    <AutoScrollArea className="max-h-[150px] overflow-y-auto">
                        <MarkdownRenderer
                            content={currentMessage}
                            repoName={repoName}
                            branch={branch}
                            snippets={snippets}
                            metaData={undefined}
                            disableCodeBlockHover
                            className="!text-gray-400"
                        />
                    </AutoScrollArea>
                    {searchMessage ? (
                        <div className={`flex flex-row justify-between items-center bottom-0 py-6 w-full`}>
                            <div className="ml-2 flex space-x-2 items-center">
                            <div className="flex justify-center">
                                <PulsingLoader size={1} />
                            </div>
                            <p className="text-gray-500 center whitespace-nowrap overflow-hidden">
                                {truncate(searchMessage, 100)}
                            </p>
                            </div>
                        </div>
                    ): (
                        <>
                            <h2 className="text-lg py-4 font-bold">
                                Rewritten Message
                            </h2>
                            <Textarea
                                ref={textareaRef}
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                className="h-[400px] p-4 mt-4 relative"
                            />
                        </>
                    )}
                    <AutoScrollArea className="max-h-[150px] overflow-y-auto">
                        <div className="pb-0 pt-2 mt-0">
                            {snippets.map(
                                (snippet, index) => (
                                    <SnippetBadge
                                    key={index}
                                    snippet={snippet}
                                    className=""
                                    repoName={repoName}
                                    branch={branch}
                                    snippets={[]}
                                    setSnippets={() => {}}
                                    newSnippets={[]}
                                    setNewSnippets={() => {}}
                                    options={[]}
                                    />
                                )
                            )}
                            {pulls.map((pull, index) => (
                                <PullRequestDisplay key={index} pr={pull} />
                            ))}
                        </div>
                    </AutoScrollArea>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel asChild>
                        <Button variant="outline" disabled={!isStreamActive} onClick={() => {
                            setNewMessage('')
                            setSnippets([])
                            setIsStreamActive(false)
                        }}><FaTimes className="mr-2" /> Cancel</Button>
                    </AlertDialogCancel>
                    <AlertDialogAction asChild onClick={sendMessage}>
                        <Button variant="outline" disabled={newMessage === '' || !isButtonEnabled}><FaArrowRight className="mr-2" /> Send</Button>
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
};
