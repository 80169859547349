import { GITHUB_BASE_URL, typeNameToColor } from '@/lib/constants'
import { sliceLines } from '@/lib/strUtils'
import { Snippet } from '@/lib/types'
import { FaExpand, FaPlus, FaTimes } from 'react-icons/fa'
import SyntaxHighlighter from '@/lib/syntaxHighlighter'
import { Dispatch, type JSX, SetStateAction, memo } from 'react';
import { ExtensionIcon } from '@/lib/iconMapping'
import { snippetIsEqual } from '@/lib/utils'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from '../ui/dialog'

const RenderPath = memo(({
  snippet,
  snippets,
  newSnippets,
  setSnippets,
  setNewSnippets,
  options,
}: {
  snippet: Snippet
  snippets: Snippet[]
  newSnippets?: Snippet[]
  setSnippets?: Dispatch<SetStateAction<Snippet[]>>
  setNewSnippets?: Dispatch<SetStateAction<Snippet[]>>
  options: string[]
}) => {
  let path = snippet.file_path
  let truncatedPath = path
  const maxPathLength = 40
  if (path.length > maxPathLength) {
    truncatedPath = '...' + path.slice((maxPathLength - 3) * -1)
  }
  return (
    <div className="flex flex-row items-center">
      <div className="inline-block align-middle mr-4">
        <FaExpand />
      </div>
      <div className="flex flex-col items-start hover:underline">
        <div className="text-base text-zinc-200 inline-block align-middle mr-2">
          <ExtensionIcon extension={snippet.file_path.split('.').pop()!} />
          {truncatedPath.slice(truncatedPath.lastIndexOf('/') + 1)}
          {!(
            snippet.end > snippet.content.split('\n').length - 3 &&
            snippet.start <= 1
          ) ? (
            <span className="text-gray-400 inline-block align-middle">
              :{snippet.start}-{snippet.end}
            </span>
          ) : (
            <span
              className={`text-gray-400 inline-block align-middle ${snippet.end - snippet.start > 1000 ? 'text-yellow-500' : ''}`}
            >
              &nbsp;{snippet.end - snippet.start} lines
            </span>
          )}
          {snippet.type_name !== 'source' && (
            <code className="ml-2 bg-opacity-20 bg-black text-white rounded p-1 px-2 text-xs">
              {snippet.type_name}
            </code>
          )}
        </div>
        <div className="text-sm text-gray-400 inline-block align-middle">
          {truncatedPath}
        </div>
      </div>
      <div className="text-lg inline-block align-middle align-center ml-2">
        {options.includes('remove') && (
          <FaTimes
            className="ml-2 hover:drop-shadow-md text-zinc-200 hover:text-white"
            onClick={(e) => {
              let newSnippets = []
              for (let curSnippet of snippets) {
                if (
                  !snippetIsEqual({
                    snippetOne: snippet,
                    snippetTwo: curSnippet,
                  })
                ) {
                  newSnippets.push(curSnippet)
                }
              }
              if (setSnippets) {
                setSnippets(newSnippets)
              }
              e.stopPropagation()
              e.preventDefault()
            }}
          />
        )}
        {options.includes('add') && (
          <FaPlus
            className="ml-2 hover:drop-shadow-md hover:text-gray-300"
            onClick={() => {
              let tempSnippets = [...snippets]
              // if we are adding a snippet that means the score should be 1
              snippet.score = 1
              // only add to snippets if it isnt already in there
              if (
                !tempSnippets.some((existingSnippet) =>
                  snippetIsEqual({
                    snippetOne: snippet,
                    snippetTwo: existingSnippet,
                  })
                )
              ) {
                tempSnippets.push(snippet)
              }
              if (setSnippets) {
                setSnippets(tempSnippets)
              }
              // remove the snippets from newSnippets
              if (setNewSnippets && newSnippets) {
                let tempNewSnippets = []
                for (let curSnippet of newSnippets) {
                  if (
                    !snippetIsEqual({
                      snippetOne: snippet,
                      snippetTwo: curSnippet,
                    })
                  ) {
                    tempNewSnippets.push(curSnippet)
                  }
                }
                setNewSnippets(tempNewSnippets)
              }
            }}
          />
        )}
      </div>
    </div>
  )
})
RenderPath.displayName = 'RenderPath';

const getLanguage = (filePath: string) => {
  return filePath.split('.').pop()
}

const SnippetBadge = memo(({
  snippet,
  className,
  repoName,
  branch,
  snippets,
  newSnippets,
  setSnippets,
  setNewSnippets,
  options = [],
}: {
  snippet: Snippet
  className?: string
  repoName: string
  branch: string
  snippets: Snippet[]
  newSnippets?: Snippet[]
  setSnippets?: Dispatch<SetStateAction<Snippet[]>>
  setNewSnippets?: Dispatch<SetStateAction<Snippet[]>>
  options?: string[]
}) => {
  let path = snippet.file_path;
  let truncatedPath = path;
  const maxPathLength = 40;

  if (path.length > maxPathLength) {
    truncatedPath = '...' + path.slice((maxPathLength - 3) * -1);
  }

  return (
    <Dialog>
      <div
        className={`hover:cursor-pointer hover:underline py-2 px-4 rounded-xl mb-2 text-xs inline-block mr-2 ${
          typeNameToColor[snippet.type_name]
        } ${className || ''}`}
        style={{ opacity: `${Math.max(Math.min(1, snippet.score), 0.5)}` }}
      >
        <DialogTrigger>
          <RenderPath
            snippet={snippet}
            snippets={snippets}
            newSnippets={newSnippets}
            setSnippets={setSnippets}
            setNewSnippets={setNewSnippets}
            options={options}
          />
        </DialogTrigger>
      </div>
      <DialogContent className="max-w-[1200px]">
        <DialogHeader className="mt-4">
          <div
            onClick={() => {
              window.open(
                `https://${GITHUB_BASE_URL}/${repoName}/blob/${branch}/${snippet.file_path}`,
                 '_blank'
              );
            }}
            className="flex flex-row items-baseline justify-between pl-4 hover:underline hover:cursor-pointer"
          >
            <div className="text-base text-zinc-200 inline-block align-middle">
              <ExtensionIcon extension={snippet.file_path.split('.').pop()!} />
              {truncatedPath.slice(truncatedPath.lastIndexOf('/') + 1)}
              {!(
                snippet.end > snippet.content.split('\n').length - 3 && 
                snippet.start === 1) ? (
                <span className="text-gray-400 inline-block align-middle">
                  :{snippet.start}-{snippet.end}
                </span>
              ) : (
                <span 
                  className={`text-gray-400 inline-block align-middle ${snippet.end - snippet.start > 1000 ? 'text-yellow-500' : ''}`}
                >
                  &nbsp;{snippet.end - snippet.start} lines
                </span>
              )}
              {snippet.type_name !== 'source' && (
                <code className="ml-2 bg-opacity-20 bg-black text-white rounded p-1 px-2 text-xs">
                  {snippet.type_name}
                </code>
              )}
            </div>
            <div className="text-sm text-gray-400 inline-block align-middle ml-2">
              {truncatedPath}
            </div>
          </div>
        </DialogHeader>
        <SyntaxHighlighter
          language={getLanguage(snippet.file_path)}
          customStyle={{
            backgroundColor: 'transparent', // inoptimal but we'll redesign this later
            fontSize: '12px',
          }}
          className="max-h-[600px] overflow-y-auto p-4 w-full"
          showLineNumbers
          startingLineNumber={snippet.start}
        >
          {sliceLines(snippet.content, snippet.start, snippet.end)}
        </SyntaxHighlighter>
      </DialogContent>
    </Dialog>
  );
});
SnippetBadge.displayName = 'SnippetBadge';
export { SnippetBadge, snippetIsEqual }
