import {
  FaFile,
  FaJava,
  FaJs,
  FaPhp,
  FaPython,
  FaReact,
  FaRust,
} from 'react-icons/fa'
import { FaGolang } from 'react-icons/fa6'

export const extensionToIcon = {
  py: FaPython,
  js: FaJs,
  ts: FaJs,
  jsx: FaReact,
  tsx: FaReact,
  php: FaPhp,
  go: FaGolang,
  java: FaJava,
  // "c": FaC,
  rs: FaRust,
}

export const ExtensionIcon = ({ extension }: { extension: string }) => {
  const Icon =
    extensionToIcon[extension as keyof typeof extensionToIcon] || FaFile
  if (extension === 'go') {
    return <Icon className="inline mr-2" size={25} />
  }
  return <Icon className="inline mr-2" size={15} style={{ marginTop: -2 }} />
}
