import { toast } from '@/components/ui/use-toast'
import { Octokit } from '@octokit/rest'
import { Buffer } from 'buffer'

const FILE_PATH_REGEX =
  /(?:^|\b|.)((?:\.{1,2}\/)?(?:[\w.-]+\/)*[\w.-]+\.[\w-]+)(?=$|\b)/g

export async function parseFiles(
  repoName: string,
  message: string,
  octokit: Octokit,
  currentRef: string
) {
  const filePaths = Array.from(message.matchAll(FILE_PATH_REGEX), match => match[1]);
  const uniqueFilePaths = Array.from(
    new Set(filePaths.map((path) => path.trim()))
  )

  const [owner, repo] = repoName.split('/')
  const fileMapping = await Promise.all(
    uniqueFilePaths.map(async (path) => {
      try {
        // TODO: make a new endpoint request to validate the file paths. Currently they will be fetched in file matching but will not be displayed in the frontend.
        const response = await octokit.request(
          'GET /repos/{owner}/{repo}/contents/{path}',
          {
            owner,
            repo,
            path,
            ref: currentRef,
          }
        )
        const fileData = Array.isArray(response.data)
          ? response.data[0]
          : response.data
        if (fileData.type === 'file' && fileData.content) {
          const content = Buffer.from(fileData.content, 'base64').toString(
            'utf8'
          )
          return [path, content]
        }
        return [path, undefined]
      } catch (error) {
        console.error(`Error fetching file ${path}:`, error)
        if (
          path.split('/').pop()?.includes('.') &&
          path.split('/').length > 2 &&
          error instanceof Error &&
          error.message.includes('Not Found')
        ) {
          toast({
            title: `Warning: ${path} does not exist`,
            description:
              'If this is a typo, please correct it. Otherwise, ignore this warning.',
            variant: 'warning',
          })
        }
        return [path, undefined]
      }
    })
  )

  const fileContentsMap = Object.fromEntries(
    fileMapping.filter(([_, content]) => content !== null)
  )

  return fileContentsMap
}
