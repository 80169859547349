import { lastPolledAtState, appliedChangesAtom, cachedAppliedChangesAtom } from "@/state/atoms";
import { useAtom, useSetAtom } from "jotai";
import { useMatch } from "react-router-dom";
import useAuthorizedFetch from "./useAuthorizedFetch";
import { useCallback, useEffect, useState } from "react";
import { StatefulCodeSuggestion } from "@/lib/types";
import { toast } from "@/components/ui/use-toast";

export enum PollingStatus {
    Connected,
    Stale,
    Disconnected,
    NeverConnected,
}

const POLL_FREQUENCY = 2000 // once every 2 seconds

const POLLING_STATUS_STALE_THRESHOLD = 20000 // 20 seconds
const POLLING_STATUS_DISCONNECTED_THRESHOLD = 60000 // 1 minute

const changesEqual = (changes1: StatefulCodeSuggestion[], changes2: StatefulCodeSuggestion[]) => {
    const sortedChanges1 = [...changes1].sort((a, b) => a.filePath.localeCompare(b.filePath));
    const sortedChanges2 = [...changes2].sort((a, b) => a.filePath.localeCompare(b.filePath));
    return sortedChanges1.length == sortedChanges2.length && sortedChanges1.every((change1, i) => 
        change1.filePath == sortedChanges2[i].filePath && 
        change1.originalCode == sortedChanges2[i].originalCode && 
        change1.newCode == sortedChanges2[i].newCode
    );
}

const getPollingStatus = (lastPolledAt: number) => {
    const timeSinceLastPolled = Date.now() - lastPolledAt
    if (lastPolledAt === 0 || lastPolledAt === undefined) {
        return PollingStatus.NeverConnected
    }
    if (timeSinceLastPolled > POLLING_STATUS_DISCONNECTED_THRESHOLD) {
        return PollingStatus.Disconnected
    } else if (timeSinceLastPolled > POLLING_STATUS_STALE_THRESHOLD) {
        return PollingStatus.Stale
    }
    return PollingStatus.Connected
}

export const useFetchPolledAt = () => {
    const match = useMatch("/c/:defaultMessagesId");
    const defaultMessagesId = match?.params?.defaultMessagesId || "";
    const authorizedFetch = useAuthorizedFetch()
    const [lastPolledAt, setLastPolledAt] = useAtom(lastPolledAtState)
    const [appliedChanges, setAppliedChanges] = useAtom(appliedChangesAtom)
    const [cachedAppliedChanges, setCachedAppliedChanges] = useState<StatefulCodeSuggestion[]>([])

    const loadMessages = async (messagesId: string) => {
        const response = await authorizedFetch(`/messages/load/${messagesId}`,
            {},
            { method: 'GET' }
        )
        const data = await response.json()
        if (data.status != 'success') {
            console.warn(`Failed to fetch last polled at: ${data.error}`)
            return
        }
        const { polled_at, applied_changes } = data.data
        setCachedAppliedChanges(applied_changes)
        setLastPolledAt(new Date(polled_at).getTime() || 0)
        console.log(`Last polled at: ${polled_at}`)
    }

    useEffect(() => {
        if (defaultMessagesId) {
            loadMessages(defaultMessagesId)
            const interval = setInterval(() => loadMessages(defaultMessagesId), POLL_FREQUENCY)
            return () => clearInterval(interval)
        }
    }, [defaultMessagesId])

    const pullLocalChanges = useCallback(async () => {
        setAppliedChanges(cachedAppliedChanges)
        toast({
            title: "Pulled local changes",
            description: `${cachedAppliedChanges.length} local changes have been applied.`,
        })
    }, [setAppliedChanges, cachedAppliedChanges])

    return {
        lastPolledAt,
        pollingStatus: getPollingStatus(lastPolledAt),
        changesOutdated: !changesEqual(appliedChanges, cachedAppliedChanges),
        pullLocalChanges,
    }
}