import { useCallback } from "react"
import { useSession } from "./useSession"
import { useAtomValue } from "jotai"
import { repoNameState } from "@/state/atoms"
import { BACKEND_URL } from "@/lib/constants"
import { toast } from "@/components/ui/use-toast"
import usePostHogCapture from "./usePosthogCapture"
import { truncateMiddle } from "@/lib/strUtils"

const constructErrorMessage = async (response: Response) => {
  let errorMessage = `HTTP error! status: ${response.status}`
  let errorDetail = ''
  try {
    const errorData = await response.json()
    errorDetail = errorData.detail || ''
    errorMessage = errorDetail || errorMessage
  } catch (parseError) {
    console.error('Error parsing error response:', parseError)
  }
  const error = new Error(errorMessage) as Error & {
    status?: number
    detail?: string
    traceback?: string
  }
  error.status = response.status
  error.detail = errorDetail
  return error
}

export default function useAuthorizedFetch() {
  const posthog_capture = usePostHogCapture()
  const { accessToken } = useSession()
  const repoName = useAtomValue(repoNameState)


  return useCallback(
    async (
      url: string,
      body: { [key: string]: any } = {},
      options: RequestInit = {},
      verbose: boolean = true
    ) => {
      if (!accessToken) {
        throw new Error("Access token is null")
      }

      try {
        const response = await fetch(`${BACKEND_URL}/backend${url}`, {
          method: options.method || 'POST',
          headers: {
            ...options.headers,
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body:
            body && options.method !== 'GET'
              ? JSON.stringify({
                  repo_name: repoName,
                  ...body,
                })
              : undefined,
          ...options,
        })

        if (!response.ok) {
          throw await constructErrorMessage(response)
        }

        return response
      } catch (error: any) {
        console.log(error)
        if (verbose) {
          if (error.message.toLowerCase().includes("prompt is too long")) {
            toast({
              title: 'Prompt Too Long',
              description: 'Please try removing some of the files that aren\'t needed and retry the request.',
              variant: 'destructive',
              duration: Infinity,
              className: 'whitespace-break-spaces',
            });
          } else {
            toast({
              title: 'Error',
              description: truncateMiddle(error.message, 100),
              variant: 'destructive',
              duration: Infinity,
            });
          }
        }
        posthog_capture('chat errored', {
          error: error.message,
          stack: error.stack,
          url: window.location.href,
          error_type: 'fetchWithRetry',
          endpoint: url,
        })

        throw error // Re-throw the updated error
      }
    },
    [accessToken, repoName]
)
}