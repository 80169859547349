import React, { useEffect, useState } from 'react';
import CommitMetricsDisplay from '@/components/CommitMetricsDisplay';
import { useSession } from '@/hooks/useSession';
import { BACKEND_URL } from '@/lib/constants'
import { Repository } from '@/lib/types';

const CommitMetrics: React.FC = () => {
  const [commitMetrics, setMetrics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null | string>(null);
  const { accessToken, octokit } = useSession();
  const loadTime = new Date();

  useEffect(() => {
    if (new Date().getTime() - loadTime.getTime() > 10000) {
      window.location.href = `${BACKEND_URL}/backend/login`;
    }
    if (accessToken) {
      (async () => {
        try {
          const response = await fetch(`${BACKEND_URL}/backend/api/metrics`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          });
          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          }
          const data = await response.json();
          setMetrics(data.metrics);
        } catch (err: any) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      })()
    }
  }, [accessToken])

  if (loading) return (
    <div className="text-white text-center h-screen flex flex-col items-center justify-center">
      <p>Loading data...</p>
      <br />
      <p>If this takes too long, please try to <a className="text-blue-500" href={`${BACKEND_URL}/backend/login`}>login</a> again.</p>
    </div>
  );
  if (error) return (
    <div className="text-white text-center h-screen flex items-center justify-center">
      Error: {error}
    </div>
  );

  return <CommitMetricsDisplay commitMetrics={commitMetrics} />;
};

export default CommitMetrics;
