import { useEffect } from 'react'

// Force dark mode
export const useForceDarkMode = () => {
  useEffect(() => {
    document.documentElement.classList.add('dark');
    return () => {
      document.documentElement.classList.remove('dark');
    };
  }, []);
}