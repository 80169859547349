import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { Snippet, StatefulCodeSuggestion } from './types'
import { useEffect, useRef } from 'react'

function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

const areStatefulCodeSuggestionsEqual = (
  a: StatefulCodeSuggestion,
  b: StatefulCodeSuggestion
): boolean => {
  return a.id === b.id
}

const areStatefulCodeSuggestionsContentsEqual = (
  a: StatefulCodeSuggestion,
  b: StatefulCodeSuggestion
): boolean => {
  return (
    a.filePath === b.filePath &&
    a.originalCode === b.originalCode &&
    a.newCode === b.newCode &&
    a.fileContents === b.fileContents
  )
}

const snippetIsEqual = ({
  snippetOne,
  snippetTwo,
}: {
  snippetOne: Snippet
  snippetTwo: Snippet
}) => {
  return (
    snippetOne.content == snippetTwo.content &&
    snippetOne.file_path == snippetTwo.file_path &&
    snippetOne.end == snippetTwo.end &&
    snippetOne.start == snippetTwo.start
  )
}

const mergeAndDeduplicateSnippets = (snippets: Snippet[]): Snippet[] => {
  // Sort snippets by file_path, then by start
  const sortedSnippets = [...snippets].sort((a, b) => {
    if (a.file_path !== b.file_path) {
      return a.file_path.localeCompare(b.file_path)
    }
    return a.start - b.start
  })

  const mergedSnippets: Snippet[] = []
  let currentSnippet: Snippet | undefined

  for (const snippet of sortedSnippets) {
    if (!currentSnippet) {
      currentSnippet = { ...snippet }
    } else if (
      currentSnippet.file_path === snippet.file_path &&
      snippet.start <= currentSnippet.end
    ) {
      // Merge overlapping snippets
      currentSnippet.start = Math.min(currentSnippet.start, snippet.start)
      currentSnippet.end = Math.max(currentSnippet.end, snippet.end)
      currentSnippet.score = Math.max(currentSnippet.score, snippet.score)

      // Handle type_name conflicts
      if (currentSnippet.type_name !== snippet.type_name) {
        currentSnippet.type_name = 'source' // Default to 'source' in case of conflict
      }
    } else {
      // No overlap, add current snippet to result and start a new one
      mergedSnippets.push(currentSnippet)
      currentSnippet = { ...snippet }
    }
  }

  if (currentSnippet) {
    mergedSnippets.push(currentSnippet)
  }

  return mergedSnippets
}

// very useful debug function that you can drop into a component to see which props change - can help optimize rerenders
// put it at the very top of a component
// example usage: useTraceUpdate({...component props here...})
function useTraceUpdate(props: { [key: string]: any }) {
  const prev = useRef<{ [key: string]: any }>(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps: { [key: string]: any }, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps);
    }
    prev.current = props;
  });
}

export {
  cn,
  areStatefulCodeSuggestionsEqual,
  areStatefulCodeSuggestionsContentsEqual,
  snippetIsEqual,
  mergeAndDeduplicateSnippets,
  useTraceUpdate,
}
