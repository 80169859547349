import React, { FC, useState } from 'react'
import { FaSync } from 'react-icons/fa'

interface RegenerateChatProps {
  onRegenerateChatMessage: () => Promise<void>
}

const RegenerateChat: FC<RegenerateChatProps> = ({
  onRegenerateChatMessage,
}) => {
  const [showPopup, setShowPopup] = useState(false)

  const handleClick = () => {
    setShowPopup(true)
  }

  const handleRegenerateConfirm = async () => {
    await onRegenerateChatMessage()
    setShowPopup(false)
  }

  const handleRegenerateCancel = () => {
    setShowPopup(false)
  }

  return (
    <div className="mx-2 my-2 ml-4">
      <button
        onClick={handleClick}
        className="text-zinc-400 hover:text-zinc-200 hover:drop-shadow-md transition-colors duration-200"
      >
        <FaSync className="inline-block text-md" />
      </button>
      {showPopup && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-background border rounded-lg shadow-xl p-6 w-160">
            <h3 className="text-lg font-semibold mb-4">Are you sure?</h3>
            <p className="mb-6 text-white-600 opacity-80">
              This will regenerate the current answer using the same context.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={handleRegenerateCancel}
                className="px-4 py-2 text-sm text-white-600 opacity-50 hover:text-zinc-800 transition-colors duration-200"
              >
                Cancel
              </button>
              <button
                onClick={handleRegenerateConfirm}
                className="px-4 py-2 text-sm text-white bg-red-500 rounded hover:bg-red-600 transition-colors duration-200"
              >
                Regenerate
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default RegenerateChat
