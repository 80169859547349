import { useEffect, useState } from "react"

export const useChatActiveTimer = () => {
  const [isChatActive, setIsChatActive] = useState<boolean>(false)
  const [chatTimer, setChatTimer] = useState(0)

  useEffect(() => {
    if (isChatActive) {
      setChatTimer(0)
      let intervalId: NodeJS.Timeout | undefined = setInterval(
        () => setChatTimer((prevTimer) => prevTimer + 1),
        1000
      )

      return () => {
        if (intervalId) clearInterval(intervalId)
      }
    }
  }, [isChatActive])

  return {
    isChatActive,
    setIsChatActive,
    chatTimer,
    setChatTimer
  }
}